import React, { ChangeEvent } from "react";
import { ITextInput } from "../../../interfaces/inputInterfaces";

const TextArea = ({
  placeholder,
  value,
  onChange,
  name,
  id,
  number,
  readOnly,
  subname,
  index,
  min,
  height
}: ITextInput) => {
  return (
    <div className="textAreaWrapper" style={{ minHeight: height }}>
      <textarea
        placeholder={placeholder}
        onChange={(
          e: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>
        ) => onChange(e, name, subname, index)}
        className="prl-10 f-s-12 pt-10"
        readOnly={readOnly}
        id={id}
        value={value || ""}
      />
    </div>
  );
};

export default TextArea;
