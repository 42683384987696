import { IUnosKupca } from "../UnosKupca";

export const validateForm = (data: IUnosKupca, type: string) => {
  let invalidFields: Array<any> = [];
  if (type === "unos") {
    if (data.naziv_firme.trim() === "") {
      invalidFields.push("Polje naziv firme je obavezno!");
    } else if (data.naziv_firme.trim().length > 100) {
      invalidFields.push(
        "Dužina naziva firme ne može biti veća od 100 karaktera!"
      );
    }
    if (data.pib.length > 0 && data.pib.length !== 8) {
      invalidFields.push("PIB mora imati 8 karaktera!");
    }
    const pdvReg = new RegExp("^[0-9]{2}\\/[0-9]{2}-[0-9]{5}-[0-9]$");
    if (data.pdv_broj.length > 0 && !pdvReg.test(data.pdv_broj)) {
      invalidFields.push("PDV broj nije validan!");
    }
    if (data.drzavaName.length <= 0) {
      invalidFields.push(
          "Polje država je obavezno!"
      );
    }
    if (data.gradName.length <= 0) {
      invalidFields.push(
        "Polje grad je obavezno!"
      );
    }
    if (data.adresa.trim().length > 100) {
      invalidFields.push("Dužina adrese ne može biti veća od 100 karaktera!");
    }
    if (data.ziro_racun === "") {
      invalidFields.push("Polje žiro račun je obavezno!");
    }
    const ziroReg = new RegExp("^[0-9]{3}-[0-9]{13}-[0-9]{2}$");
    if (data.ziro_racun.length > 0 && !ziroReg.test(data.ziro_racun)) {
      invalidFields.push("Žiro račun nije validan!");
    }
    if (data.web.trim().length > 100) {
      invalidFields.push("Dužina weba ne može biti veća od 100 karaktera!");
    }
    if (data.kontakt_osoba.trim().length > 100) {
      invalidFields.push("Dužina kontakt osobe ne može biti veća od 100 karaktera!");
    }
    if (data.telefon.trim().length > 100) {
      invalidFields.push("Dužina telefon ne može biti veća od 100 karaktera!");
    }
    if (data.napomena.trim().length > 1000) {
      invalidFields.push("Dužina napomene ne može biti veća od 1000 karaktera!");
    }
  }
  return invalidFields;
};
