import React, { forwardRef } from "react";
import { INavbarLink } from "../../../interfaces/pageInterfaces";
import { NavLink } from "react-router-dom";
import { renderIcon } from "../../Icons/Icons";

const NavbarLink = forwardRef(
  (
    {
      dropdown,
      to,
      icon,
      name,
      openSub,
      rotateArrow,
      fakture,
      subNav
    }: INavbarLink,
    ref: any
  ) => {
    if (!dropdown) {
      if (to !== "/ugovori") {
        return (
          <NavLink
            className="p-r"
            activeClassName="active"
            exact={true}
            to={typeof to === "string" ? to : ""}
          >
            <li
              className={`text-color-font-l ${
                !subNav ? "pl-20" : "pl-10"
              } f-s-12 d-flex align-items-center`}
            >
              <span className="icon mr-10">{renderIcon(icon, "")}</span>
              <span className="name">{name}</span>
            </li>
          </NavLink>
        );
      } else {
        return (
          <NavLink
            className="p-r"
            activeClassName="active"
            to={typeof to === "string" ? to : ""}
          >
            <li
              className={`text-color-font-l ${
                !subNav ? "pl-20" : "pl-10"
              } f-s-12 d-flex align-items-center`}
            >
              <span className="icon mr-10">{renderIcon(icon, "")}</span>
              <span className="name">{name}</span>
            </li>
          </NavLink>
        );
      }
    } else {
      return (
        <>
          <button
            className={`p-r ${fakture ? "active" : ""}`}
            onClick={openSub}
          >
            <li className="text-color-font-l pl-20 pr-20 f-s-12 d-flex justify-content-between align-items-center">
              <span className="height-100 d-flex align-items-center">
                <span className="icon mr-10">{renderIcon(icon, "")}</span>
                <span className="name">Fakture</span>
              </span>
              <span className={`arrow  ${rotateArrow ? "rotate" : ""}`}>
                {renderIcon("ArrowIcon", "transition-1")}
              </span>
            </li>
          </button>
          <ul className="subList" ref={ref}>
            <NavLink activeClassName="subActive" to="/fakture/izlazneFakture">
              <li className="text-color-font-l pl-30 pr-20 f-s-12 d-flex align-items-center">
                <span className="arrow">{renderIcon("ArrowIcon", "", 10)}</span>
                <span className="name ml-5">Izlazne fakture</span>
              </li>
            </NavLink>
            <NavLink activeClassName="subActive" to="/fakture/profakture">
              <li className="text-color-font-l pl-30 pr-20 f-s-12 d-flex align-items-center">
                <span className="arrow">{renderIcon("ArrowIcon", "", 10)}</span>
                <span className="name ml-5">Profakture</span>
              </li>
            </NavLink>
            <NavLink activeClassName="subActive" to="/fakture/ulazneFakture">
              <li className="text-color-font-l pl-30 pr-20 f-s-12 d-flex align-items-center">
                <span className="arrow">{renderIcon("ArrowIcon", "", 10)}</span>
                <span className="name ml-5">Ulazne fakture</span>
              </li>
            </NavLink>
          </ul>
        </>
      );
    }
  }
);

export default NavbarLink;
