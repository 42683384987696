import { InitialState } from "../InitialState/InitialState";

export const Dokumentacija = {
  buttons: [
    { icon: "DeleteDocumentIcon", text: "Obriši", type: "delete" }
  ],
  state: {
    datumOd: undefined,
    datumDo: undefined,
    loadMore: false,
    pageNumber: 0,
    firstCheckBox: true,
    secondCheckBox: false,
    sortBy: {
      name: "",
      direction: true
    },
    filter: {},
    ...InitialState,
    dropDown: {
      name: "statusDropdown",
      open: false,
      options: [
        "Status",
        "Djelimično pl. van valute",
        "Neplaćeno u valuti",
        "Neplaćeno van valute",
        "Plaćeno"
      ],
      reference: "dropDown",
      title: "Status"
    }
  },
  table: {
    thead: {
      firstRow: ["Datum", "Djelovodni broj", "Tip", "Kupac/Dobavljač", "Grad"],
      secondRow: [
        { name: "DateInput", property: "datum" },
        { name: "TextInput", property: "djelovodni_broj" },
        { name: "TextInput", property: "tip" },
        { name: "TextInput", property: "kupac" },
        { name: "TextInput", property: "grad" }
      ]
    }
  }
};

export const DokumentacijaNav = [
  { icon: "SearchIcon", title: "Pretraži dokumente" },
  { icon: "AddDocumentIcon", title: "Dodaj dokument" }
];
