import React from "react";
import { formatMoney } from "../../../functions/formaters";
import VerticalBar from "../../../components/Charts/VerticalBar/VerticalBar";

type KlijentStatsType = {
  klijentStats: {
    kupac: {
      fakturisano: string;
      placeno: string;
      neplaceno: string;
    };
    dobavljac: {
      fakturisano: string;
      placeno: string;
      neplaceno: string;
    };
  } | null;
  shown: number;
};

const KlijentStats = ({ klijentStats, shown }: KlijentStatsType) => {
  let data;
  let fakturisano;
  let placeno;
  let neplaceno;

  if (klijentStats) {
    if (Object.keys(klijentStats).length) {
      if (shown === 0) {
        data = klijentStats.kupac
          ? klijentStats.kupac
          : { placeno: "", neplaceno: "" };
        fakturisano = klijentStats.kupac ? klijentStats.kupac.fakturisano : "";
        placeno = klijentStats.kupac ? klijentStats.kupac.placeno : "";
        neplaceno = klijentStats.kupac ? klijentStats.kupac.neplaceno : "";
      } else if (shown === 1) {
        data = klijentStats.dobavljac
          ? klijentStats.dobavljac
          : { placeno: "", neplaceno: "" };
        fakturisano = klijentStats.dobavljac
          ? klijentStats.dobavljac.fakturisano
          : "";
        placeno = klijentStats.dobavljac ? klijentStats.dobavljac.placeno : "";
        neplaceno = klijentStats.dobavljac
          ? klijentStats.dobavljac.neplaceno
          : "";
      } else {
        data = { placeno: "", neplaceno: "" };
        fakturisano = "";
        placeno = "";
        neplaceno = "";
      }
    } else {
      data = { placeno: "", neplaceno: "" };
      fakturisano = "";
      placeno = "";
      neplaceno = "";
    }
  } else {
    data = { placeno: "", neplaceno: "" };
    fakturisano = "";
    placeno = "";
    neplaceno = "";
  }
  return (
    <div className="klijentCard dark p-r d-flex">
      <img src="/images/arrow.png" alt="" />
      <VerticalBar data={data} id="klijentStats" />
      <div className="info d-flex flex-column justify-content-end pb-40 ">
        <div className="content">
          <h5 className="text-color-white f-s-20 f-w-4 line-height-1">
            Fakturisano: {formatMoney(fakturisano)} €
          </h5>
          <h5
            className="text-color-white f-s-20 f-w-4 line-height-1 mt-10"
            style={{ color: "#359B6B" }}
          >
            Plaćeno: {formatMoney(placeno)} €
          </h5>
          <h5
            className="text-color-white f-s-20 f-w-4 line-height-1 mt-10"
            style={{ color: "#DD3131" }}
          >
            Neplaćeno: {formatMoney(neplaceno)} €
          </h5>
        </div>
      </div>
    </div>
  );
};

export default KlijentStats;
