import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Loading from "../../Loading/Loading";
import { formatMoney } from "../../../functions/formaters";
import {ease} from "@amcharts/amcharts4/core";

am4core.useTheme(am4themes_animated);

type GaugeChartType = {
  data?: {
    fakturisano: number;
    neplaceno: number;
    odnos: number;
  } | null;
  data2?: {
    izlazne: number;
    ulazne: number;
    troskovi: number;
    odnos: number;
  } | null;
  title: string;
  id: string;
  min: number;
  max: number;
  type: number;
};

class GaugeChart extends Component<GaugeChartType> {
  handleZbir = (ammount: number) => {
    if (ammount < 1000) {
      return formatMoney(ammount) + "";
    } else if (ammount > 999 && ammount < 1000000) {
      return formatMoney(ammount / 1000) + "k";
    } else if(ammount >= 1000000) {
      return formatMoney(ammount / 1000000) + "M";
    }
  };

  handleZbirTooltip = (ammount: number) => {
      return formatMoney(ammount) + "";
  };
  renderGauge = () => {
    var chart = am4core.create(this.props.id, am4charts.GaugeChart);
    chart.innerRadius = -10;
    chart.startAngle = 150;
    chart.endAngle = 390;
    var axis = chart.xAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
    );

    axis.min = this.props.min;
    axis.max = this.props.max;
    axis.strictMinMax = true;
    axis.renderer.inside = true;
    axis.renderer.radius = am4core.percent(97);
    axis.renderer.labels.template.radius = 35;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.ticks.template.length = 10;
    axis.fill = am4core.color("#fff");
    axis.renderer.labels._template.fill = am4core.color("#fff");

    if (this.props.type === 0) {
      var range0 = axis.axisRanges.create();
      range0.value = 0;
      range0.endValue = 10;
      range0.axisFill.fillOpacity = 1;
      range0.axisFill.fill = am4core.color("#7BAE45");

      var range1 = axis.axisRanges.create();
      range1.value = 10;
      range1.endValue = 20;
      range1.axisFill.fillOpacity = 1;
      range1.axisFill.fill = am4core.color("#FFAC29");

      var range2 = axis.axisRanges.create();
      range2.value = 20;
      range2.endValue = 100;
      range2.axisFill.fillOpacity = 1;
      range2.axisFill.fill = am4core.color("#EA3838");
    } else if (this.props.type === 1) {
      var range0 = axis.axisRanges.create();
      range0.value = 0;
      range0.endValue = 100;
      range0.axisFill.fillOpacity = 1;
      range0.axisFill.fill = am4core.color("#7BAE45");

      var range1 = axis.axisRanges.create();
      range1.value = -100;
      range1.endValue = 0;
      range1.axisFill.fillOpacity = 1;
      range1.axisFill.fill = am4core.color("#EA3838");
    }

    var hand = chart.hands.push(new am4charts.ClockHand());
    hand.fill = am4core.color("#fff");
    hand.stroke = am4core.color("#fff");

    hand.innerRadius = am4core.percent(0);
    hand.radius = am4core.percent(85);
    hand.startWidth = 10;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
      {
        label: "Preuzmi",
        menu: [
          { type: "png", label: "PNG" },
          { type: "jpg", label: "JPG" },
          { type: "gif", label: "GIF" },
          { type: "svg", label: "SVG" },
          { type: "pdf", label: "PDF" }
        ]
      }
    ];

    var label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = 35;
    label.y = 50;
    label.horizontalCenter = "middle";
    // label.verticalCenter = "bottom";
    label.fill = am4core.color("#fff");
    if (this.props.data && this.props.data.odnos) {
      label.text = Math.ceil(this.props.data.odnos) + "%";
    } else if (this.props.data2 && this.props.data2.odnos) {
      label.text = Math.ceil(this.props.data2.odnos) + "%";
    }

    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "bottom";
    let title = chart.titles.create();
    title.text = this.props.title;
    title.fontSize = 18;
    title.marginBottom = 0;
    title.marginTop = 0;
    title.fontWeight = "500";
    title.fontFamily = "Roboto";
    title.fill = am4core.color("#fff");

    let label1 = chart.chartContainer.createChild(am4core.Label);
    label1.fill = am4core.color("#fff");
    label1.fontSize = 12;

    let label2 = chart.chartContainer.createChild(am4core.Label);
    label2.fill = am4core.color("#fff");
    label2.fontSize = 12;
    if (this.props.data) {
      hand.showValue(Math.ceil(this.props.data.odnos));
      if (this.props.type === 0) {
        label1.text =
          "Fakturisano van valute: [bold]" +
          this.handleZbir(this.props.data.fakturisano) +
          "[/] €";
        label2.text =
          "Dug van valute: [bold]" +
          this.handleZbir(this.props.data.neplaceno) +
          "[/] €";
      }
    } else if (this.props.type === 1 && this.props.data2) {
      hand.showValue(Math.ceil(this.props.data2.odnos));
      label1.text =
        "Vrijednost izlaznih faktura: [bold]" +
        this.handleZbir(this.props.data2.izlazne) +
        "[/] €";
      label2.text =
        "Vrijednost ulaznih faktura: [bold]" +
        this.handleZbir(this.props.data2.ulazne) +
        "[/] €";
      label2.marginBottom = 10;
      let label3 = chart.chartContainer.createChild(am4core.Label);
      label3.fill = am4core.color("#fff");
      label3.fontSize = 12;
      label3.align = "left";
      label3.text =
        "Iznos troškova: [bold]" +
        this.handleZbir(this.props.data2.troskovi) +
        "[/] €";
    }
    label1.align = "left";
    label1.marginBottom = 10;

    label2.align = "left";
  };

  componentDidMount() {
    if (this.props.data) {
      this.renderGauge();
    }
    if (this.props.data2) {
      this.renderGauge();
    }
  }

  componentDidUpdate(prevProps: GaugeChartType) {
    if (prevProps.data !== this.props.data) {
      this.renderGauge();
    }
    if (this.props.data2 && prevProps.data2 !== this.props.data2) {
      this.renderGauge();
    }
  }

  render() {
    if (
      (this.props.type === 0 && !this.props.data) ||
      (this.props.type === 1 && !this.props.data2)
    ) {
      return (
        <div className="gauge p-r">
          <div id={this.props.id} style={{ width: "100%", height: "380px" }}>
            <Loading small />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="gauge p-r">
            <div id={this.props.id} style={{ width: "100%", height: "100%" }} />
          </div>
        </>
      );
    }
  }
}

export default GaugeChart;
