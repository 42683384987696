import React, { Component } from "react";
import { renderIcon } from "../Icons/Icons";
import { saveTable } from "../../functions/fileGenerators";
import {
  IzlazneFaktureResponse,
  UslugeType,
} from "../../settings/TableTypes/FaktureTypes";
import {
  getKlijent,
  getUsluge,
  getUslugeFaktura,
} from "../../api/getFunctions";
import Axios from "axios";
import { formatDate1, formatMoney } from "../../functions/formaters";
import Loading from "../Loading/Loading";
import { noScrollBody } from "../../functions/functions";
import { BASE_URL } from "../../api/baseUrl";

type GeneratedFakturaProps = {
  token: string;
  removeModal: (refresh?: boolean) => void;
  faktura: IzlazneFaktureResponse;
  firma: string;
  logo: string;
  adresa: string;
  grad: string;
  telefon: string;
  pib: string;
  pdv: string;
  ziro_racun: string;
  email: string;
  websajt: string;
};

type GeneratedFakturaState = {
  klijent: {
    id: string;
    naziv_firme: string;
    pib: string;
    grad: string;
    grad_id: string;
    adresa: string;
    kontakt_osoba: string;
    telefon: string;
    email: string;
    kupac: string;
    kupac_id: string;
    dobavljac: string;
    ziro_racun: string;
    pdv_broj: string;
  };
  usluge: Array<{
    id: string;
    faktura: string;
    usluga: string;
    kolicina: string;
    neto_cijena: string;
    popust: string;
    opis_usluge: string;
  }>;
  uslugeList: Array<UslugeType>;
  loading: boolean;
};

class GeneratedFaktura extends Component<GeneratedFakturaProps> {
  axiosCancelSource = Axios.CancelToken.source();

  state: GeneratedFakturaState = {
    klijent: {
      id: "",
      naziv_firme: "",
      pib: "",
      grad: "",
      grad_id: "",
      adresa: "",
      kontakt_osoba: "",
      telefon: "",
      email: "",
      kupac: "",
      kupac_id: "",
      dobavljac: "",
      ziro_racun: "",
      pdv_broj: "",
    },
    usluge: [],
    uslugeList: [],
    loading: false,
  };

  toggleLoading = (loading: boolean) => {
    this.setState({ loading });
    noScrollBody(loading);
  };

  componentDidMount() {
    getKlijent(
      `${BASE_URL}/klijenti.php?id=${this.props.faktura.kupac_id}`,
      this.axiosCancelSource,
      this.props.token
    ).then((res) => this.setState({ klijent: res[0] }));
    getUsluge(this.axiosCancelSource, this.props.token).then((res) => {
      this.setState({ uslugeList: [...res] }, () => {
        getUslugeFaktura(
          this.props.faktura.id,
          this.axiosCancelSource,
          this.props.token
        ).then((res) => this.setState({ usluge: [...res] }));
      });
    });
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  renderBody = (page: number, length: number) => {
    if (length > 1) {
      return this.state.usluge
        .slice(page === 0 ? 0 : page * 14, page === 0 ? 14 : page * 14 + 14)
        .map((e, index) => {
          const uslugaDetalji = this.state.uslugeList.find(
            (el) => el.id === e.usluga
          );
          if (uslugaDetalji) {
            const cijenaSaPopustom =
              parseFloat(e.kolicina) * parseFloat(e.neto_cijena) -
              parseFloat(e.popust);
            const iznosPDV =
              (cijenaSaPopustom * parseFloat(uslugaDetalji.pdv)) / 100;
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {uslugaDetalji.opis || "-"}
                  {" - "}
                  {e.opis_usluge}
                </td>
                <td>{1}</td>
                <td>{e.kolicina}</td>
                <td>{formatMoney(e.neto_cijena)}€</td>
                <td>{formatMoney(e.popust)}€</td>
                <td>{formatMoney(cijenaSaPopustom)}€</td>
                <td>{uslugaDetalji.pdv}%</td>
                <td>{formatMoney(iznosPDV)}€</td>
                <td>{formatMoney(cijenaSaPopustom + iznosPDV)}€</td>
              </tr>
            );
          }
        });
    } else {
      return this.state.usluge.map((e, index) => {
        const uslugaDetalji = this.state.uslugeList.find(
          (el) => el.id === e.usluga
        );
        if (uslugaDetalji) {
          const cijenaSaPopustom =
            parseFloat(e.kolicina) * parseFloat(e.neto_cijena) -
            parseFloat(e.popust);
          const iznosPDV =
            (cijenaSaPopustom * parseFloat(uslugaDetalji.pdv)) / 100;
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {uslugaDetalji.naziv || "-"}
                {" - "}
                {e.opis_usluge}
              </td>
              <td>{1}</td>
              <td>{e.kolicina}</td>
              <td>{formatMoney(e.neto_cijena)}€</td>
              <td>{formatMoney(e.popust)}€</td>
              <td>{formatMoney(cijenaSaPopustom)}€</td>
              <td>{uslugaDetalji.pdv}%</td>
              <td>{formatMoney(iznosPDV)}€</td>
              <td>{formatMoney(cijenaSaPopustom + iznosPDV)}€</td>
            </tr>
          );
        }
      });
    }
  };

  render() {
    const pages = Math.ceil(this.state.usluge.length / 6);
    let pagesArray = [pages];
    let actualPages: number = 0;
    if (pages > 1) {
      actualPages = Math.ceil(this.state.usluge.length / 14);
      pagesArray = new Array(actualPages).fill("1");
    }
    const k = this.state.klijent;
    let cijenaSaPopustom: Array<any> = [];
    let iznosPDV: Array<any> = [];
    this.state.usluge.map((e) => {
      const uslugaDetalji = this.state.uslugeList.find(
        (el) => el.id === e.usluga
      );
      if (uslugaDetalji) {
        cijenaSaPopustom.push(
          parseFloat(e.kolicina) * parseFloat(e.neto_cijena) -
            parseFloat(e.popust)
        );
        iznosPDV.push(
          ((parseFloat(e.kolicina) * parseFloat(e.neto_cijena) -
            parseFloat(e.popust)) *
            parseFloat(uslugaDetalji.pdv)) /
            100
        );
      }
    });
    let ukupno = 0;
    let pdv = 0;
    if (cijenaSaPopustom.length > 0) {
      ukupno = cijenaSaPopustom.reduce((a, b) => a + b);
      pdv = iznosPDV.reduce((a, b) => a + b);
    }

    return (
      <div className="fakturaBackground pb-40" id="fakt">
        {this.state.loading ? <Loading small title="Generisanje PDF-a" /> : ""}
        <div className="actions">
          <div className="btn pr-10 ml-20 mt-20 ">
            <div className="btn pr-10 ml-20 mt-20  d-flex justify-content-center">
              <button
                className="d-flex align-items-center justify-content-center mr-10 exit"
                onClick={() => this.props.removeModal(false)}
              >
                <span className="f-s-14 text-transform-uppercase line-height-1">
                  Odustani
                </span>
              </button>
              <button
                className="d-flex align-items-center justify-content-center mr-10 "
                onClick={() =>
                  saveTable(
                    false,
                    this.toggleLoading,
                    0,
                    pages === 1 ? pages : actualPages
                  )
                }
              >
                {renderIcon("PDFIcon", "mr-10", 20)}{" "}
                <span className="f-s-14 text-transform-uppercase line-height-1">
                  Preuzmi
                </span>
              </button>
            </div>
          </div>
        </div>
        {pagesArray.map((e, index) => {
          return (
            <React.Fragment key={index}>
              <div
                className={`fakturaPrint ${
                  pagesArray.length === 1
                    ? "d-flex flex-column justify-content-between"
                    : ""
                } pa-20 mt-40 center-margin`}
                id={`fakturaPrint-${index}`}
              >
                {index === 0 ? (
                  <div className="header pa-20 d-flex align-items-center justify-content-between">
                    <div className="imeFirme">
                      <img
                        src={this.props.logo}
                        alt={this.props.firma}
                        style={{ width: "200px" }}
                      />
                    </div>
                    <div className="firmaInfo">
                      <span className="broj f-s-12 mr-10">
                        Telefon:{" "}
                        {this.props.telefon !== undefined
                          ? this.props.telefon
                          : ""}
                      </span>
                      <br />
                      <span className="mail f-s-12 mr-10">
                        Email:{" "}
                        {this.props.email !== undefined
                          ? this.props.email
                          : " "}
                      </span>{" "}
                      {/* <span className="sajt f-s-12">{}</span> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="content pa-20 d-flex flex-column justify-content-between">
                  {index === 0 ? (
                    <div className="klijent mb-40">
                      <div className="row">
                        <div className="col-sm-4 ">
                          <div className="klijentContent pl-10 ptb-10">
                            <h5 className="f-s-14 f-w-5 mb-10">Klijent:</h5>
                            <span className="clientName f-w-6 f-s-12 d-block">
                              {k.naziv_firme}
                            </span>
                            <span className="clientAdress f-s-12 d-block">
                              {k.adresa}
                            </span>
                            <span className="clientCity  f-s-12 d-block">
                              {k.grad}
                            </span>
                            <span className="clientPib  f-s-12 d-block">
                              PIB {k.pib}
                            </span>
                            <span className="clientPDV  f-s-12 d-block">
                              PDV {k.pdv_broj}
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-8 d-flex justify-content-center flex-column">
                          <div className="row mb-10">
                            <div className="col-sm-6">
                              <span className="f-s-12 f-w-6">
                                {this.props.faktura.pf === 1
                                  ? "Profaktura"
                                  : "Faktura"}{" "}
                                broj:
                              </span>
                            </div>
                            <div className="col-sm-6 text-right">
                              <span className="f-s-12 f-w-6">
                                {this.props.faktura.br_fakture}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-10">
                            <div className="col-sm-6">
                              <span className="f-s-12">
                                Mjesto i datum izdavanja:
                              </span>
                            </div>
                            <div className="col-sm-6 text-right">
                              <span className="f-s-12">
                                {this.props.grad !== undefined
                                  ? this.props.grad
                                  : " "}
                                ,{" "}
                                {this.props.faktura !== undefined
                                  ? formatDate1(this.props.faktura.datum) + "."
                                  : " "}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-10">
                            <div className="col-sm-6">
                              <span className="f-s-12">Valuta:</span>
                            </div>
                            <div className="col-sm-6 text-right">
                              <span className="f-s-12">
                                {this.props.faktura !== undefined
                                  ? formatDate1(this.props.faktura.valuta) + "."
                                  : " "}
                              </span>
                            </div>
                          </div>
                          {/*<div className="row ">*/}
                          {/*  <div className="col-sm-6">*/}
                          {/*    <span className="f-s-12">Valuta:</span>*/}
                          {/*  </div>*/}
                          {/*  <div className="col-sm-6 text-right">*/}
                          {/*    <span className="f-s-12">*/}
                          {/*      {this.props.faktura.datum}*/}
                          {/*    </span>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="table" style={{ minHeight: "auto" }}>
                    <table>
                      {this.state.usluge.slice(
                        index === 0 ? 0 : index * 14,
                        index === 0 ? 14 : index * 14 + 14
                      ).length !== 0 ? (
                        <thead>
                          <tr>
                            <th rowSpan={2} style={{ width: "30px" }}>
                              rb
                            </th>
                            <th rowSpan={2}>Opis Usluge</th>
                            <th
                              rowSpan={2}
                              style={{ width: "35px", padding: "0 5px" }}
                            >
                              JM
                            </th>
                            <th
                              rowSpan={2}
                              style={{ width: "35px", padding: "0 5px" }}
                            >
                              Kol.
                            </th>
                            <th
                              rowSpan={2}
                              style={{ width: "75px", padding: "0 5px" }}
                            >
                              Jedinična cijena
                            </th>
                            <th rowSpan={2}>Rabat</th>
                            <th
                              rowSpan={2}
                              style={{ width: "90px", padding: "0 5px" }}
                            >
                              Iznos bez PDV sa rabatom
                            </th>
                            <th colSpan={2}>PDV</th>
                            <th style={{ width: "85px", padding: "0 5px" }}>
                              Iznos sa PDV
                            </th>
                          </tr>
                          <tr>
                            <th style={{ width: "35px" }}>%</th>
                            <th style={{ width: "60px" }}>Iznos</th>
                            <th />
                          </tr>
                        </thead>
                      ) : (
                        ""
                      )}
                      <tbody>{this.renderBody(index, pagesArray.length)}</tbody>
                    </table>
                  </div>
                  {pagesArray.length - 1 === index ||
                  this.state.usluge.slice(
                    index === 0 ? 0 : index * 14,
                    index === 0 ? 14 : index * 14 + 14
                  ).length < 7 ? (
                    <>
                      <div className="sum mt-20 pr-20">
                        <div className="bezPoreza text-right">
                          <span className="f-s-12">Iznos bez poreza:</span>
                          <span className=" ml-40 f-s-12">
                            {formatMoney(ukupno)}€
                          </span>
                        </div>
                        <div className="porez text-right">
                          <span className="f-s-12">
                            Porez na dodatnu vrijednost:
                          </span>
                          <span className=" ml-40 f-s-12">
                            {formatMoney(pdv)}€
                          </span>
                        </div>
                        <div className="ukupno text-right">
                          <span className="f-s-12 f-w-5">UKUPNO:</span>
                          <span className=" ml-40 f-s-12 f-w-5">
                            {formatMoney(ukupno + pdv)}€
                          </span>
                        </div>
                      </div>
                      <div className="napomena mt-20">
                        <h4 className="f-w-4 f-s-16">Ovo je PDV račun</h4>
                        <div className="napomenaContainer pt-5 prl-10 mt-10">
                          <span className="f-s-14 f-w-5 ">
                            Napomena:{" "}
                            {this.props.faktura !== undefined
                              ? this.props.faktura.napomena
                              : " "}
                          </span>
                        </div>
                      </div>
                      <div className="signatures mt-50">
                        <div className="row">
                          <div className="col-sm-4 text-center">
                            <h5 className="f-s-14 f-w-4 mb-30">
                              Fakturu izdao
                            </h5>
                            <div className="signatureLine" />
                          </div>
                          <div className="col-sm-4" />
                          <div className="col-sm-4 text-center">
                            <h5 className="f-s-14 f-w-4 mb-30">
                              Fakturu primio
                            </h5>
                            <div className="signatureLine" />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {pagesArray.length - 1 === index ||
                this.state.usluge.slice(
                  index === 0 ? 0 : index * 14,
                  index === 0 ? 14 : index * 14 + 14
                ).length < 7 ? (
                  <div className="footer text-center">
                    <div className="first">
                      <ul>
                        <li className="mr-10 f-s-12">{this.props.firma}</li>
                        <li className="mr-10 f-s-12">
                          &#9679; {this.props.adresa}
                        </li>
                        <li className=" f-s-12">
                          &#9679; tel: {this.props.telefon}
                        </li>
                      </ul>
                    </div>
                    <div className="second">
                      <ul>
                        <li className="mr-10 f-s-12">PIB: {this.props.pib}</li>
                        <li className="mr-10 f-s-12">
                          &#9679; PDV: {this.props.pdv}
                        </li>
                        <li className=" f-s-12">
                          &#9679; žiro račun: {this.props.ziro_racun}
                        </li>
                      </ul>
                    </div>
                    <div className="third">
                      <ul>
                        <li className="mr-10 f-s-12">{this.props.websajt}</li>
                        <li className="mr-10 f-s-12">
                          &#9679; {this.props.email}
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default GeneratedFaktura;
