import { IUnosFaktura } from "../UnosFaktura";

export const validateForm = (data: IUnosFaktura, type: string) => {
  let invalidFields: Array<any> = [];
  if (type === "unos") {
    if (data.brfakture.trim() === "") {
      invalidFields.push("Polje broj fakture je obavezno!");
    } else if (data.brfakture.trim().length > 50) {
      invalidFields.push("Dužina broja fakture ne može biti veća od 50 karaktera!");
    }
    if (!data.datum) {
      invalidFields.push("Polje datum izdavanja je obavezno!");
    }
    if (data.napomena.trim().length > 500) {
      invalidFields.push("Dužina napomene ne može biti veća od 500 karaktera!");
    }
    // if (data.klijent === "") {
    //     invalidFields.push("Klijent");
    // }

    if (data.valuta === "") {
      invalidFields.push("Polje valuta je obavezno!");
    } else if (data.valuta === "0") {
      invalidFields.push("Valuta mora biti veća od 0!");
    } else if (data.valuta.length > 11) {
      invalidFields.push("Valuta ne može imati preko 11 cifara!");
    }
    if (data.klijent === "" || data.klijent === undefined) {
      invalidFields.push(`Polje ${window.location.pathname === "/fakture/ulazneFakture" ? "dobavljač" : "kupac"} je obavezno!`);
    }
    if (data.cijenaUkupno < 0) {
      invalidFields.push("Ukupna vrijednost fakture ne smije biti manja od 0");
    }
    data.usluge.map(e => {
      if (e.neto === "") {
        invalidFields.push("Polje neto cijena je obavezno!");
      }
      if (e.naziv === "") {
        invalidFields.push("Polje usluga je obavezno!");
      }
    });
  }
  return invalidFields;
};
