import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const saveTable = (
  kif?: boolean,
  toggleLoading?: (loading: boolean) => void,
  type?: number,
  pages?: number
) => {
  const scrollY = window.scrollY;
  if (!kif) {
    if(type === 0){
    if (pages) {
      const input = document.getElementById("fakturaPrint-0");
      const inputs: Array<HTMLElement | null> = [];
      for (let index = 0; index < pages; index++) {
        inputs.push(document.getElementById(`fakturaPrint-${index}`));
      }
      if (toggleLoading) {
        toggleLoading(true);
      }
      if (inputs.length > 0 && input) {
        if (input) {
          const divWidth = input.scrollWidth;
          const divHeight = input.scrollHeight;
          const ratio = divHeight / divWidth;
          const pdf = new jsPDF("p", "mm", "a4");
          let elements = [];
          let images: any = [];
          let it = 0;
          const getImage = (e: HTMLElement | null) => {
            if (e) {
              window.scrollTo(0, 0);
              html2canvas(e, {
                width: divWidth,
                height: divHeight,
                allowTaint: true,
                useCORS: true,
                scale: window.innerWidth < 1920 ? 2.2 : 2,
              }).then(canvas => {
                const imgData = canvas.toDataURL("image/png");
                images.push(imgData);
                elements.push(1);
                it++;
                window.scrollTo(0, scrollY);
                if (it <= inputs.length) {
                  getImage(inputs[it]);
                }
              });
            }
          };
          getImage(inputs[it]);
          const download = setInterval(() => {
            if (elements.length === inputs.length) {
              let width = pdf.internal.pageSize.getWidth();
              let height = pdf.internal.pageSize.getHeight();
              height = ratio * width;
              images.forEach((e: any, index: number) => {
                if (index > 0) {
                  pdf.addPage();
                }
                pdf.addImage(e, "PNG", 0, 0, width, height);
              });

              pdf.save("faktura.pdf");
              stopInterval();
              if (toggleLoading) {
                toggleLoading(false);
              }
            }
          }, 300);

          const stopInterval = () => {
            clearInterval(download);
          };
        }
      }
    }
    } else {
      const input = document.getElementById("analitickaPrint-0");
      const inputs: Array<HTMLElement | null> = [];
      for (let index = 0; index < (pages !== undefined ? pages : 1); index++) {
        inputs.push(document.getElementById(`analitickaPrint-${index}`));
      }
      if (toggleLoading) {
        toggleLoading(true);
      }
        if (inputs.length > 0 && input) {
          if (input) {
            const divWidth = input.scrollWidth;
            const divHeight = input.scrollHeight;
            const ratio = divHeight / divWidth;
            const pdf = new jsPDF("p", "mm", "a4");
            let elements = [];
            let images: any = [];
            let it = 0;
            const getImage = (e: HTMLElement | null) => {
              if (e) {
                window.scrollTo(0, 0);
                html2canvas(e, {
                  width: divWidth,
                  height: divHeight,
                  allowTaint: true,
                  useCORS: true
                }).then(canvas => {
                  const imgData = canvas.toDataURL("image/png");
                  images.push(imgData);
                  elements.push(1);
                  it++;
                  window.scrollTo(0, scrollY);
                  if (it <= inputs.length) {
                    getImage(inputs[it]);
                  }
                });
              }
            };
            getImage(inputs[it]);
            const download = setInterval(() => {
              if (elements.length === inputs.length) {
                let width = pdf.internal.pageSize.getWidth();
                let height = pdf.internal.pageSize.getHeight();
                height = ratio * width;
                images.forEach((e: any, index: number) => {
                  if (index > 0) {
                    pdf.addPage();
                  }
                  pdf.addImage(e, "PNG", 0, 0, width, height);
                });

                pdf.save("analiticka-kartica.pdf");
                stopInterval();
                if (toggleLoading) {
                  toggleLoading(false);
                }
              }
            }, 300);

            const stopInterval = () => {
              clearInterval(download);
            };
          }
        }
    }
  } else {
    if (pages) {
      const input = document.getElementById("kifPrint-0");
      const spanNazivKIF = document.getElementById("nazivKIF");
      const spanDatumKIF = document.getElementById("datum");
      const nazivKIF = spanNazivKIF !== null && spanNazivKIF.innerText === "Knjiga izdatih računa" ? "kif" : "kuf";
      const datumKIF = spanDatumKIF !== null && spanDatumKIF.innerText.slice(6,13);
      const inputs: Array<HTMLElement | null> = [];
      for (let index = 0; index < pages; index++) {
        inputs.push(document.getElementById(`kifPrint-${index}`));
      }
      if (toggleLoading) {
        toggleLoading(true);
      }
      if (inputs.length > 0 && input) {
        if (input) {
          const divWidth = input.scrollWidth;
          const divHeight = input.scrollHeight;
          const ratio = divHeight / divWidth;
          const pdf = new jsPDF("l", "mm", "a3");
          let elements = [];
          let images: any = [];
          let it = 0;
          const getImage = (e: HTMLElement | null) => {
            if (e) {
              window.scrollTo(0, 0);
              html2canvas(e, {
                width: divWidth,
                height: divHeight
              }).then(canvas => {
                const imgData = canvas.toDataURL("image/png");
                images.push(imgData);
                elements.push(1);
                it++;
                window.scrollTo(0, scrollY);
                if (it <= inputs.length) {
                  getImage(inputs[it]);
                }
              });
            }
          };
          getImage(inputs[it]);
          const download = setInterval(() => {
            if (elements.length === inputs.length) {
              let width = pdf.internal.pageSize.getWidth();
              let height = pdf.internal.pageSize.getHeight();
              height = ratio * width;
              images.forEach((e: any, index: number) => {
                if (index > 0) {
                  pdf.addPage();
                }
                pdf.addImage(e, "PNG", 0, 0, width, height);
              });

              pdf.save(`${nazivKIF}-${datumKIF}.pdf`);
              stopInterval();
              if (toggleLoading) {
                toggleLoading(false);
              }
            }
          }, 300);

          const stopInterval = () => {
            clearInterval(download);
          };
        }
      }
    }
  }
};
