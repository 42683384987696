import React, { Component, ChangeEvent, createRef } from "react";
import axios from "axios";
import { IFaktureSettings } from "../../interfaces/pageInterfaces";
import { renderInput } from "../../components/Inputs/Inputs";
import { IDateInput, ITextInput } from "../../interfaces/inputInterfaces";
import CustomButton from "../../components/custom/CustomButton/CustomButton";
import {
  getFakture,
  getDobavljaci,
  getDokumentacija,
  getUgovori,
  getUsluge,
  getLinijeBiznisa,
  getAllAlarmi,
} from "../../api/getFunctions";
import {formatMoney, formatDate, formatDate1} from "../../functions/formaters";
import ScrollTopButton from "../../components/custom/ScrollTopButton/ScrollTopButton";
import { sortItems, noScrollBody } from "../../functions/functions";
import { renderIcon } from "../../components/Icons/Icons";
import {
  TableDisplayState,
  FilterFakture,
  IzlazneFaktureResponse,
} from "../../settings/TableTypes/FaktureTypes";
import {
  renderLegend,
  renderCheckbox,
  renderNav,
  renderLinijaBiznisa,
  renderSelect,
} from "./functions/renderFunctions";
import UnosFaktura from "../../components/UnosFaktura/UnosFaktura";
import {
  postDeleteFaktura,
  postInsertUplata,
  postDeleteKupac,
  postDeleteDokument,
  postDeleteAlarm,
} from "../../api/postFunctions";
import ModalSmall from "../../components/modals/ModalSmall/ModalSmall";
import ModalUplata from "../../components/modals/ModalUplata/ModalUplata";
import ModalBig from "../../components/modals/ModalBig/ModalBig";
import UnosKupca from "../../components/UnosKupca/UnosKupca";
import UnosDokumenta from "../../components/UnosDokumenta/UnosDokumenta";
import UnosUsluge from "../../components/UnosUsluge/UnosUsluge";
import UnosAlarma from "../../components/UnosAlarma/UnosAlarma";
import { renderBigModal } from "./functions/modalHelpers";
import UnosUgovora from "../../components/UnosUgovora/UnosUgovora";
import GeneratedFaktura from "../../components/fileComponents/GeneratedFaktura";
import UnosLinijaBiznisa from "../../components/UnosLinijaBiznisa/UnosLinijaBiznisa";
import { BASE_URL } from "../../api/baseUrl";

type StateKeys = keyof TableDisplayState;
type FilterKeys = keyof FilterFakture;

class TableDisplay extends Component<IFaktureSettings, TableDisplayState> {
  firstRef = createRef<any>();
  axiosCancelSource = axios.CancelToken.source();

  constructor(props: any) {
    super(props);
    if (this.props.settings) {
      this.props.changePageTitle(this.props.settings.title);
      this.state = this.props.settings.page[0].state;
    }
  }

  handleDateChange = (datum: Date | undefined, name: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [name]: datum,
      }),
      () => {
        if (this.props.settings.type !== 4) {
          if (this.props.settings.type === 5) {
            const datumZak: any = formatDate(this.state.datum_zak);
            const datumVazenja: any = formatDate(this.state.datum_vazenja);
            this.setState((prevState: TableDisplayState) => ({
              ...prevState,
              filter: {
                ...prevState.filter,
                datum_zak: datumZak,
                datum_vazenja: datumVazenja,
              },
            }));
          } else {
            const datumDo: any = formatDate(this.state.datumDo);
            const datumOd: any = formatDate(this.state.datumOd);
            this.setState((prevState: TableDisplayState) => ({
              ...prevState,
              filter: {
                ...prevState.filter,
                do: datumDo,
                od: datumOd,
              },
            }));
          }
        } else {
          const datum: any = formatDate(this.state.datum);
          this.setState((prevState: TableDisplayState) => ({
            ...prevState,
            filter: {
              ...prevState.filter,
              datum,
            },
          }));
        }
      }
    );
  };

  handleNavChange = (
    e: React.MouseEvent<HTMLLIElement>,
    pageNumber: number
  ) => {
    const parent: any = e.currentTarget.parentNode;
    parent.querySelectorAll("li").forEach((el: any) => {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
    this.setState(
      {
        pageNumber,
        filter: this.props.settings.page[0].state.filter,
      },
      () => {
        if (
          this.state.pageNumber === 2 &&
          this.state.mjesecneFakture.length < 1
        ) {
          this.getData();
        }
        if (
          this.state.pageNumber === 0 &&
          [3, 4, 6, 8].includes(this.props.settings.type)
        ) {
          this.getData();
        }
      }
    );
  };

  handleInputChange = (e: ChangeEvent<HTMLInputElement>, name: FilterKeys) => {
    e.persist();
    if (this.props.settings.type !== 4) {
      console.log(e.target.value);
      this.setState((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          [name]: e.target.value,
        },
      }));
      console.log(this.state.filter);
    } else {
      if (name === "kupac") {
        this.setState((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            klijent: e.target.value,
            kupac: e.target.value,
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [name]: e.target.value,
          },
        }));
      }
    }
  };

  handleDropdown = (e: React.MouseEvent<HTMLButtonElement>, name: string) => {
    e.stopPropagation();
    this.setState((prevState: any) => ({
      ...prevState,
      dropDown: {
        ...prevState.dropDown,
        open: !prevState.dropDown.open,
      },
    }));
  };

  handleFilterClick = (e: React.MouseEvent<HTMLLIElement>, name: string) => {
    const title = e.currentTarget.innerHTML;
    e.persist();
    let type = 0;
    this.setState((prevState: any) => ({
      ...prevState,
      dropDown: {
        ...prevState.dropDown,
        title,
        status: type,
        open: false,
      },
    }));
  };

  handleInputRender = (info: any, index: number) => {
    const name: StateKeys = info.property;
    const inputName: FilterKeys = info.property;
    let value: any = "";
    const date = this.state[name];
    if (info.name === "DateInput") {
      if (typeof this.state[name] === "string") {
        value = this.state[name];
      } else {
        value = "";
      }
      const data: IDateInput = {
        date: date instanceof Date ? date : undefined,
        onChange: this.handleDateChange,
        name: name,
      };
      return renderInput(info.name, { data });
    } else {
      if (typeof this.state.filter[inputName] === "string") {
        value = this.state.filter[inputName];
      } else {
        value = "";
      }
      const data: ITextInput = {
        placeholder: this.props.settings.page[this.state.pageNumber].table.thead
          .firstRow[index],
        value,
        name: info.property,
        type: info.type,
        onChange: this.handleInputChange,
      };

      return renderInput(info.name, { data });
    }
  };

  handleSortHelper = (sortName: string, type: any) => {
    if (this.props.settings.type < 3 || this.props.settings.type === 7) {
      const { fakture, pageNumber } = this.state;
      const sortedArray = sortItems(
        fakture,
        type,
        this.state.sortBy.direction,
        sortName
      );

      if (pageNumber === 0) {
        this.setState((prevState: TableDisplayState) => ({
          fakture: sortedArray.sortedArray,
          sortBy: {
            ...prevState.sortBy,
            name: sortName,
          },
        }));
      } else {
        this.setState((prevState: TableDisplayState) => ({
          mjesecneFakture: sortedArray.sortedArray,
          sortBy: {
            ...prevState.sortBy,
            name: sortName,
          },
        }));
      }
    } else if (this.props.settings.type === 3) {
      const { dobavljaciFiltrirani } = this.state;
      const sortedArray = sortItems(
        dobavljaciFiltrirani,
        type,
        this.state.sortBy.direction,
        sortName
      );

      this.setState((prevState: TableDisplayState) => ({
        dobavljaciFiltrirani: sortedArray.sortedArray,
        sortBy: {
          ...prevState.sortBy,
          name: sortName,
        },
      }));
    } else if (this.props.settings.type === 4) {
      const { dokumentacijaFiltrirana } = this.state;
      const sortedArray = sortItems(
        dokumentacijaFiltrirana,
        type,
        this.state.sortBy.direction,
        sortName
      );

      this.setState((prevState: TableDisplayState) => ({
        dokumentacijaFiltrirana: sortedArray.sortedArray,
        sortBy: {
          ...prevState.sortBy,
          name: sortName,
        },
      }));
    } else if (this.props.settings.type === 5) {
      const { istekliUgovori, sviUgovori, pageNumber } = this.state;
      const localType =
        sortName === "ui" ? 1 : sortName === "vrijednost" ? 1 : "1";
      if (pageNumber === 0) {
        const sortedArray = sortItems(
          istekliUgovori,
          localType,
          this.state.sortBy.direction,
          sortName
        );
        this.setState((prevState: TableDisplayState) => ({
          istekliUgovori: sortedArray.sortedArray,
          sortBy: {
            ...prevState.sortBy,
            name: sortName,
          },
        }));
      } else {
        const sortedArray = sortItems(
          sviUgovori,
          localType,
          this.state.sortBy.direction,
          sortName
        );

        this.setState((prevState: TableDisplayState) => ({
          sviUgovori: sortedArray.sortedArray,
          sortBy: {
            ...prevState.sortBy,
            name: sortName,
          },
        }));
      }
    } else if (this.props.settings.type === 6) {
      const { usluge } = this.state;
      const sortedArray = sortItems(
        usluge,
        type,
        this.state.sortBy.direction,
        sortName
      );

      this.setState((prevState: TableDisplayState) => ({
        usluge: sortedArray.sortedArray,
        sortBy: {
          ...prevState.sortBy,
          name: sortName,
        },
      }));
    } else if (this.props.settings.type === 8) {
      const { alarmi } = this.state;
      const sortedArray = sortItems(
        alarmi !== undefined ? alarmi : [],
        type,
        this.state.sortBy.direction,
        sortName
      );

      this.setState((prevState: TableDisplayState) => ({
        alarmi: sortedArray.sortedArray,
        sortBy: {
          ...prevState.sortBy,
          name: sortName,
        },
      }));
    }
  };

  handleSortBy = (
    e: React.MouseEvent<HTMLTableHeaderCellElement>,
    sortBy: string
  ) => {
    var elements = document.querySelector(".sortDirection.d-block");
    const element = e.currentTarget.querySelector("span");
    if (elements && elements !== element) {
      elements.classList.remove("d-block");
      elements.classList.remove("rotate");
      elements.classList.add("d-none");
    }

    if (element) {
      if (element.classList.contains("d-block")) {
        element.classList.toggle("rotate");
      } else {
        element.classList.remove("d-none");
        element.classList.add("d-block");
      }
    }
    const sortName =
      sortBy === "datumDo" || sortBy === "datumOd"
        ? "datum"
        : (sortBy === "klijent" && this.props.settings.type < 3) ||
          (sortBy === "klijent" && this.props.settings.type === 7)
        ? "kupac"
        : sortBy === "brfakture"
        ? "br_fakture"
        : sortBy;
    if (sortName === this.state.sortBy.name) {
      this.setState(
        (prevState: TableDisplayState) => ({
          sortBy: {
            ...prevState.sortBy,
            direction: !prevState.sortBy.direction,
          },
        }),
        () => {
          if (
            sortName !== "datum" &&
            sortName !== "iznos" &&
            sortName !== "dug" &&
            sortName !== "ugovor" &&
            sortName !== "valuta" &&
            sortName !== "pdv"
          ) {
            this.handleSortHelper(sortName, "string");
          } else if (
            sortName === "iznos" ||
            sortName === "dug" ||
            sortName === "ugovor" ||
            sortName === "valuta" ||
            sortName === "pdv"
          ) {
            this.handleSortHelper(sortName, 1);
          } else if (sortName === "datum") {
            this.handleSortHelper(sortName, "datum");
          }
        }
      );
    } else {
      this.setState(
        (prevState: TableDisplayState) => ({
          sortBy: {
            ...prevState.sortBy,
            direction: true,
          },
        }),
        () => {
          if (
            sortName !== "datum" &&
            sortName !== "iznos" &&
            sortName !== "dug" &&
            sortName !== "ugovor" &&
            sortName !== "valuta" &&
            sortName !== "pdv"
          ) {
            this.handleSortHelper(sortName, "string");
          } else if (
            sortName === "iznos" ||
            sortName === "dug" ||
            sortName === "ugovor" ||
            sortName === "valuta" ||
            sortName === "pdv"
          ) {
            this.handleSortHelper(sortName, 1);
          } else if (sortName === "datum") {
            this.handleSortHelper(sortName, "datum");
          }
        }
      );
    }
  };

  renderTableHead = () => {
    return (
      <thead>
        <tr>
          {this.props.settings.page[
            this.state.pageNumber
          ].table.thead.firstRow.map((e: any, index: number) => {
            if (
              this.props.settings.page[this.state.pageNumber].table.thead
                .secondRow[index].property !== "status"
            ) {
              return (
                <th
                  key={index}
                  className="f-s-12 sort prl-10 p-r"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={(e: React.MouseEvent<HTMLTableHeaderCellElement>) =>
                    this.handleSortBy(
                      e,
                      this.props.settings.page[this.state.pageNumber].table
                        .thead.secondRow[index].property
                    )
                  }
                >
                  {e}
                  <span className="center-a-h d-none sortDirection">
                    {renderIcon("ArrowIcon", "")}
                  </span>
                </th>
              );
            } else {
              return (
                <th key={index} className="f-s-12 sort prl-10 p-r nonActive">
                  {e}
                </th>
              );
            }
          })}
        </tr>
        <tr>
          {this.props.settings.page[
            this.state.pageNumber
          ].table.thead.secondRow.map((e: any, index: number) => {
            return (
              <th key={index} className="pt-30 prl-10">
                {this.handleInputRender(e, index)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  handleStatusIcon = (data: IzlazneFaktureResponse) => {
    let status;
    const today = new Date();
    const valuta = new Date(data.valuta);
    if (parseInt(data.dug) < 0.01) {
      status = "green";
    } else if (parseInt(data.uplaceno) === 0) {
      if (valuta >= today) {
        status = "blue";
      } else {
        status = "red";
      }
    } else if (parseInt(data.dug) >= 0.01 && parseInt(data.uplaceno) > 0) {
      if (valuta >= today) {
        status = "yellow";
      } else {
        status = "orange";
      }
    } else {
      status = "";
    }
    return <span className={`circle ${status}`} />;
  };

  deleteFaktura = (id?: string) => {
    if (id) {
      postDeleteFaktura(id, this.axiosCancelSource, this.props.api).then(
        (res) => {
          if (res[0] === "deleted") {
            this.getData();
            this.removeModal();
          }
        }
      );
    }
  };

  deleteKupac = (id?: string) => {
    if (id) {
      postDeleteKupac(id, this.axiosCancelSource, this.props.api).then(
        (res) => {
          if (res[0] === "deleted") {
            this.getData();
            this.removeModal();
          }
        }
      );
    }
  };

  deleteDokument = (id?: string) => {
    if (id) {
      postDeleteDokument(id, this.axiosCancelSource, this.props.api).then(
        (res) => {
          if (res[0] === "deleted") {
            this.getData();
            this.removeModal();
          }
        }
      );
    }
  };

  deleteAlarm = (id?: string) => {
    if (id) {
      postDeleteAlarm(id, this.axiosCancelSource, this.props.api).then(
        (res) => {
          if (res[0] === "deleted") {
            this.getData();
            this.removeModal();
          }
        }
      );
    }
  };

  deleteUsluga = (id?: string) => {};

  insertUplata = (
    id: string,
    faktura: string,
    datum: string,
    vrijednost: string
  ) => {
    const data = {
      id,
      faktura,
      datum,
      vrijednost,
    };
    postInsertUplata(data, this.axiosCancelSource, this.props.api).then(
      (res) => {
        if (res.id) {
          this.removeModal(true);
        }
      }
    );
  };

  updateKupac = (
    id: string,
    faktura: string,
    datum: string,
    vrijednost: string
  ) => {
    const data = {
      id,
      faktura,
      datum,
      vrijednost,
    };
    postInsertUplata(data, this.axiosCancelSource, this.props.api).then(
      (res) => {
        if (res.id) {
          this.removeModal(true);
        }
      }
    );
  };

  showModal = (
    e: React.MouseEvent<HTMLElement>,
    modalType?: string,
    modalID?: string,
    modalName?: string
  ) => {
    e.stopPropagation();
    this.setState({ modal: true, modalType, modalID, modalName });
    noScrollBody(true);
  };

  removeModal = (refresh?: boolean) => {
    this.setState({ modal: false });
    noScrollBody(false);
    if (refresh) {
      this.getData(undefined, undefined, refresh);
    }
  };

  renderModal = () => {
    if (this.state.modalType === "delete") {
      if (this.props.settings.type === 1) {
        return (
          <ModalSmall
            question="Da li ste sigurni da želite obrisati
            odabranu profakturu?"
            title="Brisanje profakture"
            buttons={["delete", "document"]}
            options={["Da", "Ne"]}
            id={this.state.modalID ? this.state.modalID : ""}
            onClick={this.deleteFaktura}
            removeModal={this.removeModal}
          />
        );
      } else if (this.props.settings.type < 3) {
        return (
          <ModalSmall
            question="Da li ste sigurni da želite obrisati
            odabranu fakturu?"
            title="Brisanje fakture"
            buttons={["delete", "document"]}
            options={["Da", "Ne"]}
            id={this.state.modalID ? this.state.modalID : ""}
            onClick={this.deleteFaktura}
            removeModal={this.removeModal}
          />
        );
      } else if (this.props.settings.type === 3) {
        return (
          <ModalSmall
            question="Da li ste sigurni da želite da izbrišete ovog kupca/dobavljača?"
            title="Brisanje kupca/dobavljača"
            buttons={["delete", "document"]}
            options={["Da", "Ne"]}
            id={this.state.modalID ? this.state.modalID : ""}
            onClick={this.deleteKupac}
            removeModal={this.removeModal}
          />
        );
      } else if (this.props.settings.type === 4) {
        return (
          <ModalSmall
            question="Da li ste sigurni da želite da izbrišete ovaj dokument?"
            title="Brisanje dokumenta"
            buttons={["delete", "document"]}
            options={["Da", "Ne"]}
            id={this.state.modalID ? this.state.modalID : ""}
            onClick={this.deleteDokument}
            removeModal={this.removeModal}
          />
        );
      } else if (this.props.settings.type === 8) {
        return (
          <ModalSmall
            question="Da li ste sigurni da želite da izbrišete ovaj alarm?"
            title="Brisanje alarma"
            buttons={["delete", "document"]}
            options={["Da", "Ne"]}
            id={this.state.modalID ? this.state.modalID : ""}
            onClick={this.deleteAlarm}
            removeModal={this.removeModal}
          />
        );
      }
    } else if (this.state.modalType === "euro") {
      const faktura = this.state.fakture.find(
        (e) => e.id === this.state.modalID
      );
      let dug: number;
      if (faktura) {
        dug = parseFloat(faktura.dug) > 0 ? parseFloat(faktura.dug) : 0;
      } else {
        dug = 0;
      }
      return (
        <ModalUplata
          id={this.state.modalID}
          title="Iznos za uplatu fakture"
          removeModal={this.removeModal}
          onClick={this.insertUplata}
          dug={dug}
          token={this.props.api}
        />
      );
    } else if (
      this.state.modalType === "izmjena" ||
      this.state.modalType === "copy"
    ) {
      return renderBigModal(
        this.props.api,
        this.state,
        this.props.settings.type,
        this.removeModal
      );
    } else if (this.state.modalType === "document") {
      const faktura = this.state.fakture.filter((e) => {
        return e.id === this.state.modalID;
      })[0];
      return (
        <GeneratedFaktura
          faktura={faktura}
          removeModal={this.removeModal}
          token={this.props.api}
          firma={this.props.firma || ""}
          logo={this.props.logo || ""}
          adresa={this.props.adresa || ""}
          grad={this.props.grad || ""}
          telefon={this.props.telefon || ""}
          pib={this.props.pib || ""}
          pdv={this.props.pdv || ""}
          ziro_racun={this.props.ziro_racun || ""}
          email={this.props.email || ""}
          websajt={this.props.websajt || ""}
        />
      );
    }
  };

  showKlijent = (name: string) => {
    this.props.history.push(`/klijent/${name}`);
  };

  showDocument = (dokument: string) => {
    // window.location.assign(`${BASE_URL}/${dokument}`);
    window.open(`${BASE_URL}/${dokument}`, "_blank");
  };

  renderTableBody = () => {
    if (this.props.settings.type < 3) {
      return (
        <tbody>
          {this.state[
            this.state.pageNumber === 0 ? "fakture" : "mjesecneFakture"
          ].map((el, index: number) => {
            const datum = new Date(el.datum);
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            };
            const parsedDatum = datum.toLocaleDateString("sr-latn-SR", options);
            return (
              <tr className="transition-1" key={"r" + index}>
                {(this.state.pageNumber === 0 &&
                  this.props.settings.type !== 1) ||
                (this.state.pageNumber === 0 &&
                  this.props.settings.type !== 7) ? (
                  <td
                    className="text-center prl-10 f-s-12"
                    onClick={(ev: React.MouseEvent<HTMLElement>) =>
                      this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                    }
                  >
                    {this.handleStatusIcon(el)}
                  </td>
                ) : (
                  false
                )}
                <td
                  className="text-center prl-10 f-s-12"
                  colSpan={2}
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {parsedDatum}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {this.state.pageNumber === 0 ? el.br_fakture : el.ugovor}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {el.kupac}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {el.grad}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {formatMoney(el.iznos)}€
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {formatMoney(parseFloat(el.dug) > 0 ? el.dug : 0)}€
                </td>
                {this.props.settings.type !== 2 &&
                  this.props.settings.page[this.state.pageNumber].buttons.map(
                    (e: any, index: number) => {
                      return (
                        <td className="text-center prl-10" key={index}>
                          <CustomButton
                            icon={e.icon}
                            text={e.text}
                            onClick={(ev: React.MouseEvent<HTMLElement>) =>
                              this.showModal(
                                ev,
                                !e.mod ? e.type : e.mod,
                                el.id,
                                "Kopija Fakture"
                              )
                            }
                            type={e.type}
                            id={el.id}
                          />
                        </td>
                      );
                    }
                  )}
                {this.props.settings.type === 2 && this.state.pageNumber === 0 && (
                  <td className="text-center prl-10">
                    <CustomButton
                      icon={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[0].icon
                      }
                      text={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[0].text
                      }
                      onClick={(ev: React.MouseEvent<HTMLElement>) =>
                        this.showModal(
                          ev,
                          !this.props.settings.page[this.state.pageNumber]
                            .buttons[0].mod
                            ? this.props.settings.page[this.state.pageNumber]
                                .buttons[0].type
                            : this.props.settings.page[this.state.pageNumber]
                                .buttons[0].mod,
                          el.id,
                          "Kopija Fakture"
                        )
                      }
                      type={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[0].type
                      }
                      id={el.id}
                    />
                  </td>
                )}

                {this.props.settings.type === 2 && this.state.pageNumber === 0 && (
                  <td
                    className="text-center prl-10"
                    style={{
                      cursor: `${el.dokument.length > 0 ? "pointer" : "auto"}`,
                    }}
                  >
                    <div className="buttonContainer document">
                      <button
                        className={`document transition-1 d-flex align-items-center justify-content-center ${
                          el.dokument.length > 0 ? "" : "invisible"
                        }`}
                        onClick={() => this.showDocument(el.dokument)}
                      >
                        <span className="icon line-height-1 mr-5 d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 383.9 512"
                            className=""
                            width="12"
                          >
                            <path
                              fill="#8292A2"
                              d="M383.9,103.6c0-3.1-1.2-6.1-3.5-8.4L288.6,3.5c0,0,0,0,0,0c-2.2-2.2-5.2-3.5-8.5-3.5H25.2 C11.3,0,0,11.3,0,25.2v461.5C0,500.7,11.3,512,25.2,512h333.4c13.9,0,25.2-11.3,25.2-25.2v-383 C383.9,103.7,383.9,103.7,383.9,103.6z M292.2,40.9L343,91.8h-49.6c-0.7,0-1.3-0.6-1.3-1.3L292.2,40.9L292.2,40.9z M359.9,486.8 c0,0.7-0.6,1.3-1.3,1.3H25.2c-0.7,0-1.3-0.6-1.3-1.3V25.2c0-0.7,0.6-1.3,1.3-1.3h242.9v66.5c0,13.9,11.3,25.2,25.2,25.2h66.5 L359.9,486.8L359.9,486.8z"
                            ></path>
                            <path
                              fill="#8292A2"
                              d="M298,164H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H298c6.6,0,12-5.4,12-12C310,169.4,304.7,164,298,164z"
                            ></path>
                            <path
                              fill="#8292A2"
                              d="M298,236.4H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H298c6.6,0,12-5.4,12-12 C310,241.7,304.7,236.4,298,236.4z"
                            ></path>
                            <path
                              fill="#8292A2"
                              d="M298,308.7H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H298c6.6,0,12-5.4,12-12 C310,314.1,304.7,308.7,298,308.7z"
                            ></path>
                            <path
                              fill="#8292A2"
                              d="M192,381H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H192c6.6,0,12-5.4,12-12S198.6,381,192,381z"
                            ></path>
                          </svg>
                        </span>
                        <span className="text text-transform-uppercase f-s-14 f-w-3 line-height-1">
                          Prikaži
                        </span>
                      </button>
                    </div>
                  </td>
                )}

                {this.props.settings.type === 2 && this.state.pageNumber === 0 && (
                  <td className="text-center prl-10">
                    <CustomButton
                      icon={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[2].icon
                      }
                      text={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[2].text
                      }
                      onClick={(ev: React.MouseEvent<HTMLElement>) =>
                        this.showModal(
                          ev,
                          !this.props.settings.page[this.state.pageNumber]
                            .buttons[2].mod
                            ? this.props.settings.page[this.state.pageNumber]
                                .buttons[2].type
                            : this.props.settings.page[this.state.pageNumber]
                                .buttons[2].mod,
                          el.id,
                          "Kopija Fakture"
                        )
                      }
                      type={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[2].type
                      }
                      id={el.id}
                    />
                  </td>
                )}
                {this.props.settings.type === 2 && this.state.pageNumber === 0 && (
                  <td className="text-center prl-10">
                    <CustomButton
                      icon={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[3].icon
                      }
                      text={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[3].text
                      }
                      onClick={(ev: React.MouseEvent<HTMLElement>) =>
                        this.showModal(
                          ev,
                          !this.props.settings.page[this.state.pageNumber]
                            .buttons[3].mod
                            ? this.props.settings.page[this.state.pageNumber]
                                .buttons[3].type
                            : this.props.settings.page[this.state.pageNumber]
                                .buttons[3].mod,
                          el.id,
                          "Kopija Fakture"
                        )
                      }
                      type={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[3].type
                      }
                      id={el.id}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      );
    } else if (this.props.settings.type === 3) {
      return (
        <tbody>
          {this.state.dobavljaciFiltrirani.map((el, index: number) => {
            return (
              <tr
                className="transition-1"
                key={"r" + index}
                onClick={() => this.showKlijent(el.id)}
              >
                <td className="text-center prl-10 f-s-12">{el.naziv_firme}</td>
                <td className="text-center prl-10 f-s-12">{el.grad}</td>
                <td className="text-center prl-10 f-s-12">{el.pib}</td>
                <td className="text-center prl-10 f-s-12">
                  {el.kontakt_osoba}
                </td>

                {this.props.settings.page[this.state.pageNumber].buttons.map(
                  (e: any, index: number) => {
                    return (
                      <td className="text-center prl-10" key={index}>
                        <CustomButton
                          icon={e.icon}
                          text={e.text}
                          onClick={(ev: React.MouseEvent<HTMLElement>) => {
                            const modalName =
                              e.type === "izmjena"
                                ? "Izmjena Kupca/Dobavljača"
                                : "Kopija Kupca/Dobavljača";
                            this.showModal(ev, e.type, el.id, modalName);
                          }}
                          type={e.type}
                          id={e.id}
                        />
                      </td>
                    );
                  }
                )}
              </tr>
            );
          })}
        </tbody>
      );
    } else if (this.props.settings.type === 4) {
      return (
        <tbody>
          {this.state.dokumentacijaFiltrirana.map((el, index: number) => {
            return (
              <tr className="transition-1" key={"r" + index}>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Dokumenta")
                  }
                >
                  {el.datum !== undefined
                    ? el.datum.split("-").reverse().join(".") + "."
                    : el.datum}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Dokumenta")
                  }
                >
                  {el.djelovodni_broj}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Dokumenta")
                  }
                >
                  {el.tip}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Dokumenta")
                  }
                >
                  {el.kupac}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Dokumenta")
                  }
                >
                  {el.grad}
                </td>
                <td className="text-center prl-10">
                  <div className="buttonContainer document">
                    <button
                      className="document transition-1 d-flex align-items-center justify-content-center"
                      onClick={() => this.showDocument(el.dokument)}
                    >
                      <span className="icon line-height-1 mr-5 d-flex align-items-center justify-content-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 383.9 512"
                          className=""
                          width="12"
                        >
                          <path
                            fill="#8292A2"
                            d="M383.9,103.6c0-3.1-1.2-6.1-3.5-8.4L288.6,3.5c0,0,0,0,0,0c-2.2-2.2-5.2-3.5-8.5-3.5H25.2 C11.3,0,0,11.3,0,25.2v461.5C0,500.7,11.3,512,25.2,512h333.4c13.9,0,25.2-11.3,25.2-25.2v-383 C383.9,103.7,383.9,103.7,383.9,103.6z M292.2,40.9L343,91.8h-49.6c-0.7,0-1.3-0.6-1.3-1.3L292.2,40.9L292.2,40.9z M359.9,486.8 c0,0.7-0.6,1.3-1.3,1.3H25.2c-0.7,0-1.3-0.6-1.3-1.3V25.2c0-0.7,0.6-1.3,1.3-1.3h242.9v66.5c0,13.9,11.3,25.2,25.2,25.2h66.5 L359.9,486.8L359.9,486.8z"
                          ></path>
                          <path
                            fill="#8292A2"
                            d="M298,164H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H298c6.6,0,12-5.4,12-12C310,169.4,304.7,164,298,164z"
                          ></path>
                          <path
                            fill="#8292A2"
                            d="M298,236.4H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H298c6.6,0,12-5.4,12-12 C310,241.7,304.7,236.4,298,236.4z"
                          ></path>
                          <path
                            fill="#8292A2"
                            d="M298,308.7H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H298c6.6,0,12-5.4,12-12 C310,314.1,304.7,308.7,298,308.7z"
                          ></path>
                          <path
                            fill="#8292A2"
                            d="M192,381H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H192c6.6,0,12-5.4,12-12S198.6,381,192,381z"
                          ></path>
                        </svg>
                      </span>
                      <span className="text text-transform-uppercase f-s-14 f-w-3 line-height-1">
                        Prikaži
                      </span>
                    </button>
                  </div>
                </td>

                {this.props.settings.page[this.state.pageNumber].buttons.map(
                  (e: any, index: number) => {
                    return (
                      <td className="text-center prl-10" key={index}>
                        <CustomButton
                          icon={e.icon}
                          text={e.text}
                          onClick={(ev: React.MouseEvent<HTMLElement>) =>
                            this.showModal(ev, e.type, el.id)
                          }
                          type={e.type}
                          id={e.id}
                        />
                      </td>
                    );
                  }
                )}
              </tr>
            );
          })}
        </tbody>
      );
    } else if (this.props.settings.type === 5) {
      if (this.state.pageNumber === 0) {
        return (
          <tbody>
            {this.state.istekliUgovori.map((el, index: number) => {
              return (
                <tr
                  className="transition-1"
                  key={"r" + index}
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Ugovora")
                  }
                >
                  <td className="text-center prl-10 f-s-12">{el.klijent}</td>
                  <td className="text-center prl-10 f-s-12">
                    {el.datum_zak !== undefined
                      ? el.datum_zak.split("-").reverse().join(".") + "."
                      : el.datum_zak}
                  </td>
                  <td className="text-center prl-10 f-s-12">
                    {el.djelovodni_broj_firme}
                  </td>
                  <td className="text-center prl-10 f-s-12">
                    {el.datum_vazenja !== undefined
                      ? el.datum_vazenja.split("-").reverse().join(".") + "."
                      : el.datum_vazenja}
                  </td>
                  <td className="text-center prl-10 f-s-12">
                    {formatMoney(el.vrijednost)} €
                  </td>
                  <td className="text-center prl-10 f-s-12">
                    {el.linija_biznisa}
                  </td>

                  {this.props.settings.page[this.state.pageNumber].buttons.map(
                    (e: any, index: number) => {
                      return (
                        <td className="text-center prl-10" key={index}>
                          <CustomButton
                            icon={e.icon}
                            text={e.text}
                            onClick={() => console.log()}
                            type={e.type}
                            id={e.id}
                          />
                        </td>
                      );
                    }
                  )}
                </tr>
              );
            })}
          </tbody>
        );
      } else {
        return (
          <tbody>
            {this.state.sviUgovori.map((el, index: number) => {
              return (
                <tr
                  className="transition-1"
                  key={"r" + index}
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Ugovora")
                  }
                >
                  <td className="text-center prl-10 f-s-12">{el.klijent}</td>
                  <td className="text-center prl-10 f-s-12">
                    {el.datum_zak !== undefined
                      ? el.datum_zak.split("-").reverse().join(".") + "."
                      : el.datum_zak}
                  </td>
                  <td className="text-center prl-10 f-s-12">
                    {el.djelovodni_broj_firme}
                  </td>
                  <td className="text-center prl-10 f-s-12">
                    {el.datum_vazenja !== undefined
                      ? el.datum_vazenja.split("-").reverse().join(".") + "."
                      : el.datum_vazenja}
                  </td>
                  <td className="text-center prl-10 f-s-12">
                    {formatMoney(el.vrijednost)} €
                  </td>
                  <td className="text-center prl-10 f-s-12">
                    {el.linija_biznisa}
                  </td>

                  {this.props.settings.page[this.state.pageNumber].buttons.map(
                    (e: any, index: number) => {
                      return (
                        <td className="text-center prl-10" key={index}>
                          <CustomButton
                            icon={e.icon}
                            text={e.text}
                            onClick={() => console.log()}
                            type={e.type}
                            id={e.id}
                          />
                        </td>
                      );
                    }
                  )}
                </tr>
              );
            })}
          </tbody>
        );
      }
    } else if (this.props.settings.type === 6) {
      const filtrirano = this.state.usluge.filter((el) => {
        const naziv = this.state.filter.naziv ? this.state.filter.naziv : "";
        const valuta = this.state.filter.valuta ? this.state.filter.valuta : "";
        const linija_biznisa_naziv = this.state.filter.linija_biznisa_naziv
          ? this.state.filter.linija_biznisa_naziv
          : "";
        const pdv = this.state.filter.pdv
          ? this.state.filter.pdv.slice(-1) === "%"
            ? this.state.filter.pdv.replace("%", "")
            : this.state.filter.pdv
          : "";
        return (
          el.naziv.toLocaleLowerCase().includes(naziv.toLocaleLowerCase()) &&
          el.linija_biznisa_naziv
            .toLocaleLowerCase()
            .includes(linija_biznisa_naziv.toLocaleLowerCase()) &&
          el.valuta.toString().includes(valuta) &&
          el.pdv.toString().includes(pdv)
        );
      });
      return (
        <tbody>
          {filtrirano && filtrirano.length > 0 ? (
            filtrirano.map((el, index) => {
              return (
                <tr
                  className="transition-1"
                  key={"r" + index}
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Usluge")
                  }
                >
                  <td className="text-center prl-10 f-s-12">{el.naziv}</td>
                  <td className="text-center prl-10 f-s-12">
                    {el.linija_biznisa_naziv}
                  </td>
                  <td className="text-center prl-10 f-s-12">{el.valuta}</td>
                  <td className="text-center prl-10 f-s-12">{el.pdv}%</td>
                  {this.props.settings.page[this.state.pageNumber].buttons.map(
                    (e: any, index: number) => {
                      return (
                        <td className="text-center prl-10" key={index}>
                          <CustomButton
                            icon={e.icon}
                            text={e.text}
                            onClick={() => console.log()}
                            type={e.type}
                            id={e.id}
                          />
                        </td>
                      );
                    }
                  )}
                </tr>
              );
            })
          ) : (
            <tr className="transition-1">
              <td colSpan={12} className="f-s-20 text-center">
                Nema rezultata
              </td>
            </tr>
          )}
        </tbody>
      );
    } else if (this.props.settings.type === 7) {
      return (
        <tbody>
          {this.state[
            this.state.pageNumber === 0 ? "fakture" : "mjesecneFakture"
          ].map((el, index: number) => {
            return (
              <tr className="transition-1" key={"r" + index}>
                <td
                  className="text-center prl-10 f-s-12"
                  colSpan={2}
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {el.datum !== undefined && el.datum !== null
                    ? el.datum.split("-").reverse().join(".") + "."
                    : el.datum}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {this.state.pageNumber === 0 ? el.br_fakture : el.ugovor}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {el.kupac}
                </td>
                <td
                  className="text-center prl-10 f-s-12"
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Fakture")
                  }
                >
                  {formatMoney(el.iznos)}€
                </td>
                {this.state.optionsFaktureName !== "Ulazne fakture" &&
                  this.props.settings.page[this.state.pageNumber].buttons.map(
                    (e: any, index: number) => {
                      return (
                        <td className="text-center prl-10" key={index}>
                          <CustomButton
                            icon={e.icon}
                            text={e.text}
                            onClick={(ev: React.MouseEvent<HTMLElement>) =>
                              this.showModal(
                                ev,
                                !e.mod ? e.type : e.mod,
                                el.id,
                                "Kopija Fakture"
                              )
                            }
                            type={e.type}
                            id={el.id}
                          />
                        </td>
                      );
                    }
                  )}
                {this.state.optionsFaktureName === "Ulazne fakture" && (
                  <td
                    className="text-center prl-10"
                    style={{
                      cursor: `${el.dokument.length > 0 ? "pointer" : "auto"}`,
                    }}
                  >
                    <div className="buttonContainer document">
                      <button
                        className={`document transition-1 d-flex align-items-center justify-content-center ${
                          el.dokument.length > 0 ? "" : "invisible"
                        }`}
                        onClick={() => this.showDocument(el.dokument)}
                      >
                        <span className="icon line-height-1 mr-5 d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 383.9 512"
                            className=""
                            width="12"
                          >
                            <path
                              fill="#8292A2"
                              d="M383.9,103.6c0-3.1-1.2-6.1-3.5-8.4L288.6,3.5c0,0,0,0,0,0c-2.2-2.2-5.2-3.5-8.5-3.5H25.2 C11.3,0,0,11.3,0,25.2v461.5C0,500.7,11.3,512,25.2,512h333.4c13.9,0,25.2-11.3,25.2-25.2v-383 C383.9,103.7,383.9,103.7,383.9,103.6z M292.2,40.9L343,91.8h-49.6c-0.7,0-1.3-0.6-1.3-1.3L292.2,40.9L292.2,40.9z M359.9,486.8 c0,0.7-0.6,1.3-1.3,1.3H25.2c-0.7,0-1.3-0.6-1.3-1.3V25.2c0-0.7,0.6-1.3,1.3-1.3h242.9v66.5c0,13.9,11.3,25.2,25.2,25.2h66.5 L359.9,486.8L359.9,486.8z"
                            ></path>
                            <path
                              fill="#8292A2"
                              d="M298,164H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H298c6.6,0,12-5.4,12-12C310,169.4,304.7,164,298,164z"
                            ></path>
                            <path
                              fill="#8292A2"
                              d="M298,236.4H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H298c6.6,0,12-5.4,12-12 C310,241.7,304.7,236.4,298,236.4z"
                            ></path>
                            <path
                              fill="#8292A2"
                              d="M298,308.7H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H298c6.6,0,12-5.4,12-12 C310,314.1,304.7,308.7,298,308.7z"
                            ></path>
                            <path
                              fill="#8292A2"
                              d="M192,381H85.9c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H192c6.6,0,12-5.4,12-12S198.6,381,192,381z"
                            ></path>
                          </svg>
                        </span>
                        <span className="text text-transform-uppercase f-s-14 f-w-3 line-height-1">
                          Prikaži
                        </span>
                      </button>
                    </div>
                  </td>
                )}
                {this.state.optionsFaktureName === "Ulazne fakture" && (
                  <td className="text-center prl-10">
                    <CustomButton
                      icon={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[1].icon
                      }
                      text={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[1].text
                      }
                      onClick={(ev: React.MouseEvent<HTMLElement>) =>
                        this.showModal(
                          ev,
                          !this.props.settings.page[this.state.pageNumber]
                            .buttons[1].mod
                            ? this.props.settings.page[this.state.pageNumber]
                                .buttons[1].type
                            : this.props.settings.page[this.state.pageNumber]
                                .buttons[1].mod,
                          el.id,
                          "Kopija Fakture"
                        )
                      }
                      type={
                        this.props.settings.page[this.state.pageNumber]
                          .buttons[1].type
                      }
                      id={el.id}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      );
    } else if (this.props.settings.type === 8) {
      const filtrirano =
        this.state.alarmi !== undefined ? this.state.alarmi : [];
      // .filter((el) => {
      //   const naziv = this.state.filter.naziv
      //       ? this.state.filter.naziv
      //       : "";
      //   const datum = this.state.filter.datum
      //       ? this.state.filter.datum
      //       : "";
      //   const obavjestenje = this.state.filterAlarm.obavjestenje
      //       ? this.state.filterAlarm.obavjestenje
      //       : "";
      //   return (
      //       el.naziv
      //           .toLocaleLowerCase()
      //           .includes(naziv.toLocaleLowerCase()) &&
      //       el.obavjestenje.toLocaleLowerCase()
      //           .includes(obavjestenje.toLocaleLowerCase())
      //   );
      // })
      return (
        <tbody>
          {filtrirano && filtrirano.length > 0 ? (
            filtrirano.map((el, index) => {
              return (
                <tr
                  className="transition-1"
                  key={"r" + index}
                  onClick={(ev: React.MouseEvent<HTMLElement>) =>
                    this.showModal(ev, "izmjena", el.id, "Izmjena Alarma")
                  }
                >
                  <td className="text-center prl-10 f-s-12">{el.naziv}</td>
                  <td className="text-center prl-10 f-s-12" colSpan={2}>
                    {el.datum.split("-").reverse().join(".")}.
                  </td>
                  <td className="text-center prl-10 f-s-12">
                    {el.obavjestenje}
                  </td>
                  {this.props.settings.page[this.state.pageNumber].buttons.map(
                    (e: any, index: number) => {
                      return (
                        <td className="text-center prl-10" key={index}>
                          <CustomButton
                            icon={e.icon}
                            text={e.text}
                            onClick={(ev: React.MouseEvent<HTMLElement>) =>
                              this.showModal(
                                ev,
                                "delete",
                                el.id,
                                "Obriši Alarm"
                              )
                            }
                            type={e.type}
                            id={e.id}
                          />
                        </td>
                      );
                    }
                  )}
                </tr>
              );
            })
          ) : (
            <tr className="transition-1">
              <td colSpan={12} className="f-s-20 text-center">
                Nema rezultata
              </td>
            </tr>
          )}
        </tbody>
      );
      // })
    }
  };

  getData = (
    loadMore?: boolean,
    linijaBiznisa?: boolean,
    refresh?: boolean
  ) => {
    if (this.props.settings.type < 3 || this.props.settings.type === 7) {
      if (refresh) {
        const filter = Object.create(this.state.filter);
        filter.currentpage = 1;
        getFakture(
          this.state.fakturaType === undefined
            ? this.props.settings.type
            : this.state.fakturaType,
          this.axiosCancelSource,
          this.props.api,
          filter,
          this.state.pageNumber,
          {
            usluga: this.state.optionsLinijeId,
            limit:
              this.state.filter.currentpage &&
              this.state.filter.currentpage * 50,
          }
        ).then((res) => {
          if (this.state.pageNumber === 0) {
            if (!loadMore) {
              this.setState({
                fakture: res,
                loadMore: res.length >= 50,
              });
            } else {
              this.setState((prevState: TableDisplayState) => ({
                fakture: [...prevState.fakture, ...res],
                loadMore: res.length >= 50,
              }));
            }
          } else {
            if (!loadMore) {
              this.setState({
                mjesecneFakture: res,
                loadMore: res.length === 50 ? true : false,
              });
            } else {
              this.setState((prevState: TableDisplayState) => ({
                mjesecneFakture: [...prevState.mjesecneFakture, ...res],
                loadMore: res.length === 50 ? true : false,
              }));
            }
          }
        });
      } else {
        getFakture(
          this.state.fakturaType === undefined
            ? this.props.settings.type
            : this.state.fakturaType,
          this.axiosCancelSource,
          this.props.api,
          this.state.filter,
          this.state.pageNumber,
          { usluga: this.state.optionsLinijeId }
        ).then((res) => {
          if (this.state.pageNumber === 0) {
            if (!loadMore) {
              this.setState({
                fakture: res,
                loadMore: res.length === 50 ? true : false,
              });
            } else {
              this.setState((prevState: TableDisplayState) => ({
                fakture: [...prevState.fakture, ...res],
                loadMore: res.length === 50 ? true : false,
              }));
            }
          } else {
            if (!loadMore) {
              this.setState({
                mjesecneFakture: res,
                loadMore: res.length === 50 ? true : false,
              });
            } else {
              this.setState((prevState: TableDisplayState) => ({
                mjesecneFakture: [...prevState.mjesecneFakture, ...res],
                loadMore: res.length === 50 ? true : false,
              }));
            }
          }
        });
      }

      if (!linijaBiznisa) {
        getUsluge(this.axiosCancelSource, this.props.api).then((res) => {
          this.setState({
            optionsLinije: res,
          });
        });
      }
    } else if (this.props.settings.type === 3) {
      getDobavljaci(
        this.axiosCancelSource,
        this.props.api,
        this.state.filter
      ).then((res) => {
        this.setState({ dobavljaci: res }, this.filterBasedOnCheckbox);
      });
    } else if (this.props.settings.type === 4) {
      getDokumentacija(
        this.axiosCancelSource,
        this.props.api,
        this.state.filter
      ).then((res) => {
        this.setState({ dokumentacija: res }, this.filterBasedOnCheckbox);
      });
    } else if (this.props.settings.type === 5) {
      const data = {
        ui: this.state.firstCheckBox ? 2 : 1,
        ...this.state.filter,
      };
      getUgovori(this.axiosCancelSource, this.props.api, data).then((res) => {
        this.setState({ sviUgovori: res }, () => {
          const istekliUgovori = this.state.sviUgovori.filter((el: any) => {
            return new Date(el.datum_vazenja).getTime() < new Date().getTime();
          });
          this.setState({ istekliUgovori });
        });
      });
    } else if (this.props.settings.type === 6) {
      getLinijeBiznisa(this.axiosCancelSource, this.props.api).then((res) => {
        this.setState({ linijeBiznisa: res }, () => {
          getUsluge(this.axiosCancelSource, this.props.api).then((res) => {
            this.setState({ usluge: res });
          });
        });
      });
    } else if (this.props.settings.type === 8) {
      getAllAlarmi(
        this.axiosCancelSource,
        this.props.api,
        this.state.filter
      ).then((res) => {
        this.setState({ alarmi: res });
      });
    }
  };

  componentDidMount() {
    this.getData();
    if (
      this.props.match.params.unos &&
      (this.props.settings.type === 0 || this.props.settings.type === 2)
    ) {
      this.setState({ pageNumber: 1 });
    } else if (this.props.match.params.unos && this.props.settings.type === 5) {
      this.setState({ pageNumber: 2 });
    }
  }

  componentDidUpdate(
    prevProps: IFaktureSettings,
    prevState: TableDisplayState
  ) {
    if (
      prevState.filter !== this.state.filter &&
      this.props.settings.type !== 6
    ) {
      if (prevState.filter.currentpage !== this.state.filter.currentpage) {
        this.getData(true);
      } else {
        this.getData();
      }
    }
    if (this.props.settings.type < 3) {
      if (
        prevState.pageNumber !== this.state.pageNumber &&
        prevState.pageNumber === 1
      ) {
        this.getData();
      }
    }
    if (prevProps.match.params.unos !== this.props.match.params.unos) {
      if (this.props.settings.type === 0 || this.props.settings.type === 2) {
        this.setState({ pageNumber: 1 });
      } else if (this.props.settings.type === 5) {
        this.setState({ pageNumber: 2 });
      }
    }
  }

  loadMore = (id?: string) => {
    this.setState((prevState: any) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        currentpage: prevState.filter.currentpage + 1,
      },
    }));
  };

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  renderLoadMore = (load: boolean) => {
    if (load) {
      return (
        <div className="loadMore text-center d-flex align-items-center justify-content-center">
          <CustomButton
            text="Učitaj još"
            type="load"
            onClick={(ev: React.MouseEvent<HTMLElement>) => this.loadMore()}
            id="1"
          />
        </div>
      );
    }
  };

  renderNoResult = () => {
    if (this.props.settings.type < 3) {
      return (
        this.state.fakture.length === 0 && (
          <tbody>
            <tr>
              <td colSpan={11} className="f-s-20 text-center">
                Nema rezultata
              </td>
              {/* Render hidden buttons to prevent table layout shift */}
              {this.props.settings.page[this.state.pageNumber].buttons.map(
                (e: any, index: number) => {
                  return (
                    <td
                      className="text-center prl-10"
                      style={{ visibility: "hidden" }}
                      key={index}
                    >
                      <CustomButton
                        icon={e.icon}
                        text={e.text}
                        onClick={(ev: React.MouseEvent<HTMLElement>) => false}
                        type={e.type}
                        id={"button" + index}
                      />
                    </td>
                  );
                }
              )}
            </tr>
          </tbody>
        )
      );
    } else if (this.props.settings.type === 3) {
      return (
        this.state.dobavljaciFiltrirani.length === 0 && (
          <tbody>
            <tr>
              <td colSpan={10} className="f-s-20 text-center">
                Nema rezultata
              </td>
              {/* Render hidden buttons to prevent table layout shift */}
              {this.props.settings.page[this.state.pageNumber].buttons.map(
                (e: any, index: number) => {
                  return (
                    <td
                      className="text-center prl-10"
                      style={{ visibility: "hidden" }}
                      key={index}
                    >
                      <CustomButton
                        icon={e.icon}
                        text={e.text}
                        onClick={(ev: React.MouseEvent<HTMLElement>) => false}
                        type={e.type}
                        id={"button" + index}
                      />
                    </td>
                  );
                }
              )}
            </tr>
          </tbody>
        )
      );
    } else if (this.props.settings.type === 5) {
      if (this.state.pageNumber === 0) {
        return (
          this.state.istekliUgovori.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={10} className="f-s-20 text-center">
                  Nema rezultata
                </td>
              </tr>
            </tbody>
          )
        );
      } else {
        return (
          this.state.sviUgovori.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={10} className="f-s-20 text-center">
                  Nema rezultata
                </td>
              </tr>
            </tbody>
          )
        );
      }
    } else if (this.props.settings.type === 7) {
      return (
        this.state.fakture.length === 0 && (
          <tbody>
            <tr>
              <td colSpan={10} className="f-s-20 text-center">
                Nema rezultata
              </td>
            </tr>
          </tbody>
        )
      );
    }
  };

  filterBasedOnCheckbox = () => {
    if (this.props.settings.type === 3) {
      if (
        (this.state.firstCheckBox && this.state.secondCheckBox) ||
        (!this.state.firstCheckBox && !this.state.secondCheckBox)
      ) {
        this.setState({ dobavljaciFiltrirani: this.state.dobavljaci });
      } else if (this.state.firstCheckBox && !this.state.secondCheckBox) {
        const dobavljaciFiltrirani = this.state.dobavljaci.filter(
          (a: any) => a.kupac === 1
        );
        this.setState({ dobavljaciFiltrirani });
      } else if (!this.state.firstCheckBox && this.state.secondCheckBox) {
        const dobavljaciFiltrirani = this.state.dobavljaci.filter(
          (a: any) => a.dobavljac === 1
        );
        this.setState({ dobavljaciFiltrirani });
      }
    } else if (this.props.settings.type === 4) {
      if (this.state.firstCheckBox && !this.state.secondCheckBox) {
        const dokumentacijaFiltrirana = this.state.dokumentacija.filter(
          (a: any) => a.ui === 1
        );
        this.setState({ dokumentacijaFiltrirana });
      } else if (!this.state.firstCheckBox && this.state.secondCheckBox) {
        const dokumentacijaFiltrirana = this.state.dokumentacija.filter(
          (a: any) => a.ui === 2
        );
        this.setState({ dokumentacijaFiltrirana });
      }
    } else if (this.props.settings.type === 5) {
      this.getData();
    }
  };

  handleSelectChange = (
    val: any,
    name: StateKeys,
    index?: number,
    edit?: boolean
  ) => {
    if (name === "optionsFakture" && val.length > 0) {
      this.setState(
        {
          optionsFaktureId: val[0].id,
          optionsFaktureName: val[0].naziv,
          fakturaType: val[0].id,
        },
        () => this.getData(false, true)
      );
    } else if (name === "optionsLinije" && val.length > 0) {
      this.setState(
        {
          optionsLinijeId: val[0].id,
          optionsLinijeName: val[0].naziv,
        },
        () => this.getData(false, true)
      );
    }
  };

  toggleCheckbox = (name: StateKeys) => {
    if (this.props.settings.type === 3) {
      this.setState(
        (prevState: TableDisplayState) => ({
          ...prevState,
          [name]: !prevState[name],
        }),
        this.filterBasedOnCheckbox
      );
    } else {
      if (name === "firstCheckBox") {
        this.setState(
          (prevState: TableDisplayState) => ({
            ...prevState,
            firstCheckBox: true,
            secondCheckBox: false,
          }),
          this.filterBasedOnCheckbox
        );
      } else {
        this.setState(
          (prevState: TableDisplayState) => ({
            ...prevState,
            firstCheckBox: false,
            secondCheckBox: true,
          }),
          this.filterBasedOnCheckbox
        );
      }
    }
  };

  render() {
    const { settings } = this.props;
    if (settings.type < 3 && this.state.pageNumber === 1) {
      return (
        <div className="table">
          {settings.nav
            ? renderNav(
                settings.nav,
                this.handleNavChange,
                this.props.match.params.unos,
                this.props.settings.type
              )
            : ""}
          <div className="tableContainer prl-20 ptb-40">
            <UnosFaktura type={settings.type} token={this.props.api} />
          </div>
        </div>
      );
    } else if (settings.type === 3 && this.state.pageNumber === 1) {
      return (
        <div className="table">
          {settings.nav ? renderNav(settings.nav, this.handleNavChange) : ""}
          <div className="tableContainer pt-20 prl-10">
            <UnosKupca token={this.props.api} />
          </div>
        </div>
      );
    } else if (settings.type === 4 && this.state.pageNumber === 1) {
      return (
        <div className="table">
          {settings.nav ? renderNav(settings.nav, this.handleNavChange) : ""}
          <div className="tableContainer pt-20 prl-10">
            <UnosDokumenta token={this.props.api} />
          </div>
        </div>
      );
    } else if (settings.type === 5 && this.state.pageNumber === 2) {
      return (
        <div className="table">
          {settings.nav
            ? renderNav(
                settings.nav,
                this.handleNavChange,
                this.props.match.params.unos,
                settings.type
              )
            : ""}
          <div className="tableContainer pt-20 prl-10">
            <UnosUgovora token={this.props.api} />
          </div>
        </div>
      );
    } else if (settings.type === 6 && this.state.pageNumber === 1) {
      return (
        <div className="table">
          {settings.nav ? renderNav(settings.nav, this.handleNavChange) : ""}
          <div className="tableContainer pt-20 prl-10">
            <UnosUsluge
              token={this.props.api}
              linijeBiznisa={this.state.linijeBiznisa}
            />
          </div>
        </div>
      );
    } else if (settings.type === 6 && this.state.pageNumber === 2) {
      return (
        <div className="table">
          {settings.nav ? renderNav(settings.nav, this.handleNavChange) : ""}
          <div className="tableContainer pt-20 prl-10">
            <UnosLinijaBiznisa token={this.props.api} />
          </div>
        </div>
      );
    } else if (settings.type === 8 && this.state.pageNumber === 1) {
      return (
        <div className="table">
          {settings.nav ? renderNav(settings.nav, this.handleNavChange) : ""}
          <div className="tableContainer pt-20 prl-10">
            <UnosAlarma token={this.props.api} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="table">
          {settings.nav ? renderNav(settings.nav, this.handleNavChange) : ""}

          <div className="tableContainer pt-20 prl-10">
            {renderCheckbox(
              this.state.firstCheckBox,
              this.state.secondCheckBox,
              this.toggleCheckbox,
              this.props.settings.type
            )}
            {this.props.settings.type === 7 && (
              <div className="row mb-40">
                <div className="col-md-3" style={{ paddingLeft: "25px" }}>
                  {renderSelect(
                    "optionsFakture",
                    this.state.optionsFaktureName
                      ? this.state.optionsFaktureName
                      : "",
                    this.state.optionsFakture ? this.state.optionsFakture : [],
                    "naziv",
                    this.handleSelectChange,
                    true
                  )}
                </div>
                <div className="col-md-3">
                  {renderSelect(
                    "optionsLinije",
                    this.state.optionsLinijeName
                      ? this.state.optionsLinijeName
                      : "",
                    this.state.optionsLinije ? this.state.optionsLinije : [],
                    "naziv",
                    this.handleSelectChange,
                    true,
                    "Morate unijeti uslugu"
                  )}
                </div>
              </div>
            )}

            {this.state.pageNumber === 0 && this.props.settings.type < 3
              ? renderLegend(settings.legend)
              : ""}
            <table>
              {this.renderTableHead()}
              {this.renderTableBody()}
              {this.renderNoResult()}
            </table>
            {this.renderLoadMore(this.state.loadMore)}
          </div>
          <ScrollTopButton />
          {this.state.modal ? this.renderModal() : ""}
        </div>
      );
    }
  }
}

export default TableDisplay;
