import { InitialState } from "../InitialState/InitialState";

export const IzlazneFaktureMjesecne = {
  buttons: [{ icon: "", text: "", type: "" }],
  state: {
    datumOd: undefined,
    datumDo: undefined,
    loadMore: false,
    pageNumber: 2,
    modal: false,
    sortBy: {
      name: "datum",
      direction: true
    },
    filter: {
      od: "",
      do: "",
      brfakture: "",
      klijent: "",
      grad: "",
      iznos: "",
      currentpage: 1
    },
    ...InitialState,
    dropDown: {
      name: "statusDropdown",
      open: false,
      options: [
        "Status",
        "Djelimično pl. van valute",
        "Neplaćeno u valuti",
        "Neplaćeno van valute",
        "Plaćeno"
      ],
      reference: "dropDown",
      title: "Status"
    }
  },
  table: {
    thead: {
      firstRow: [
        "Datum od",
        "Datum do",
        "Broj Ugovora",
        "Kupac",
        "Grad",
        "Iznos",
        "Dug"
      ],
      secondRow: [
        { name: "DateInput", property: "datumOd" },
        { name: "DateInput", property: "datumDo" },
        { name: "TextInput", property: "ugovor" },
        { name: "TextInput", property: "klijent" },
        { name: "TextInput", property: "grad" },
        { name: "", property: "iznos" },
        { name: "", property: "dug" }
      ]
    }
  }
};
