import React from "react";
import { KlijentType } from "../Klijent";

const KlijentInfo = (klijent: KlijentType) => {
  return (
    <div className="klijentCard klijentInfo d-flex flex-column justify-content-between prl-20 ptb-10">
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/grad.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>Država:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.drzava}</span>
          </div>
        </div>
      </div>
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/grad.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>Grad:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.grad}</span>
          </div>
        </div>
      </div>
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/adresa.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>Adresa:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.adresa}</span>
          </div>
        </div>
      </div>
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/pib.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>PIB:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.pib}</span>
          </div>
        </div>
      </div>
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/pib.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>PDV broj:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.pdv_broj}</span>
          </div>
        </div>
      </div>
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/telefon.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>Kontakt osoba:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.kontakt_osoba}</span>
          </div>
        </div>
      </div>
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/telefon.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>Telefon:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.telefon}</span>
          </div>
        </div>
      </div>
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/email.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>Email:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.email}</span>
          </div>
        </div>
      </div>
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/web.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>Web:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.web}</span>
          </div>
        </div>
      </div>
      <div className="item d-flex align-items-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="row d-flex align-items-center">
              <div className="col-sm-4 text-center d-flex align-items-center">
                <img src="/images/klijent/pib.png" alt="" />
              </div>
              <div className="col-sm-8 text-center d-flex align-items-center">
                <span className="f-s-16 f-w-6 line-height-1" style={{ whiteSpace: 'nowrap' }}>Žiro račun:</span>
              </div>
            </div>
          </div>
          <div className="col-sm-8 d-flex align-items-center justify-content-center">
            <span className="f-s-16 line-height-1 ">{klijent.ziro_racun}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KlijentInfo;
