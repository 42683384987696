import React from "react";
import { renderIcon } from "../../Icons/Icons";
import { animateScrollTo } from "../../../functions/animations";

const ScrollTopButton = () => {
  const element = document.getElementById("mainContainer");

  const scrollTop = () => {
    animateScrollTo(window.scrollY, 0, 1000);
  };
  return (
    <div className="scrollTopButton">
      <button onClick={scrollTop}>{renderIcon("ScrollTopIcon", "", 20)}</button>
    </div>
  );
};

export default ScrollTopButton;
