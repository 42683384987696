import axios, { CancelTokenSource } from "axios";
import { BASE_URL } from "./baseUrl";

export const postInsertFaktura = (
  data: {},
  cancel: CancelTokenSource,
  api: string,
  id?: string,
  usluge?: string
) => {
  const url = `${BASE_URL}/insert/fakura.php?usluge=${usluge}`;
  return axios({
    url,
    method: "post",
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};

export const postDeleteFaktura = (
  id: string,
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/delete/faktura.php?id=${id}&api=${api}`;
  return axios({
    url,
    method: "get",
    cancelToken: cancel.token
  }).then(res => res.data);
};

export const postDeleteKupac = (
  id: string,
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/delete/klijent.php?id=${id}&api=${api}`;
  return axios({
    url,
    method: "get",
    cancelToken: cancel.token
  }).then(res => res.data);
};

export const postDeleteDokument = (
    id: string,
    cancel: CancelTokenSource,
    api: string
) => {
  const url = `${BASE_URL}/delete/dokument.php?id=${id}&api=${api}`;
  return axios({
    url,
    method: "post",
    cancelToken: cancel.token
  }).then(res => res.data);
};

export const postDeleteAlarm = (
    id: string,
    cancel: CancelTokenSource,
    api: string
) => {
  const url = `${BASE_URL}/delete/alarm.php?id=${id}&api=${api}`;
  return axios({
    url,
    method: "post",
    cancelToken: cancel.token
  }).then(res => res.data);
};

export const postDeleteAlarms = (
    id: string,
    cancel: CancelTokenSource,
    api: string
) => {
  const url = `${BASE_URL}/delete/alarm.php?deleteAll=1&api=${api}`;
  return axios({
    url,
    method: "post",
    cancelToken: cancel.token
  }).then(res => res.data);
};

export const postSeenAlarms = (
    id: string,
    cancel: CancelTokenSource,
    api: string
) => {
  const url = `${BASE_URL}/insert/alarmAllSeen.php?api=${api}`;
  return axios({
    url,
    method: "post",
    cancelToken: cancel.token
  }).then(res => res.data);
};



export const postInsertUplata = (
  data: {},
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/insert/uplata.php`;
  return axios({
    url,
    method: "get",
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};

export const postInstertKlijent = (
  data: {},
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/insert/klijent.php`;
  return axios({
    url,
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};
export const postInsertDokument = (
  data: {},
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/insert/dokumentacija.php`;
  return axios({
    url,
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};

export const postInsertUsluga = (
  data: {},
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/insert/usluga.php`;
  return axios({
    url,
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};

export const postUpdatePodesavanja = (
    data: {},
    cancel: CancelTokenSource,
    api: string
) => {
  const url = `${BASE_URL}/insert/settings.php`;
  return axios({
    url,
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};

export const postInsertAlarm = (
    data: {},
    cancel: CancelTokenSource,
    api: string
) => {
  const url = `${BASE_URL}/insert/alarm.php`;
  return axios({
    url,
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};

export const postInsertTarget = (
  data: {},
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/insert/target.php`;
  return axios({
    url,
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};
export const postInsertUgovor = (
  data: {},
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/insert/ugovor.php`;
  return axios({
    method: "POST",
    url,
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};

export const postIzmjenaLinijeBiznisa = (
  data: {},
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/insert/linija_biznisa.php`;
  return axios({
    method: "GET",
    url,
    cancelToken: cancel.token,
    params: {
      ...data,
      api
    }
  }).then(res => res.data);
};

export const postLoginUser = (data: {}, cancel: CancelTokenSource) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/index.php`,
    cancelToken: cancel.token,
    params: {
      ...data
    }
  }).then(res => res.data);
};
