import React from "react";

type KlijentNavType = {
  handleNavChange: (
    e: React.MouseEvent<HTMLElement>,
    pageNumber: number
  ) => void;
  kupac: number;
  dobavljac: number;
};

export const KlijentNav = ({
  handleNavChange,
  kupac,
  dobavljac
}: KlijentNavType) => {
  return (
    <div className="klijentNav">
      {kupac === 1 ? (
        <button
          onClick={(e: React.MouseEvent<HTMLElement>) => handleNavChange(e, 0)}
          className="f-s-14 prl-30 ptb-10 active mr-10"
        >
          Kupac
        </button>
      ) : (
        ""
      )}
      {dobavljac === 1 ? (
        <button
          onClick={(e: React.MouseEvent<HTMLElement>) => handleNavChange(e, 1)}
          className="f-s-14 prl-30 ptb-10 mr-10"
        >
          Dobavljač
        </button>
      ) : (
        ""
      )}
      <button
        onClick={(e: React.MouseEvent<HTMLElement>) => handleNavChange(e, 2)}
        className="f-s-14 prl-30 ptb-10 "
      >
        Analitička kartica
      </button>
    </div>
  );
};
