import React from "react";
import RingChart from "../../../components/Charts/RingChart/RingChart";
import { formatMoney } from "../../../functions/formaters";

type RingChartType = {
  result: any;
  small?: boolean;
};

const RingChartWrapper = ({ result, small }: RingChartType) => {
  const dateHandler = () => {
    const date = new Date();
    const monthLength = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();
    const currentDate = date.getDate();
    return {
      monthPercentage: parseInt(((currentDate * 100) / monthLength).toFixed(2)),
      date: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    };
  };

  if (result) {
    return (
      <div className="homeRingContainer d-flex  flex-column align-items-center ">
        <h2
          className={`${
            !small ? "f-s-30" : "f-s-20"
          } text-color-white f-w-4 mb-20`}
        >
          Indikativni iznos PDV-a za tekući mjesec
        </h2>
        <div className="ring mb-20">
          <RingChart
            size={250}
            percentage={dateHandler().monthPercentage}
            date={dateHandler().date + "."}
            value={formatMoney(result.tekuci.izlazni - result.tekuci.ulazni)}
          />
        </div>
        <p className="f-s-18 text-color-white f-w-4">
          PDV za prethodni mjesec je iznosio:{" "}
          {formatMoney(result.prethodni.izlazni - result.prethodni.ulazni)}€
        </p>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default RingChartWrapper;
