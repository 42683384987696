import React, { Component } from "react";
import { IPage } from "../../interfaces/pageInterfaces";
import axios from "axios";
import { getPodesavanja } from "../../api/getFunctions";
import { postUpdatePodesavanja } from "../../api/postFunctions";
import CustomButton from "../../components/custom/CustomButton/CustomButton";
import ModalSmall from "../../components/modals/ModalSmall/ModalSmall";
import "./podesavanja.css";

interface IState {
  [key: string]: any;
}

class Podesavanja extends Component<IPage> {
  state: IState = {
    modal: false,
    reports: {
      vrijednost_faktura: 0,
      odnos_loseg_duga: 0,
      potrazivanja_dugovanja: 0,
      profitabilnost: 0,
      vrijednost_izlaznih: 0,
      pdv: 0,
      top5duznika: 0,
      top5povjerilaca: 0,
      top10kupaca: 0,
      top10dobavljaca: 0,
      targeti: 0,
      br_izlaznih_faktura: 0,
    },
  };

  componentDidMount() {
    if (this.props.changePageTitle) {
      this.props.changePageTitle("Podešavanja");
    }
    this.getData();
  }

  axiosCancelSource = axios.CancelToken.source();

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  getData = () => {
    getPodesavanja(this.axiosCancelSource, this.props.api).then((res) => {
      this.setState({ reports: res });
    });
  };

  handleCheckboxChange = (event: any) => {
    event.persist();
    this.setState((prevState: any) => ({
      ...prevState,
      reports: {
        ...prevState.reports,
        [event.target.name]: event.target.checked ? 1 : 0,
      },
    }));
  };

  resetForm = () => {
    this.getData();
  };

  savePodesavanja = () => {
    const data = {
      ...this.state.reports,
    };
    postUpdatePodesavanja(data, this.axiosCancelSource, this.props.api).then(
      () =>
        this.setState({
          modal: true,
        })
    );
  };

  render() {
    return (
      <div className="targetiContainer settingsContainer">
        {this.state.modal && (
          <ModalSmall
            question="Podešavanja uspješno sačuvana."
            buttons={["euro"]}
            options={["U redu"]}
            id={""}
            onClick={() => false}
            removeModal={() => this.setState({ modal: false })}
            title="Podešavanja sačuvana"
          />
        )}

        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="content">
                <div className="title d-flex mt-20">
                  <div className="col-12 d-flex align-items-center justify-content-center settings-title">
                    <h5>Izvještaji</h5>
                  </div>
                </div>
                <table className="settings-table">
                  <tbody>
                    <tr>
                      <td>Vrijednost faktura</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="vrijednost_faktura"
                            checked={this.state.reports.vrijednost_faktura}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Odnos lošeg duga</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="odnos_loseg_duga"
                            checked={this.state.reports.odnos_loseg_duga}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Potraživanja / Dugovanja</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="potrazivanja_dugovanja"
                            checked={this.state.reports.potrazivanja_dugovanja}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Profitabilnost</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="profitabilnost"
                            checked={this.state.reports.profitabilnost}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Vrijednost izlaznih faktura</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="vrijednost_izlaznih"
                            checked={this.state.reports.vrijednost_izlaznih}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>PDV</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="pdv"
                            checked={this.state.reports.pdv}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Top 5 dužnika</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="top5duznika"
                            checked={this.state.reports.top5duznika}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Top 5 povjerilaca</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="top5povjerilaca"
                            checked={this.state.reports.top5povjerilaca}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Top 10 kupaca</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="top10kupaca"
                            checked={this.state.reports.top10kupaca}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Top 10 dobavljača</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="top10dobavljaca"
                            checked={this.state.reports.top10dobavljaca}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Targeti Prihodi</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="targeti"
                            checked={this.state.reports.targeti}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Broj izlaznih faktura</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="br_izlaznih_faktura"
                            checked={this.state.reports.br_izlaznih_faktura}
                            onChange={this.handleCheckboxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="save d-flex pb-20 mt-20 justify-content-center">
                  <CustomButton
                    text="Reset"
                    onClick={this.resetForm}
                    type="reset"
                  />
                  <div style={{ width: "1rem" }}></div>
                  <CustomButton
                    text="Sačuvaj"
                    onClick={this.savePodesavanja}
                    type="save"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Podesavanja;
