import React, { Component, ChangeEvent } from "react";
import Select from "react-dropdown-select";
import TextInput from "../Inputs/TextInput/TextInput";
import axios from "axios";
import TextArea from "../Inputs/TextArea/TextArea";
import CustomButton from "../custom/CustomButton/CustomButton";
import { postInsertUsluga } from "../../api/postFunctions";
import ModalSmall from "../modals/ModalSmall/ModalSmall";
import { validateForm } from "./functions/validateForm";
import ModalErrors from "../modals/ModalErrors/ModalErrors";
import { LinijeBiznisa } from "../../settings/TableTypes/FaktureTypes";

export interface IUnosUsluge {
  id: string;
  naziv: string;
  linija_biznisa: string;
  linija_biznisaList: Array<{ id: string; naziv: string; valuta: string }>;
  linija_biznisaName: string;
  cijena: string;
  pdv: string;
  valuta: string;
  opis: string;
  errors: Array<string>;
  modal: boolean;
  options: Array<{ id: string, naziv: string}>
}

type UnosUslugeType = {
  edit?: boolean;
  data?: any;
  token: string;
  linijeBiznisa?: Array<LinijeBiznisa>;
};

type StateKeys = keyof IUnosUsluge;

class UnosUsluge extends Component<UnosUslugeType> {
  form: any = React.createRef();
  state: IUnosUsluge = {
    id: "0",
    naziv: "",
    linija_biznisa: "",
    linija_biznisaList: [],
    linija_biznisaName: "",
    cijena: "",
    pdv: "",
    valuta: "",
    opis: "",
    errors: [],
    modal: false,
    options: [{ id: "0", naziv: "0%"}, { id: "7", naziv: "7%"},{ id: "21", naziv: "21%"}]
  };

  //*****************************************/
  //*              LIFECYCLE                */
  //*****************************************/

  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this.initStateData();
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  //*****************************************/
  //*              LIFECYCLE                */
  //*****************************************/

  //****************************************?/
  //?              CUSTOM FUNCTIONS         */
  //****************************************?/

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    name: StateKeys,
    subname?: any,
    index?: number
  ) => {
    e.persist();
    if (name !== "naziv" && name !== "opis") {
      const regNumbers = new RegExp("^[0-9.]+$");
      if (regNumbers.test(e.target.value) || e.target.value.length === 0) {
        this.setState((prevState) => ({
          ...prevState,
          [name]: e.target.value,
        }));
      }
    } else {
      if (name === "opis") {
        if (e.target.value.length <= 1000) {
          this.setState((prevState) => ({
            ...prevState,
            [name]: e.target.value,
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            [name]: e.target.value.substr(0, 1000),
            errors: ["Dužina napomene ne može biti veća od 1000 karaktera!"],
          }));
          this.setState({
            naziv: "",
          });
          this.showModal();
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [name]: e.target.value,
        }));
      }
    }
  };

  handleSelectChange = (
    val: any,
    name: StateKeys,
    index?: number,
    edit?: boolean
  ) => {
    if (name === "linija_biznisa" && val.length > 0) {
      this.setState({
        linija_biznisa: val[0].id,
        linija_biznisaName: val[0].naziv,
      });
    } else if (name === "linija_biznisa" && val.length === 0) {
      this.setState({
        linija_biznisa: "",
        linija_biznisaName: "",
      });
    }
  };

  showModal = () => {
    this.setState({ modal: true });
  };
  removeModal = () => {
    this.setState({ modal: false });
  };

  renderModal = () => {
    if (this.state.errors.length > 0) {
      return (
        <ModalErrors
          title="Greška prilikom unosa usluge"
          errors={this.state.errors}
          removeModal={this.removeModal}
          message="Greška prilikom unosa usluge."
        />
      );
    } else {
      return (
        <ModalSmall
          question="Usluga uspješno sačuvana."
          buttons={["euro"]}
          options={["U redu"]}
          id={""}
          onClick={() => false}
          removeModal={this.removeModal}
          title="Usluga sačuvana"
        />
      );
    }
  };

  initStateData = () => {
    if (this.props.linijeBiznisa !== undefined) {
      this.setState({ linija_biznisaList: this.props.linijeBiznisa });
    } else {
      this.setState({
        id: this.props.data.id,
        naziv: this.props.data.naziv,
        linija_biznisa: this.props.data.linija_biznisa,
        linija_biznisaName: this.props.data.linija_biznisa_naziv,
        linijeBiznisa: this.props.data.linijeBiznisa,
        cijena: this.props.data.cijena === 0 ? "0" : this.props.data.cijena,
        pdv: this.props.data.pdv === 0 ? "0%" : this.props.data.pdv + "%",
        valuta: this.props.data.valuta === 0 ? "0" : this.props.data.valuta,
        opis: this.props.data.opis,
        linija_biznisaList: this.props.data.linijeBiznisa,
      });
    }
  };

  resetForm = () => {
    this.setState({
      naziv: "",
      linija_biznisa: "",
      cijena: "",
      pdv: "",
      valuta: "",
      opis: "",
    });
    this.handleSelectChange([], "linija_biznisa", 0);
    if (this.props.edit) {
      this.initStateData();
    }
  };

  insertUsluga = () => {
    const { id, naziv, linija_biznisa, cijena, pdv, valuta, opis } = this.state;
    let errors;
    const data = {
      id,
      naziv,
      linija_biznisa,
      cijena,
      pdv,
      valuta,
      opis,
    };
    errors = validateForm(this.state, "unos");

    if (errors.length === 0) {
      this.setState({ errors });
      postInsertUsluga(data, this.axiosCancelSource, this.props.token).then(
        (res) => {
          if (res) {
            if (!this.props.edit) {
              this.setState({
                id: "0",
                naziv: "",
                cijena: "",
                pdv: "",
                valuta: "",
                opis: "",
              });
              this.handleSelectChange([], "linija_biznisa", 0);
            }
            this.showModal();
          }
        }
      );
    } else {
      this.setState({ errors }, () => {
        this.showModal();
      });
    }
  };

  //****************************************?/
  //?              CUSTOM FUNCTIONS         */
  //****************************************?/
  render() {
    const s = this.state;
    console.log(this.state.pdv)
    return (
      <div>
        <form ref={this.form} onSubmit={(e) => e.preventDefault()}>
          <div className="row">
            <div className="col-sm-12">
              <div className="unosFakturaContainer ">
                <div className="row mt-30">
                  <div className="col-sm-12 col-lg-6">
                    <div className="title f-s-12 f-w-5 mb-5">
                      <label htmlFor="naziv">
                        Naziv usluge <span>*</span>
                      </label>
                    </div>
                    <div className="input">
                      <TextInput
                        placeholder=""
                        value={s.naziv}
                        onChange={this.handleInputChange}
                        name="naziv"
                        id="naziv"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6 ">
                    <div className="title f-s-12 f-w-5 mb-5">
                      <span>
                        Linija biznisa <span>*</span>
                      </span>
                    </div>
                    <div className="input p-r">
                      <label htmlFor="" className="react-select-label f-s-12">
                        {s.linija_biznisaName}
                      </label>
                      <Select
                        options={s.linija_biznisaList}
                        labelField="naziv"
                        valueField="id"
                        placeholder=""
                        clearable={true}
                        dropdownGap={0}
                        clearOnSelect={true}
                        onChange={(val: any) =>
                          this.handleSelectChange(val, "linija_biznisa", 0)
                        }
                        onClearAll={() =>
                          this.handleSelectChange([], "linija_biznisa", 0)
                        }
                        noDataRenderer={() => (
                          <span className="text-center ptb-5">
                            Nema rezultata
                          </span>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-30">
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <div className="title f-s-12 f-w-5 mb-5">
                      <label htmlFor="valuta">
                        Valuta <span>*</span>
                      </label>
                    </div>
                    <div className="input">
                      <TextInput
                        placeholder=""
                        value={s.valuta}
                        onChange={this.handleInputChange}
                        name="valuta"
                        id="valuta"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <div className="title f-s-12 f-w-5 mb-5">
                      <label htmlFor="cijena">
                        Cijena (€) <span>*</span>
                      </label>
                    </div>
                    <div className="input">
                      <TextInput
                        placeholder=""
                        value={s.cijena}
                        onChange={this.handleInputChange}
                        name="cijena"
                        id="cijena"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <div className="title f-s-12 f-w-5 mb-5">
                      <label htmlFor="pdv">
                        PDV (%) <span>*</span>
                      </label>
                    </div>
                    <div className="input p-r">
                      <label htmlFor="" className="react-select-label f-s-12">
                        {s.pdv}
                      </label>
                      <Select
                          options={s.options}
                          labelField="naziv"
                          valueField="id"
                          placeholder=""
                          clearable={true}
                          dropdownGap={0}
                          clearOnSelect={true}
                          onChange={(val: any) => this.setState({pdv: val[0] !== undefined ? val[0].naziv : ""})}
                          onClearAll={() => this.setState({pdv: ""})}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-30">
                  <div className="col-sm-12">
                    <TextArea
                      value={s.opis}
                      onChange={this.handleInputChange}
                      name="opis"
                      placeholder="Opis"
                      height={150}
                    />
                  </div>
                </div>
                <div className="row mt-40">
                  <div className="col-sm-2">
                    <CustomButton
                      text="Reset"
                      onClick={this.resetForm}
                      type="reset"
                    />
                  </div>
                  <div className="col-sm-3 pb-20">
                    <CustomButton
                      text="Sačuvaj"
                      onClick={this.insertUsluga}
                      type="save"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.modal ? this.renderModal() : ""}
        </form>
      </div>
    );
  }
}

export default UnosUsluge;
