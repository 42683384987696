import React, { Component, ChangeEvent } from "react";
import Select from "react-dropdown-select";

import { renderCheckbox } from "../../pages/TableDisplay/functions/renderFunctions";
import TextInput from "../Inputs/TextInput/TextInput";
import { getKlijent, getTipovi } from "../../api/getFunctions";
import axios from "axios";
import CustomButton from "../custom/CustomButton/CustomButton";
import { postInsertDokument } from "../../api/postFunctions";
import DateInput from "../Inputs/DateInput/DateInput";
import CustomCheckbox from "../custom/CustomCheckbox/CustomCheckbox";
import { formatDate } from "../../functions/formaters";
import ModalSmall from "../modals/ModalSmall/ModalSmall";
import { validateForm } from "./functions/validateForm";
import ModalErrors from "../modals/ModalErrors/ModalErrors";
import { BASE_URL } from "../../api/baseUrl";

export interface IUnosDokumenta {
  firstCheckBox: boolean;
  secondCheckBox: boolean;
  thirdCheckBox: boolean;
  tip: string;
  tipName: string;
  listaTipova: Array<{ id: string; naziv: string }>;
  klijent: string;
  klijentName: string;
  listaKlijenata: Array<{
    id: string;
    naziv_firme: string;
  }>;
  datum: Date | undefined;
  djelovodni_broj: string;
  nije_kd: string;
  dokument: string;
  ui: string;
  id: string;
  modal: boolean;
  disabled: boolean;
  errors: Array<string>;
  documentName: string;
}

interface IUnosDokumentaProps {
  token: string;
  edit?: boolean;
  data?: any;
}

type StateKeys = keyof IUnosDokumenta;

class UnosDokumenta extends Component<IUnosDokumentaProps> {
  form: any = React.createRef();
  state: IUnosDokumenta = {
    firstCheckBox: true,
    secondCheckBox: false,
    thirdCheckBox: false,
    tip: "",
    tipName: "",
    listaTipova: [],
    klijent: "",
    klijentName: "",
    listaKlijenata: [],
    datum: new Date(),
    djelovodni_broj: "",
    nije_kd: "",
    dokument: "",
    ui: "",
    id: "",
    modal: false,
    errors: [],
    documentName: "",
    disabled:false
  };

  //*****************************************/
  //*              LIFECYCLE                */
  //*****************************************/

  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this.getInitData();
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  //*****************************************/
  //*              LIFECYCLE                */
  //*****************************************/

  //****************************************?/
  //?              CUSTOM FUNCTIONS         */
  //****************************************?/

  toggleCheckbox = (name: StateKeys) => {
    if (name === "firstCheckBox") {
      this.setState((prevState: IUnosDokumenta) => ({
        ...prevState,
        firstCheckBox: true,
        secondCheckBox: false,
      }));
    } else if (name === "secondCheckBox") {
      this.setState((prevState: IUnosDokumenta) => ({
        ...prevState,
        firstCheckBox: false,
        secondCheckBox: true,
      }));
    } else {
      this.setState((prevState: IUnosDokumenta) => ({
        ...prevState,
        [name]: !prevState[name],
      }));
    }
  };

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    name: StateKeys
  ) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  handleSelectChange = (
    val: any,
    name: StateKeys,
    index?: number,
    edit?: boolean
  ) => {
    if (name === "tip" && val.length > 0) {
      this.setState({
        tip: val[0].id,
        tipName: val[0].naziv,
      });
    } else if (name === "tip" && val.length === 0) {
      this.setState({
        tip: "",
        tipName: "",
      });
    }

    if (name === "klijent" && val.length > 0) {
      this.setState({
        klijent: val[0].id,
        klijentName: val[0].naziv_firme,
      });
    } else if (name === "klijent" && val.length === 0) {
      this.setState({
        klijent: "",
        klijentName: "",
      });
    }
  };

  handleDateChange = (datum: Date | undefined, name: string) => {
    this.setState({ [name]: datum });
  };

  showModal = () => {
    this.setState({ modal: true });
  };
  removeModal = () => {
    this.setState({ modal: false, disabled: false });
  };

  renderModal = () => {
    if (this.state.errors.length > 0) {
      return (
        <ModalErrors
          title="Greška prilikom unosa dokumenta"
          errors={this.state.errors}
          removeModal={this.removeModal}
          message="Greška prilikom unosa dokumenta."
        />
      );
    } else {
      return (
        <ModalSmall
          question="Dokument uspješno sačuvan."
          buttons={["euro"]}
          options={["U redu"]}
          id={""}
          onClick={() => false}
          removeModal={this.removeModal}
          title="Dokument sačuvan"
        />
      );
    }
  };

  getInitData = () => {
    getKlijent(
      `${BASE_URL}/klijenti.php?nolimit=1`,
      this.axiosCancelSource,
      this.props.token
    ).then((listaKlijenata) =>
      this.setState({ listaKlijenata }, () => {
        getTipovi(this.axiosCancelSource, this.props.token).then(
          (listaTipova) => {
            this.setState({ listaTipova }, () => {
              if (this.props.data) {
                const klijent = this.state.listaKlijenata.find(
                  (el) => el.naziv_firme === this.props.data.klijent
                );
                const tip = this.state.listaTipova.find(
                  (el) => el.naziv === this.props.data.tip
                );
                if (klijent && tip) {
                  this.setState((prevState: IUnosDokumenta) => ({
                    ...prevState,
                    ...this.props.data,
                    klijent: klijent.id,
                    klijentName: this.props.data.klijent,
                    tipName: tip.naziv,
                    tip: tip.id,
                    documentName: this.props.data.dokument,
                  }));
                }
              }
            });
          }
        );
      })
    );
  };

  resetForm = () => {
    this.setState({
      thirdCheckBox: false,
      tip: "",
      tipName: "",
      klijent: "",
      klijentName: "",
      datum: new Date(),
      djelovodni_broj: "",
      nije_kd: "",
      dokument: "",
      documentName: "",
      disabled: false,
    });
    if (this.props.edit) {
      this.getInitData();
    }
  };

  insertDokument = () => {
    const {
      firstCheckBox,
      thirdCheckBox,
      tip,
      klijent,
      djelovodni_broj,
      ui,
      nije_kd,
      id,
      documentName,
    } = this.state;

    this.setState({
      disabled: true
    })

    const formatDatuma = formatDate(this.state.datum);

    const datum = formatDatuma !== undefined ? formatDatuma : formatDatuma;

    let errors;

    const postDokument = (doc: any) => {
      const data: { [k: string]: any } = {
        tip,
        klijent,
        datum,
        djelovodni_broj,
        ui: firstCheckBox ? 1 : 2,
        nije_kd: thirdCheckBox ? 1 : 0,
        dokument: doc,
      };
      if (this.props.edit) {
        data.id = id;
      }
      errors = validateForm(this.state, "unos");

      if (errors.length === 0) {
        postInsertDokument(data, this.axiosCancelSource, this.props.token).then(
          (res) => {
            if (res.id) {
              this.showModal();
              this.setState({
                disabled: false,
                errors: []
              })
            }
          }
        );
      } else {
        this.setState({ errors }, () => {
          this.showModal();
        });
      }
    };

    const formIDAdd = document.getElementById("addDokument" + "");
    // @ts-ignore
    const formDataAdd = new FormData(formIDAdd);

    for (const file of formDataAdd.getAll("fileDokument")) {
      const newFormDataAdd = new FormData();
      newFormDataAdd.append("file", file);
      if (file.name.length !== 0) {
        axios({
          method: "POST",
          url: `${BASE_URL}/ajax/fileUpload.php?api=${this.props.token}`,
          data: newFormDataAdd,
        }).then((r) => {
          postDokument(r.data.file_path);
          this.setState({
            dokument: r.data.file_path,
          });
        });
      } else {
        postDokument(this.state.dokument);
      }
    }
  };

  //****************************************?/
  //?              CUSTOM FUNCTIONS         */
  //****************************************?/

  render() {
    const s = this.state;
    return (
      <div>
        <form
          ref={this.form}
          id="addDokument"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="unosFakturaContainer">
            <div className="row">
              <div className="col-sm-12">
                {renderCheckbox(
                  s.firstCheckBox,
                  s.secondCheckBox,
                  this.toggleCheckbox,
                  3,
                  true,
                  true
                )}
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <span>
                    Tip dokumenta <span>*</span>
                  </span>
                </div>
                <div className="input p-r">
                  <label htmlFor="" className="react-select-label f-s-12">
                    {s.tipName}
                  </label>
                  <Select
                    options={s.listaTipova}
                    labelField="naziv"
                    valueField="id"
                    placeholder=""
                    clearable={true}
                    dropdownGap={0}
                    clearOnSelect={true}
                    onChange={(val: any) =>
                      this.handleSelectChange(val, "tip", 0)
                    }
                    onClearAll={() => this.handleSelectChange([], "tip", 0)}
                    noDataRenderer={() => (
                      <span className="text-center ptb-5">Nema rezultata</span>
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <span>
                    Kupac/Dobavljač <span>*</span>
                  </span>
                </div>
                <div className="input p-r">
                  <label htmlFor="" className="react-select-label f-s-12">
                    {s.klijentName}
                  </label>
                  <Select
                    options={s.listaKlijenata}
                    labelField="naziv_firme"
                    valueField="id"
                    placeholder=""
                    clearable={true}
                    dropdownGap={0}
                    clearOnSelect={true}
                    onChange={(val: any) =>
                      this.handleSelectChange(val, "klijent", 0)
                    }
                    onClearAll={() => this.handleSelectChange([], "klijent", 0)}
                    noDataRenderer={() => (
                      <span className="text-center ptb-5">Nema rezultata</span>
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <span>Datum unosa</span>
                </div>
                <div className="input">
                  <DateInput
                    date={s.datum}
                    onChange={this.handleDateChange}
                    name="datum"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="djelovodni_broj">
                    Djelovodni broj <span>*</span>
                  </label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.djelovodni_broj}
                    onChange={this.handleInputChange}
                    name="djelovodni_broj"
                    id="djelovodni_broj"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-sm-12 col-lg-3">
                <CustomCheckbox
                  className=""
                  text="Lice nije kupac/dobavljač"
                  checked={s.thirdCheckBox}
                  onClick={this.toggleCheckbox}
                  name="thirdCheckBox"
                />
              </div>
              <div
                className="col-sm-12 col-lg-9"
                style={{ display: "contents" }}
              >
                <label className="custom-file-upload">
                  <input
                    type="file"
                    name="fileDokument"
                    id="fileDokument"
                    onChange={(e) =>
                      this.setState({
                        documentName: e.target.value.replace(
                          "C:\\fakepath\\",
                          ""
                        ),
                      })
                    }
                  />
                  <img
                    src="../images/file-upload-solid.svg"
                    height="20px"
                    alt="upload"
                  />{" "}
                  <div>Dodaj dokument</div>
                </label>
                <div>
                  {s.documentName.length !== 0
                    ? s.documentName
                    : s.dokument !== undefined &&
                      s.dokument.replace("uploads/", "")}
                </div>
              </div>
            </div>
            <div className="row mt-40">
              <div className="col-sm-2">
                <CustomButton
                  text="Reset"
                  onClick={this.resetForm}
                  type="reset"
                />
              </div>
              <div className="col-sm-3 pb-20">
                <CustomButton
                  text="Sačuvaj"
                  onClick={this.insertDokument}
                  type="save"
                  disabled={this.state.disabled}
                  loading={this.state.disabled}
                />
              </div>
            </div>
          </div>
          {this.state.modal ? this.renderModal() : ""}
        </form>
      </div>
    );
  }
}

export default UnosDokumenta;
