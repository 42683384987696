import React, { Component, ChangeEvent } from "react";
import Select from "react-dropdown-select";

import { renderCheckbox } from "../../pages/TableDisplay/functions/renderFunctions";
import TextInput from "../Inputs/TextInput/TextInput";
import {
  getDrzave,
  getGradovi,
  getKlijent,
  getUsluge,
  getFakture,
  getUslugeFaktura,
} from "../../api/getFunctions";
import axios from "axios";
import TextArea from "../Inputs/TextArea/TextArea";
import CustomButton from "../custom/CustomButton/CustomButton";
import {
  postInstertKlijent,
  postInsertUgovor,
  postInsertFaktura,
} from "../../api/postFunctions";
import ModalErrors from "../modals/ModalErrors/ModalErrors";
import ModalSmall from "../modals/ModalSmall/ModalSmall";
import { validateForm } from "./functions/validateForm";
import { emailIsValid, noScrollBody } from "../../functions/functions";
import DateInput from "../Inputs/DateInput/DateInput";
import {formatDate, formatDate1, formatMoney} from "../../functions/formaters";
import { IzlazneFaktureResponse } from "../../settings/TableTypes/FaktureTypes";
import UnosFaktura from "../UnosFaktura/UnosFaktura";
import ModalBig from "../modals/ModalBig/ModalBig";
import { BASE_URL } from "../../api/baseUrl";

export interface IUnosUgovora {
  firstCheckBox: boolean;
  secondCheckBox: boolean;
  id: number;
  djelovodni_broj_firme: string;
  djelovodni_broj_klijenta: string;
  klijent: string;
  datum_zak: string;
  datumZakljucenja: Date | undefined;
  datumVazenja: Date | undefined;
  datum_vazenja: string;
  usluga: string;
  opis: string;
  alarm_datum: string;
  alarmDatum: Date | undefined;
  ui: string;
  first_date: string;
  firstDate: Date | undefined;
  first_vrijednost: string;
  vrijednost: string;
  offset: string;
  trajanje: string;
  drzava: string;
  listaDrzava: Array<{ id: string; name: string }>;
  drzavaName: string;
  grad: string;
  listaGradova: Array<{ id: string; name: string; drzava: string }>;
  gradName: "";
  valuta: string;
  errors: Array<string>;
  modal: boolean;
  listaKlijenata: Array<{
    id: string;
    naziv_firme: string;
  }>;
  klijentName: string;
  listaUsluga: Array<{
    id: string;
    naziv: string;
  }>;
  uslugaName: string;
  fakture: Array<IzlazneFaktureResponse>;
  fakturaModal: boolean;
  modalType: string;
  modalID: string;
  modalName: string;
  noviKlijent: boolean;
  usluge: Array<any>;
}

interface IUnosUgovoraProps {
  token: string;
  type?: number;
  edit?: boolean;
  data?: object;
  klijent?: string;
  copy?: boolean;
}

type StateKeys = keyof IUnosUgovora;

class UnosUgovora extends Component<IUnosUgovoraProps> {
  form: any = React.createRef();
  state: IUnosUgovora = {
    firstCheckBox: true,
    secondCheckBox: false,
    id: 0,
    djelovodni_broj_firme: "",
    djelovodni_broj_klijenta: "",
    klijent: "",
    datum_zak: "",
    datumZakljucenja: undefined,
    datumVazenja: undefined,
    datum_vazenja: "",
    usluga: "",
    opis: "",
    alarm_datum: "",
    alarmDatum: undefined,
    ui: "",
    first_date: "",
    firstDate: undefined,
    first_vrijednost: "",
    vrijednost: "",
    offset: "",
    trajanje: "",
    drzava: "",
    listaDrzava: [],
    drzavaName: "",
    grad: "",
    listaGradova: [],
    gradName: "",
    valuta: "",
    errors: [],
    modal: false,
    listaKlijenata: [],
    klijentName: "",
    listaUsluga: [],
    uslugaName: "",
    fakture: [],
    fakturaModal: false,
    modalType: "",
    modalID: "",
    modalName: "",
    noviKlijent: false,
    usluge: [],
  };

  //*****************************************/
  //*              LIFECYCLE                */
  //*****************************************/

  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    if (!this.props.edit) {
      this.getData();
    } else {
      this.setState({ ...this.props.data }, () => {
        this.getEditData();
      });
    }
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  //*****************************************/
  //*              LIFECYCLE                */
  //*****************************************/

  //****************************************?/
  //?              CUSTOM FUNCTIONS         */
  //****************************************?/

  getData = (getGrad?: boolean) => {
    getKlijent(
      `${BASE_URL}/klijenti.php?nolimit=1`,
      this.axiosCancelSource,
      this.props.token
    ).then((listaKlijenata) => {
      this.setState({ listaKlijenata });
    });
    getUsluge(this.axiosCancelSource, this.props.token).then((listaUsluga) =>
      this.setState({ listaUsluga })
    );
  };

  getEditData = () => {
    getKlijent(
      `${BASE_URL}/klijenti.php?nolimit=1`,
      this.axiosCancelSource,
      this.props.token
    ).then((listaKlijenata) => {
      this.setState({ listaKlijenata }, () => {
        const klijent = this.state.listaKlijenata.filter((e) => {
          return e.naziv_firme === this.state.klijentName;
        });
        this.setState({ klijent: klijent[0].id });
        getFakture(
          this.state.firstCheckBox ? 0 : 2,
          this.axiosCancelSource,
          this.props.token,
          {
            ugovor: this.state.id.toString(),
          }
        ).then((res) => {
          const result = res.map((e: any) => {
            e.usluge = [];
            return e;
          });
          getUsluge(this.axiosCancelSource, this.props.token).then(
            (listaUsluga) =>
              this.setState({ fakture: result, listaUsluga }, () => {
                this.state.fakture.map((e, index) => {
                  getUslugeFaktura(
                    e.id,
                    this.axiosCancelSource,
                    this.props.token
                  ).then((res) => {
                    const usluge = res.map((e: any, index: number) => {
                      return this.state.listaUsluga.find(
                        (el) => e.usluga === el.id
                      );
                    });
                    const data = res.map((e: any, index: number) => {
                      return {
                        id: e.id,
                        usluga: e.usluga,
                        kolicina: e.kolicina,
                        neto: e.neto_cijena,
                        popust: e.popust,
                        pdv: usluge[index].pdv,
                        naziv: usluge[index].naziv,
                      };
                    });
                    this.setState((prevState: IUnosUgovora) => ({
                      fakture: [
                        ...prevState.fakture.slice(0, index),
                        {
                          ...prevState.fakture[index],
                          usluge: data,
                        },
                        ...prevState.fakture.slice(index + 1),
                      ],
                    }));
                  });
                });
              })
          );
        });
      });
    });
    getUsluge(this.axiosCancelSource, this.props.token).then((listaUsluga) =>
      this.setState({ listaUsluga })
    );
  };

  toggleCheckbox = (name: StateKeys) => {
    if (name === "firstCheckBox") {
      this.setState((prevState: IUnosUgovora) => ({
        ...prevState,
        firstCheckBox: true,
        secondCheckBox: false,
      }));
    } else if (name === "secondCheckBox") {
      this.setState((prevState: IUnosUgovora) => ({
        ...prevState,
        firstCheckBox: false,
        secondCheckBox: true,
      }));
    } else {
      this.setState((prevState: IUnosUgovora) => ({
        ...prevState,
        [name]: !prevState[name],
      }));
    }
  };

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    name: StateKeys,
    subname?: any,
    index?: number
  ) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  handleSelectChange = (
    val: any,
    name: StateKeys,
    index?: number,
    edit?: boolean
  ) => {
    if (name === "klijent" && val.length > 0) {
      this.setState(
        {
          klijent: val[0].id,
          klijentName: val[0].naziv_firme,
          noviKlijent: true,
        },
        () => {
          getKlijent(
            `${BASE_URL}/klijenti.php?id=${this.state.klijent}`,
            this.axiosCancelSource,
            this.props.token
          ).then((res) =>
            this.setState({ gradName: res[0].grad, grad: res[0].grad_id })
          );
        }
      );
    } else if (name === "klijent" && val.length === 0) {
      this.setState({
        klijent: "",
        klijentName: "",
        gradName: "",
      });
    }
    if (name === "usluga" && val.length > 0) {
      this.setState({
        usluga: val[0].id,
        uslugaName: val[0].naziv,
      });
    } else if (name === "usluga" && val.length === 0) {
      this.setState({
        usluga: "",
        uslugaName: "",
      });
    }
  };

  showModal = () => {
    this.setState({ modal: true });
  };
  showFakturaModal = (
    e: React.MouseEvent<HTMLElement>,
    modalType?: string,
    modalID?: string,
    modalName?: string
  ) => {
    e.stopPropagation();
    this.setState({ fakturaModal: true, modalType, modalID, modalName });
    noScrollBody(true);
  };
  removeModal = () => {
    this.setState({ modal: false });
    noScrollBody(true);
  };
  removeFakturaModal = () => {
    this.setState({ fakturaModal: false }, () => {
      getFakture(
        this.state.firstCheckBox ? 0 : 2,
        this.axiosCancelSource,
        this.props.token,
        {
          ugovor: this.state.id.toString(),
        }
      ).then((res) => this.setState({ fakture: res }));
    });
    noScrollBody(true);
  };

  renderModal = () => {
    if (this.state.modalType !== "") {
      const faktura = this.state.fakture.find(
        (e) => e.id === this.state.modalID
      );
      let data;
      let kupac;
      if (faktura) {
        const days = 24 * 60 * 60 * 1000;
        const valuta = Math.ceil(
          (new Date(faktura.valuta).getTime() - new Date().getTime()) / days
        );
        data = {
          brfakture: faktura.br_fakture,
          datum: new Date(faktura.datum),
          datumizdavanja: formatDate(new Date(faktura.datum)),
          valuta,
          napomena: faktura.napomena,
          id: faktura.id,
          ugovor: this.state.id,
        };
        kupac = faktura.kupac_id;
      } else {
        data = {};
        kupac = "";
      }
      return (
        <ModalBig
          token={this.props.token}
          onClick={() => false}
          title={this.state.modalName}
          removeModal={this.removeFakturaModal}
          data={data}
          klijent={kupac}
          type={this.state.firstCheckBox ? 0 : 2}
        />
      );
    } else {
      if (this.state.errors.length > 0 && this.state.modalType === "") {
        return (
          <ModalErrors
            title="Greška prilikom unosa ugovora"
            errors={this.state.errors}
            removeModal={this.removeModal}
            message="Greška prilikom unosa ugovora"
          />
        );
      } else {
        return (
          <ModalSmall
            question="Ugovor uspješno sačuvan."
            buttons={["euro"]}
            options={["U redu"]}
            id={""}
            onClick={() => false}
            removeModal={this.removeModal}
            title="Ugovor sačuvan"
          />
        );
      }
    }
  };

  resetForm = () => {
    this.setState({
      djelovodni_broj_firme: "",
      djelovodni_broj_klijenta: "",
      klijent: "",
      klijentName: "",
      datum_zak: "",
      datumZakljucenja: undefined,
      datumVazenja: undefined,
      datum_vazenja: "",
      usluga: "",
      uslugaName: "",
      opis: "",
      alarm_datum: "",
      alarmDatum: undefined,
      first_date: "",
      firstDate: undefined,
      first_vrijednost: "",
      vrijednost: "",
      offset: "",
      trajanje: "",
      drzava: "",
      grad: "",
      gradName: "",
      valuta: "",
    });
    if (this.props.edit) {
      this.getEditData();
    }
  };

  insertUgovor = () => {
    const {
      id,
      djelovodni_broj_firme,
      djelovodni_broj_klijenta,
      klijent,
      usluga,
      first_vrijednost,
      vrijednost,
      offset,
      trajanje,
      grad,
      valuta,
      opis,
      firstCheckBox,
      secondCheckBox,
    } = this.state;

    const datum_zak = this.state.datum_zak;
    const datum_vazenja = this.state.datum_vazenja;
    const first_date = this.state.first_date;
    const alarm_datum = this.state.alarm_datum;

    let errors;
    const data = {
      id,
      djelovodni_broj_firme,
      djelovodni_broj_klijenta,
      klijent,
      usluga,
      datum_zak,
      datum_vazenja,
      first_date,
      first_vrijednost,
      vrijednost,
      alarm_datum,
      offset,
      trajanje,
      grad,
      valuta,
      opis,
      ui: firstCheckBox ? 2 : secondCheckBox ? 1 : 0,
    };

    //? offset:30
    //? trajanje:12
    if (!this.props.edit) {
      errors = validateForm(this.state, "unos");

      if (errors.length === 0) {
        postInsertUgovor(
          data,
          this.axiosCancelSource,
          this.props.token
        ).then((res) =>
          res.id ? this.showModal() : console.log("Something went wrong")
        );
      } else {
        this.setState({ errors }, () => {
          this.showModal();
        });
      }
    } else {
      const fakture = this.state.fakture.map((e) => e);
      const firstDate = fakture.pop();
      const valuta = this.state.fakture[0].valuta;
      let first_date;
      let first_vrijednost;
      let grad;
      if (firstDate) {
        first_date = firstDate.datum;
        first_vrijednost = firstDate.iznos;
        grad = firstDate.grad;
      }
      this.setState(
        {
          first_date,
          first_vrijednost,
          grad,
          valuta,
          usluga: 1,
          offset: 30,
          trajanje: 12,
          alarm_datum: first_date,
          ui: this.state.firstCheckBox ? 2 : 1,
        },
        () => {
          const {
            id,
            djelovodni_broj_firme,
            djelovodni_broj_klijenta,
            klijent,
            usluga,
            datum_zak,
            datum_vazenja,
            first_date,
            first_vrijednost,
            vrijednost,
            alarm_datum,
            offset,
            trajanje,
            grad,
            valuta,
            opis,
            firstCheckBox,
            noviKlijent,
          } = this.state;
          let errors;
          const data = {
            id,
            djelovodni_broj_firme,
            djelovodni_broj_klijenta,
            klijent,
            usluga,
            datum_zak,
            datum_vazenja,
            first_date,
            first_vrijednost,
            vrijednost,
            alarm_datum,
            offset,
            trajanje,
            grad,
            valuta,
            opis,
            ui: firstCheckBox ? 1 : 2,
          };

          errors = validateForm(this.state, "unos");

          if (errors.length === 0) {
            if (noviKlijent) {
              postInsertUgovor(
                data,
                this.axiosCancelSource,
                this.props.token
              ).then((res) => {
                if (res) {
                  this.showModal();
                  this.state.fakture.map((e, index) => {
                    this.insertFaktura(e, index);
                  });
                }
              });
            } else {
              postInsertUgovor(
                data,
                this.axiosCancelSource,
                this.props.token
              ).then((res) =>
                res ? this.showModal() : console.log("Something went wrong")
              );
            }
          } else {
            this.setState({ errors }, () => {
              this.showModal();
            });
          }
        }
      );
    }
  };

  insertFaktura = (faktura: any, index: number) => {
    const {
      br_fakture,
      datum,
      klijent,
      grad,
      valuta,
      napomena,
      ui,
      pf,
      ugovor,
      id,
    } = faktura;

    const { type } = this.props;

    let data;
    let stringUsluge;
    let errors;

    stringUsluge = JSON.stringify(faktura.usluge);
    const days = 24 * 60 * 60 * 1000;
    const valutaDays = Math.ceil(
      (new Date(faktura.valuta).getTime() - new Date().getTime()) / days
    );
    data = {
      id,
      brfakture: br_fakture,
      datumizdavanja: datum,
      klijent: this.state.klijent,
      grad: 1,
      valuta: valutaDays,
      napomena,
      ui: this.state.firstCheckBox ? 2 : 1,
      pf: 0,
      ugovor,
      dokument: "google.com",
    };

    errors = validateForm(this.state, "unos");

    if (errors.length === 0) {
      if (this.state.fakture.length - index === 1) {
        postInsertFaktura(data, this.axiosCancelSource, "", stringUsluge).then(
          (res) => {
            if (res.id) {
              this.showModal();
              this.getEditData();
            } else {
              console.log("Something went wrong");
            }
          }
        );
      } else {
        postInsertFaktura(data, this.axiosCancelSource, "", stringUsluge).then(
          (res) => {
            if (res.id) {
              this.showModal();
            } else {
              console.log("Something went wrong");
            }
          }
        );
      }
    } else {
      this.setState({ errors }, () => {
        this.showModal();
      });
    }
  };

  handleDateChange = (datum: Date | undefined, name: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [name]: datum,
      }),
      () => {
        if (name === "datumZakljucenja") {
          const datum_zak: any = formatDate(this.state.datumZakljucenja);
          this.setState({ datum_zak });
        } else if (name === "datumVazenja") {
          const datum_vazenja: any = formatDate(this.state.datumVazenja);
          this.setState({ datum_vazenja });
        } else if (name === "firstDate") {
          const first_date: any = formatDate(this.state.firstDate);
          this.setState({ first_date });
        } else if (name === "alarmDatum") {
          const alarm_datum: any = formatDate(this.state.alarmDatum);
          this.setState({ alarm_datum });
        }
      }
    );
  };

  //****************************************?/
  //?              CUSTOM FUNCTIONS         */
  //****************************************?/

  render() {
    const s = this.state;
    if (!this.props.edit) {
      return (
        <div>
          <form ref={this.form} onSubmit={(e) => e.preventDefault()}>
            <div className="unosFakturaContainer">
              <div className="row">
                <div className="col-sm-12">
                  {renderCheckbox(
                    this.state.firstCheckBox,
                    this.state.secondCheckBox,
                    this.toggleCheckbox,
                    5,
                    true
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-6 col-xl-3">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="djelovodni_broj_firme">
                      Djelovodni broj firme <span>*</span>
                    </label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={s.djelovodni_broj_firme}
                      onChange={this.handleInputChange}
                      name="djelovodni_broj_firme"
                      id="djelovodni_broj_firme"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-xl-3">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="djelovodni_broj_klijenta">
                      Djelovodni broj klijent <span>*</span>
                    </label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={s.djelovodni_broj_klijenta}
                      onChange={this.handleInputChange}
                      name="djelovodni_broj_klijenta"
                      id="djelovodni_broj_klijenta"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-xl-3">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <span>
                      Klijent <span>*</span>
                    </span>
                  </div>
                  <div className="input p-r">
                    <label htmlFor="" className="react-select-label f-s-12">
                      {s.klijentName}
                    </label>
                    <Select
                      options={s.listaKlijenata}
                      labelField="naziv_firme"
                      valueField="id"
                      placeholder=""
                      clearable={true}
                      dropdownGap={0}
                      clearOnSelect={true}
                      onChange={(val: any) =>
                        this.handleSelectChange(val, "klijent", 0)
                      }
                      onClearAll={() =>
                        this.handleSelectChange([], "klijent", 0)
                      }
                      noDataRenderer={() => (
                        <span className="text-center ptb-5">
                          Nema rezultata
                        </span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-xl-3">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <span>
                      Usluga <span>*</span>
                    </span>
                  </div>
                  <div className="input p-r">
                    <label htmlFor="" className="react-select-label f-s-12">
                      {s.uslugaName}
                    </label>
                    <Select
                      options={s.listaUsluga}
                      labelField="naziv"
                      valueField="id"
                      placeholder=""
                      clearable={true}
                      dropdownGap={0}
                      clearOnSelect={true}
                      onChange={(val: any) =>
                        this.handleSelectChange(val, "usluga", 0)
                      }
                      onClearAll={() =>
                        this.handleSelectChange([], "usluga", 0)
                      }
                      noDataRenderer={() => (
                        <span className="text-center ptb-5">
                          Nema rezultata
                        </span>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-30">
                <div className="col-sm-6 col-lg-4 col-xl-2">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <span>
                      Datum zaključenja <span>*</span>
                    </span>
                  </div>
                  <div className="input">
                    <DateInput
                      date={s.datumZakljucenja}
                      name="datumZakljucenja"
                      onChange={this.handleDateChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-2">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <span>
                      Datum važenja <span>*</span>
                    </span>
                  </div>
                  <div className="input">
                    <DateInput
                      date={s.datumVazenja}
                      name="datumVazenja"
                      onChange={this.handleDateChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-2">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <span>
                      Datum prve fakture <span>*</span>
                    </span>
                  </div>
                  <div className="input">
                    <DateInput
                      date={s.firstDate}
                      name="firstDate"
                      onChange={this.handleDateChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-2">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="first_vrijednost">
                      Vrijednost prve fakture <span>*</span>
                    </label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={s.first_vrijednost}
                      onChange={this.handleInputChange}
                      name="first_vrijednost"
                      id="first_vrijednost"
                      number={true}
                      min={1}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-2">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="vrijednost">
                      Ukupna vrijednost <span>*</span>
                    </label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={s.vrijednost}
                      onChange={this.handleInputChange}
                      name="vrijednost"
                      id="vrijednost"
                      number={true}
                      min={1}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-2">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <span>
                      Datum alarma <span>*</span>
                    </span>
                  </div>
                  <div className="input">
                    <DateInput
                      date={s.alarmDatum}
                      name="alarmDatum"
                      onChange={this.handleDateChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-30">
                <div className="col-sm-12 col-lg-6 col-xl-3">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="offset">
                      Offset <span>*</span>
                    </label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={s.offset}
                      onChange={this.handleInputChange}
                      name="offset"
                      id="offset"
                      number={true}
                      min={1}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-xl-3">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="trajanje">
                      Trajanje <span>*</span>
                    </label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={s.trajanje}
                      onChange={this.handleInputChange}
                      name="trajanje"
                      id="trajanje"
                      number={true}
                      min={1}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-lg-6 col-xl-3">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="valuta">
                      Valuta <span>*</span>
                    </label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={s.valuta}
                      onChange={this.handleInputChange}
                      name="valuta"
                      id="valuta"
                      number={true}
                      min={1}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-xl-3">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="grad">
                      Grad <span>*</span>
                    </label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={s.gradName}
                      onChange={this.handleInputChange}
                      name="grad"
                      id="grad"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-30">
                <div className="col-sm-12">
                  <TextArea
                    value={s.opis}
                    onChange={this.handleInputChange}
                    name="opis"
                    placeholder="Opis"
                    height={150}
                  />
                </div>
              </div>
              <div className="row mt-30">
                <div className="col-sm-2">
                  <CustomButton
                    text="Reset"
                    onClick={this.resetForm}
                    type="reset"
                  />
                </div>
                <div className="col-sm-3 pb-20">
                  <CustomButton
                    text="Sačuvaj"
                    onClick={this.insertUgovor}
                    type="save"
                  />
                </div>
              </div>
            </div>
            {this.state.modal ? this.renderModal() : ""}
          </form>
        </div>
      );
    } else {
      return (
        <form ref={this.form} onSubmit={(e) => e.preventDefault()}>
          <div className="unosFakturaContainer">
            <div className="row">
              <div className="col-sm-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <span>Djelovodni broj firme</span>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.djelovodni_broj_firme}
                    onChange={this.handleInputChange}
                    name="djelovodni_broj_firme"
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <span>Djelovodni broj klijent</span>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.djelovodni_broj_klijenta}
                    onChange={this.handleInputChange}
                    name="djelovodni_broj_klijenta"
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <span>Klijent</span>
                </div>
                <div className="input p-r">
                  <label htmlFor="" className="react-select-label f-s-12">
                    {s.klijentName}
                  </label>
                  <Select
                    options={s.listaKlijenata}
                    labelField="naziv_firme"
                    valueField="id"
                    placeholder=""
                    clearable={true}
                    dropdownGap={0}
                    clearOnSelect={true}
                    onChange={(val: any) =>
                      this.handleSelectChange(val, "klijent", 0)
                    }
                    onClearAll={() => this.handleSelectChange([], "klijent", 0)}
                    noDataRenderer={() => (
                      <span className="text-center ptb-5">Nema rezultata</span>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-30">
              <div className="col-sm-3 pb-20">
                <CustomButton
                  text="Izmijeni"
                  onClick={this.insertUgovor}
                  type="save"
                />
              </div>
            </div>
            <table className="mt-20">
              <thead>
                <tr>
                  <th className="text-center prl-10 f-s-12">Datum</th>
                  <th className="text-center prl-10 f-s-12">Broj fakture</th>
                  <th className="text-center prl-10 f-s-12">Klijent</th>
                  <th className="text-center prl-10 f-s-12">Grad</th>
                  <th className="text-center prl-10 f-s-12">Iznos</th>
                </tr>
              </thead>
              <tbody>
                {this.state.fakture.length > 0 ? (
                  this.state.fakture.map((e, index) => {
                    const data = {
                      brfakture: e.br_fakture,
                      datum: new Date(e.datum),
                      datumizdavanja: formatDate(new Date(e.datum)),
                      valuta: e.valuta,
                      napomena: e.napomena,
                      id: e.id,
                    };
                    return (
                      <tr
                        key={index}
                        onClick={(ev: React.MouseEvent<HTMLElement>) =>
                          this.showFakturaModal(
                            ev,
                            "izmjena",
                            e.id,
                            "Izmjena Fakture"
                          )
                        }
                      >
                        <td className="text-center prl-10 f-s-12">
                          {e.datum !== undefined
                            ? formatDate1(e.datum) + "."
                            : e.datum}
                        </td>
                        <td className="text-center prl-10 f-s-12">
                          {e.br_fakture}
                        </td>
                        <td className="text-center prl-10 f-s-12">{e.kupac}</td>
                        <td className="text-center prl-10 f-s-12">{e.grad}</td>
                        <td className="text-center prl-10 f-s-12">
                          {formatMoney(e.iznos)}€
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {this.state.modal || this.state.fakturaModal
            ? this.renderModal()
            : ""}
        </form>
      );
    }
  }
}

export default UnosUgovora;
