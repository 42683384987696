export const formatMoney = (n?: any, c?: any, d?: any, t?: any) => {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "," : d,
    t = t == undefined ? "." : t,
    s = n < 0 ? "-" : "",
    i: any = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j: any = (j = i.length) > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

export const formatDate = (date: Date | undefined) => {
  const addZ = (n: number) => {
    return n < 10 ? "0" + n : "" + n;
  };
  try {
    if (date) {
      return `${date.getFullYear()}-${addZ(date.getMonth() + 1)}-${addZ(
        date.getDate()
      )}`;
      // return `${addZ(date.getDate())}-${addZ(date.getMonth() + 1)}-${date.getFullYear()}`;
    }
  } catch (e) {
    console.log(e);
  }
};

export const formatDate1 = (date: string | any) => {
  let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month ,year].join('.');
};

