import React, { Component } from "react";
import { customAnimation } from "../../../functions/animations";

interface IRingChart {
  size: number;
  percentage: number;
  date: string;
  value: any;
}

class RingChart extends Component<IRingChart> {
  renderCircle = () => {
    customAnimation(0, this.props.percentage, 1000, this.setProgress);
  };

  setProgress = (percent: number) => {
    var circle: SVGCircleElement | any = document.getElementById("progress");
    if (circle) {
      var radius = circle.r.baseVal.value;
      var circumference = radius * 2 * Math.PI;
      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset = `${circumference}`;
      const offset = circumference - (percent / 100) * circumference;
      circle!.style.strokeDashoffset = offset.toString();
    }
  };

  componentDidMount() {
    this.renderCircle();
  }
  render() {
    return (
      <div className="ringContainer line-height-1 p-r">
        <svg
          className="progress-ring"
          height={this.props.size}
          width={this.props.size}
        >
          <circle
            id="bgRing"
            className="bgRing"
            strokeWidth="5"
            stroke="#343F4E"
            fill="transparent"
            r={this.props.size / 2 - 8}
            cx={this.props.size / 2}
            cy={this.props.size / 2}
            // strokeDasharray="100"
          />

          <circle
            id="progress"
            className="progress-ring__circle"
            strokeWidth="5"
            stroke="#39B677"
            fill="transparent"
            r={this.props.size / 2 - 8}
            cx={this.props.size / 2}
            cy={this.props.size / 2}
            // strokeLinecap="round"
            opacity="1"
          />
        </svg>
        <div className="info center-a-b">
          <div className="title mb-10">
            <h4 className="f-s-16 text-color-white f-w-4 text-center">
              {this.props.date}
            </h4>
          </div>
          <div className="title">
            <h5 className="f-s-16 text-color-white f-w-4 text-center">
              <span className="mr-10">PDV:</span>
              <span className="f-s-1 text-color-secondary">
                {this.props.value}€
              </span>
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default RingChart;
