import axios, { CancelTokenSource } from "axios";
import {
  FilterFakture,
  FilterAlarma,
} from "../settings/TableTypes/FaktureTypes";
import { BASE_URL } from "./baseUrl";

export const getHomeSuperUlazneIzlazne = (
  month: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    url: `${BASE_URL}/izvjestaji/izlazne_ulazne.php?1&m=${month}&api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};

export const getHomeSuperPotrazivanjaDugovanja = (
  month: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    url: `${BASE_URL}/izvjestaji/potrazivanja_dugovanja.php?1&m=${month}&api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};
export const getHomeSuperTopDuznici = (
  month: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    url: `${BASE_URL}/izvjestaji/duznici.php?1&m=${month}&api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};
export const getHomeSuperTopPovjerioci = (
  month: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    url: `${BASE_URL}/izvjestaji/povjerioci.php?1&m=${month}&api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};
export const getHomeSuperPDV = (
  month: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    url: `${BASE_URL}/izvjestaji/pdv.php?1&m=${month}&api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};

export const getUplate = (
  id: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/ajax/uplate.php?faktura=${id}`,
    cancelToken: cancel.token,
    params: {
      api,
    },
  }).then((res) => res.data);
};

export const getFakture = (
  type: number,
  cancel: CancelTokenSource,
  api: string,
  filter?: FilterFakture,
  mjesecna?: number,
  customParam?: {}
) => {
  let url;
  if (type === 0) {
    url = `${BASE_URL}/izlazne.php`;
  } else if (type === 1) {
    url = `${BASE_URL}/izlazne.php?profaktura=1`;
  } else if (type === 2) {
    url = `${BASE_URL}/ulazne.php`;
  } else {
    url = "";
  }
  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
    params: {
      ...filter,
      mjesecna: mjesecna === 0 ? "" : 2,
      ...customParam,
      api,
    },
  }).then((res) => res.data);
};
export const getFaktureAnalitickaKartica = (
  type: number,
  cancel: CancelTokenSource,
  api: string,
  filter?: FilterFakture,
  mjesecna?: number,
  customParam?: {}
) => {
  let url;
  if (type === 0) {
    url = `${BASE_URL}/izlazne.php`;
  } else if (type === 1) {
    url = `${BASE_URL}/izlazne.php?profaktura=1`;
  } else if (type === 2) {
    url = `${BASE_URL}/ulazne.php`;
  } else {
    url = "";
  }
  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
    params: {
      ...filter,
      ...customParam,
      api,
    },
  }).then((res) => res.data);
};

export const getPodesavanja = (cancel: CancelTokenSource, api: string) => {
  const url = `${BASE_URL}/settings.php`;

  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
    params: {
      api,
    },
  }).then((res) => res.data);
};

export const getAlarmi = (
  cancel: CancelTokenSource,
  api: string,
  filter?: FilterAlarma
) => {
  const url = `${BASE_URL}/alarmi.php`;

  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
    params: {
      ...filter,
      api,
    },
  }).then((res) => res.data);
};

export const getAllAlarmi = (
  cancel: CancelTokenSource,
  api: string,
  filter?: FilterAlarma
) => {
  const url = `${BASE_URL}/alarmi.php?all=1`;
  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
    params: {
      ...filter,
      api,
    },
  }).then((res) => res.data.alarmi);
};

export const getDobavljaci = (
  cancel: CancelTokenSource,
  api: string,
  filter?: FilterFakture
) => {
  const url = `${BASE_URL}/klijenti.php?nolimit=1`;

  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
    params: {
      ...filter,
      api,
    },
  }).then((res) => res.data);
};

export const getUsluge = (
  cancel: CancelTokenSource,
  api: string,
  filter?: FilterFakture
) => {
  const url = `${BASE_URL}/ajax/usluge.php`;

  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
    params: {
      ...filter,
      api,
    },
  }).then((res) => res.data);
};

export const getDokumentacija = (
  cancel: CancelTokenSource,
  api: string,
  filter?: FilterFakture
) => {
  const url = `${BASE_URL}/dokumentacija.php`;
  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
    params: {
      ...filter,
      api,
    },
  }).then((res) => res.data);
};
export const getUgovori = (
  cancel: CancelTokenSource,
  api: string,
  filter?: FilterFakture,
  customParam?: {}
) => {
  const url = `${BASE_URL}/ugovori.php`;

  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
    params: {
      ...filter,
      ...customParam,
      api,
    },
  }).then((res) => res.data);
};

export const getIzvjestaj = (
  url: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    method: "get",
    params: {
      api,
    },
    url,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};

export const getKlijent = (
  url: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    method: "get",
    url,
    params: {
      api,
    },
    cancelToken: cancel.token,
  }).then((res) => res.data);
};

export const getKlijentStats = (
  id: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/klijent.php?klijent=${id}&api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};

export const getLinijeBiznisa = (cancel: CancelTokenSource, api: string) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/ajax/linije_biznisa.php?api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};

export const getUslugeFaktura = (
  id: string,
  cancel: CancelTokenSource,
  api: string
) => {
  const url = `${BASE_URL}/ajax/faktura_usluge.php?faktura=${id}&api=${api}`;
  return axios({
    method: "get",
    url,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};

export const getDrzave = (cancel: CancelTokenSource, api: string) => {
  return axios({
    url: `${BASE_URL}/ajax/drzave.php?api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};
export const getGradovi = (
  cancel: CancelTokenSource,
  api: string,
  drzavaID?: string
) => {
  return axios({
    url: `${BASE_URL}/ajax/gradovi.php?drzava=${drzavaID}&api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};

export const getTipovi = (cancel: CancelTokenSource, api: string) => {
  return axios({
    url: `${BASE_URL}/ajax/tip_dokumenta.php?api=${api}`,
    cancelToken: cancel.token,
  }).then((res) => res.data);
};

export const getTargeti = (
  year: string,
  cancel: CancelTokenSource,
  api: string
) => {
  return axios({
    url: `${BASE_URL}/targeti.php?api=${api}`,
    params: {
      g: year,
    },
    cancelToken: cancel.token,
  }).then((res) => res.data);
};
