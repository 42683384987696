import React, { useEffect, useRef } from "react";
import CustomButton from "../../custom/CustomButton/CustomButton";
import { renderIcon } from "../../Icons/Icons";

type ModalSmallType = {
  title: string;
  question: string;
  buttons: Array<string>;
  options: Array<string>;
  id: string;
  onClick: (id: string) => void;
  removeModal: () => void;
  logout?: () => void;
};

const ModalSmall = ({
  question,
  buttons,
  id,
  options,
  onClick,
  removeModal,
  title,
  logout,
}: ModalSmallType) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    document.addEventListener("keyup", keyPressHandler);
    if (buttonRef.current) {
      buttonRef.current.focus();
    }

    return () => document.removeEventListener("keyup", keyPressHandler);
  }, []);

  const keyPressHandler = (event: any) => {
    console.log(document.activeElement);
    // ESC key
    if (event.keyCode === 27) {
      removeModal();
    }
  };

  const clickOutsideHandler = (event: any) => {
    if (event.target.classList.contains("modal")) {
      removeModal();
    }
  };

  if (title !== "Log out") {
    return (
      <div
        className="modal modalSmall d-flex align-items-center justify-content-center ce"
        onClick={clickOutsideHandler}
      >
        <div className="modalSmallContent pa-10 d-flex align-items-center justify-content-center flex-column p-r">
          <div className="header d-flex align-items-center justify-content-center">
            <h5 className="text-color-white f-s-18 f-w-5">{title}</h5>
            <span className="center-a-v" onClick={removeModal}>
              {renderIcon("CloseIcon", "closeIcon", 16)}
            </span>
          </div>
          <h3 className="f-s-20 f-w-5 mb-30 text-center">{question}</h3>
          <div className="buttons d-flex">
            {buttons.map((e, index) => {
              if (e === "delete") {
                return (
                  <span className="mr-20" key={index}>
                    <CustomButton
                      type={e}
                      text={options[index]}
                      onClick={() => onClick(id)}
                    />
                  </span>
                );
              } else {
                return (
                  <span key={index}>
                    <CustomButton
                      ref={buttonRef}
                      type={e}
                      text={options[index]}
                      onClick={removeModal}
                    />
                  </span>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="modal modalSmall d-flex align-items-center justify-content-center ce">
        <div className="modalSmallContent pa-10 d-flex align-items-center justify-content-center flex-column p-r">
          <div className="header d-flex align-items-center justify-content-center">
            <h5 className="text-color-white f-s-18 f-w-5">{title}</h5>
            <span className="center-a-v" onClick={removeModal}>
              {renderIcon("CloseIcon", "closeIcon", 16)}
            </span>
          </div>
          <h3 className="f-s-20 f-w-5 mb-30 text-center">{question}</h3>
          <div className="buttons d-flex">
            {buttons.map((e, index) => {
              if (e === "delete") {
                return (
                  <span className="mr-20" key={index}>
                    <CustomButton
                      type={e}
                      text={options[index]}
                      onClick={logout ? logout : () => false}
                    />
                  </span>
                );
              } else {
                return (
                  <span key={index}>
                    <CustomButton
                      type={e}
                      text={options[index]}
                      onClick={removeModal}
                    />
                  </span>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
};

export default ModalSmall;
