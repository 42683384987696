import { IUnosUgovora } from "../UnosUgovora";

export const validateForm = (data: IUnosUgovora, type: string) => {
  let invalidFields: Array<any> = [];
  if (type === "unos") {
    if (data.djelovodni_broj_firme.trim() === "") {
      invalidFields.push("Polje djelovodni broj firme je obavezno!");
    } else if(data.djelovodni_broj_firme.trim().length > 50){
      invalidFields.push("Dužina djelovodnog broja firme ne može biti veća od 50 karaktera!");
    }
    if (data.djelovodni_broj_klijenta.trim() === "") {
      invalidFields.push("Polje djelovodni broj klijenta je obavezno!");
    } else if(data.djelovodni_broj_klijenta.trim().length > 50){
      invalidFields.push("Dužina djelovodnog broja klijenta ne može biti veća od 50 karaktera!");
    }
    if (data.klijent === "") {
      invalidFields.push("Polje klijent je obavezno!");
    }
    if (data.usluga === "") {
      invalidFields.push("Polje usluga je obavezno!");
    }
    if(data.id === 0) {
      if (data.datum_zak === "") {
        invalidFields.push("Polje datum zaključenja je obavezno!");
      }
      if (data.datum_vazenja === "") {
        invalidFields.push("Polje datum važenja je obavezno!");
      }
      if (data.first_date === "") {
        invalidFields.push("Polje datum prve fakture je obavezno!");
      }
      if(data.datum_zak !== "" && data.datum_vazenja !== "" && Date.parse(data.datum_zak) > Date.parse(data.datum_vazenja)){
        invalidFields.push("Datum zaključenja ugovora ne može biti veći od datuma važenja ugovora!");
      }
      if(data.datum_zak !== "" && data.first_date !== "" && Date.parse(data.datum_zak) > Date.parse(data.first_date)){
        invalidFields.push("Datum prve fakture ne može biti manji od datuma zaključenja ugovora!");
      }
      if(data.datum_vazenja !== "" && data.first_date !== "" && Date.parse(data.first_date) > Date.parse(data.datum_vazenja)){
        invalidFields.push("Datum prve fakture ne može biti veći od datuma važenja ugovora!");
      }
    }
    if (data.first_vrijednost === "") {
      invalidFields.push("Polje vrijednost prve fakture je obavezno!");
    }
    if (data.vrijednost === "") {
      invalidFields.push("Polje ukupna vrijednost je obavezno!");
    }
    if (data.alarm_datum === "") {
      invalidFields.push("Polje datum alarma je obavezno!");
    }
    if (data.offset === "") {
      invalidFields.push("Polje offset je obavezno!");
    }
    if (data.trajanje === "") {
      invalidFields.push("Polje trajanje je obavezno!");
    } else if( parseInt(data.trajanje) < 0 ){
      invalidFields.push(`Trajanje mora da bude pozitivan broj!`);
    }
    if (data.grad === "") {
      invalidFields.push("Polje grad je obavezno!");
    }
    if (data.valuta === "") {
      invalidFields.push("Polje valuta je obavezno!");
    } else if (data.valuta.length > 11) {
      invalidFields.push("Dužina valute ne može biti veća od 11 cifara!");
    }
    if (data.opis !== null && data.opis.trim().length > 500) {
      invalidFields.push("Dužina opisa ne može biti veća od 500 karaktera!");
    }
  }
  return invalidFields;
};
