import React, { ChangeEvent } from "react";
import Select from "react-dropdown-select";
import TextInput from "../../Inputs/TextInput/TextInput";
import { renderIcon } from "../../Icons/Icons";
import { formatMoney } from "../../../functions/formaters";
import TextArea from "../../Inputs/TextArea/TextArea";

type UslugeType = {
  uslugeList: Array<object>;
  handleSelectChange(val: any, name: string, index: number): void;
  kolicina: string;
  handleInputChange(
    e: ChangeEvent<HTMLInputElement>,
    name: string,
    subname?: string
  ): void;
  neto: string;
  popust: string;
  popustPercent: string;
  pdv: string;
  opis_usluge: string;
  index: number;
  addUsluga(): void;
  removeUsluga(index: number): void;
  usluge: Array<any>;
  nazivUsluge: string;
};

const Usluge = ({
  uslugeList,
  handleSelectChange,
  kolicina,
  handleInputChange,
  neto,
  popust,
  pdv,
  opis_usluge,
  index,
  addUsluga,
  usluge,
  removeUsluga,
  nazivUsluge,
  popustPercent,
}: UslugeType) => {
  let ukupnoValue;
  const ukupno = parseFloat(kolicina) * parseFloat(neto);
  const calcPopust = popust !== "" ? parseFloat(popust) : 0;
  const calcPDV = ((ukupno - calcPopust) * parseFloat(pdv)) / 100;
  if (popust !== "") {
    ukupnoValue = ukupno + calcPDV - parseFloat(popust);
  } else {
    ukupnoValue = ukupno + calcPDV;
  }

  return (
    <div className="row mt-30 align-items-stretch">
      <div className="col-sm-12 col-lg-6 col-xl-3">
        <div className="title f-s-12 f-w-5 mb-5">
          <span>
            Usluge <span>*</span>
          </span>
        </div>
        <div className="input p-r">
          <label htmlFor="" className="react-select-label f-s-12">
            {nazivUsluge}
          </label>
          <Select
            options={uslugeList}
            labelField="naziv"
            valueField="id"
            placeholder=""
            clearable={true}
            dropdownGap={0}
            clearOnSelect={true}
            onChange={(val: any) => handleSelectChange(val, "usluge", index)}
            onClearAll={() => handleSelectChange([], "usluge", index)}
            noDataRenderer={() => (
              <span className="text-center ptb-5">Nema rezultata</span>
            )}
          />
        </div>
      </div>
      <div className="col-sm-4 col-lg-2 col-xl-1">
        <div className="title f-s-12 f-w-5 mb-5">
          <label htmlFor="kolicina">Količina</label>
        </div>
        <div className="input">
          <TextInput
            placeholder=""
            value={kolicina}
            onChange={handleInputChange}
            name="usluge"
            subname="kolicina"
            id="kolicina"
            number={true}
            index={index}
            min={1}
          />
        </div>
      </div>
      <div className="col-sm-6 col-lg-4 col-xl-2">
        <div className="title f-s-12 f-w-5 mb-5">
          <label htmlFor="neto">
            Neto cijena (€) <span>*</span>
          </label>
        </div>
        <div className="input">
          <TextInput
            placeholder=""
            value={neto}
            onChange={handleInputChange}
            name="usluge"
            subname="neto"
            id="neto"
            number={true}
            index={index}
            min={0}
            step="0.01"
          />
        </div>
      </div>
      <div className="col-sm-4 col-lg-2 col-xl-1">
        <div className="title f-s-12 f-w-5 mb-5">
          <label htmlFor="popust">Popust (€)</label>
        </div>
        <div className="input">
          <TextInput
            placeholder=""
            value={popust}
            onChange={handleInputChange}
            name="usluge"
            subname="popust"
            id="popust"
            number={true}
            index={index}
            min={0}
            step="0.01"
          />
        </div>
      </div>
      <div className="col-sm-4 col-lg-2 col-xl-1">
        <div className="title f-s-12 f-w-5 mb-5">
          <label htmlFor="popustPercent">Popust (%)</label>
        </div>
        <div className="input">
          <TextInput
            placeholder=""
            value={popustPercent}
            onChange={handleInputChange}
            name="usluge"
            subname="popustPercent"
            id="popustPercent"
            number={true}
            index={index}
            min={0}
            step="0.01"
          />
        </div>
      </div>
      <div className="col-sm-4 col-lg-2 col-xl-1">
        <div className="title f-s-12 f-w-5 mb-5">
          <label htmlFor="pdv">PDV</label>
        </div>
        <div className="input">
          <TextInput
            placeholder=""
            value={pdv !== "" ? `${pdv}%` : ""}
            onChange={() => false}
            name="pdv"
            id="pdv"
            readOnly={true}
            index={index}
          />
        </div>
      </div>
      <div className="col-sm-6 col-lg-4 col-xl-2">
        <div className="title f-s-12 f-w-5 mb-5">
          <label htmlFor="ukupno">Ukupno (€)</label>
        </div>
        <div className="input">
          <TextInput
            placeholder=""
            value={`${formatMoney(ukupnoValue)}€`}
            onChange={() => false}
            name="usluge"
            id="ukupno"
            readOnly={true}
            index={index}
            min={0}
          />
        </div>
      </div>
      <div className="col-sm-4 col-lg-2 col-xl-1 d-flex align-items-end">
        <div className="row">
          <div className="col-sm-6">
            <div
              className="icon d-flex align-items-center justify-content-center"
              onClick={() => removeUsluga(index)}
            >
              {renderIcon("MinusIconCircle", "minus", 19)}
            </div>
          </div>
          {index + 1 === usluge.length ? (
            <div className="col-sm-6">
              <div
                className="icon d-flex align-items-center justify-content-center"
                onClick={addUsluga}
              >
                {renderIcon("PlusIconCircle", "plus", 19)}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="col-sm-12 col-lg-4 mt-15">
        <TextArea
          value={opis_usluge}
          onChange={handleInputChange}
          name="usluge"
          subname="opis_usluge"
          placeholder="Opis usluge"
          index={index}
          height={100}
        />
      </div>
    </div>
  );
};

export default Usluge;
