import React, { Component } from "react";
import RingChartWrapper from "../Home/RingChartWrapper/RingChartWrapper";
import { getHomeSuperPDV } from "../../api/getFunctions";
import axios from "axios";
import { IPage } from "../../interfaces/pageInterfaces";

class PDV extends Component<IPage> {
  state = {
    pdv: null,
    month: new Date().getMonth() + 1
  };

  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    getHomeSuperPDV(
      this.state.month.toString(),
      this.axiosCancelSource,
      this.props.api
    ).then(res => this.setState({ pdv: res }));
    this.props.changePageTitle("PDV");
  }
  render() {
    return (
      <div className="homeContent mt-20 prl-20">
        <RingChartWrapper result={this.state.pdv} />
      </div>
    );
  }
}

export default PDV;
