import React, { Component } from "react";
import DoughnutChart from "../../components/Charts/DoughnutChart/DoughnutChart";
import { getIzvjestaj, getPodesavanja } from "../../api/getFunctions";
import axios from "axios";
import GaugeChart from "../../components/Charts/GaugeChart/GaugeChart";
import { IPage } from "../../interfaces/pageInterfaces";
import Select from "react-dropdown-select";
import ColumnChart, {
  DuzniciPovjerioci,
} from "../../components/Charts/ColumnChart/ColumnChart";
import Loading from "../../components/Loading/Loading";
import { checkLoaded } from "./functions/functions";
import RingChartWrapper from "../Home/RingChartWrapper/RingChartWrapper";
import { BASE_URL } from "../../api/baseUrl";

export interface IIzvjestajiState {
  vrijednostIzlaznihFaktura: Array<{
    name: string;
    value: number;
  }> | null;
  duznici: Array<DuzniciPovjerioci> | null;
  povjerioci: Array<DuzniciPovjerioci> | null;
  pdv: Array<{
    tekuci: {
      ulazni: number;
      izlazni: number;
    };
    prethodni: {
      ulazni: number;
      izlazni: number;
    };
  }> | null;
  fakture: Array<{
    tip: string;
    fakturisano: number;
    uplaceno: number;
    dug_van_valute: number;
  }> | null;
  potrazivanja: Array<{
    tip: string;
    ukupno: number;
    u_valuti: number;
    van_valute: number;
  }> | null;
  topKupci: Array<{
    kupac: string;
    vrijednost_faktura: number;
    dug_u_valuti: number;
    dug_van_valute: number;
  }> | null;
  brojFaktura: Array<{
    broj_faktura: number;
    linija_biznisa: string;
  }> | null;
  topDobavljaci: Array<{
    kupac: string;
    vrijednost_faktura: number;
    dug_u_valuti: number;
    dug_van_valute: number;
  }> | null;
  odnosLosegDuga: {
    fakturisano: number;
    neplaceno: number;
    odnos: number;
  } | null;
  targeti: {
    trenutnaGodina: Array<{
      mjesec: number;
      data: {
        target: number;
        prihodi: number;
        rashodi: number;
      };
    }>;
    prethodnaGodina: Array<{
      mjesec: number;
      data: {
        target: number;
        prihodi: number;
        rashodi: number;
      };
    }>;
  } | null;
  profitabilnost: {
    izlazne: number;
    ulazne: number;
    troskovi: number;
    odnos: number;
  } | null;
  options: Array<any>;
  optionsTargeti: Array<any>;
  activeFirst: string;
  activeSecond: string;
  activeThird: string;
  activeFourth: any;
  reports: {
    vrijednost_faktura: number;
    odnos_loseg_duga: number;
    potrazivanja_dugovanja: number;
    profitabilnost: number;
    vrijednost_izlaznih: number;
    pdv: number;
    top5duznika: number;
    top5povjerilaca: number;
    top10kupaca: number;
    top10dobavljaca: number;
    targeti: number;
    br_izlaznih_faktura: number;
  };
  loaded: boolean;
  optionsMonths: Array<{ id: number; naziv: string }>;
}
type StateKeys = keyof IIzvjestajiState;

class Izvjestaji extends Component<IPage, IIzvjestajiState> {
  axiosCancelSource = axios.CancelToken.source();
  monthList = () => {
    const date = new Date().getMonth();
    const monthList = [];

    for (let index = 0; index <= date; index++) {
      monthList.push(
        new Date(0, index).toLocaleString("sr-latn-SR", { month: "long" })
      );
    }
    return monthList;
  };
  state: IIzvjestajiState = {
    reports: {
      vrijednost_faktura: 0,
      odnos_loseg_duga: 0,
      potrazivanja_dugovanja: 0,
      profitabilnost: 0,
      vrijednost_izlaznih: 0,
      pdv: 0,
      top5duznika: 0,
      top5povjerilaca: 0,
      top10kupaca: 0,
      top10dobavljaca: 0,
      targeti: 0,
      br_izlaznih_faktura: 0,
    },
    vrijednostIzlaznihFaktura: null,
    odnosLosegDuga: null,
    profitabilnost: null,
    activeFirst: "",
    activeSecond: "",
    activeThird: "",
    activeFourth: this.monthList().pop(),
    topDobavljaci: null,
    topKupci: null,
    brojFaktura: null,
    targeti: null,
    loaded: false,
    fakture: null,
    potrazivanja: null,
    duznici: null,
    povjerioci: null,
    pdv: null,
    optionsMonths: this.monthList().map((e, index) => {
      return { id: index, naziv: e };
    }),
    options: [
      {
        naziv: new Date().toLocaleDateString("sr-latn-SR", {
          month: "long",
          year: "numeric",
        }),
        id: 0,
      },
      {
        naziv: "Tekući Kvartal",
        id: 1,
      },
      { naziv: "Godina " + new Date().getFullYear(), id: 2 },
    ],
    optionsTargeti: [
      {
        id: 0,
        naziv: "Prihodi",
      },
      {
        id: 1,
        naziv: "Rashodi",
      },
    ],
  };
  componentDidMount() {
    this.props.changePageTitle("Izvještaji");

    this.setState(
      {
        activeFirst: this.state.options[0].naziv,
        activeSecond: this.state.options[0].naziv,
        activeThird: this.state.optionsTargeti[0].naziv,
      },
      this.getData
    );
  }

  getData = (name?: StateKeys) => {
    getPodesavanja(this.axiosCancelSource, this.props.api).then((res) => {
      this.setState({ reports: res });
    });

    let p1;
    let p2;
    const order1 = this.state.options
      .map((e: any) => e.naziv)
      .indexOf(this.state.activeFirst);
    const order2 = this.state.options
      .map((e: any) => e.naziv)
      .indexOf(this.state.activeSecond);

    order1 === 0 ? (p1 = "m") : order1 === 1 ? (p1 = "q") : (p1 = "y");
    order2 === 0 ? (p2 = "m") : order2 === 1 ? (p2 = "q") : (p2 = "y");
    const currentYear = new Date().getFullYear();
    if (!name) {
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/izlazne_ulazne.php?m=${
          new Date().getMonth() + 1
        }`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        const fakture = res.map((e: any) => {
          return {
            tip: e.tip.charAt(0).toUpperCase() + e.tip.slice(1) + " fakture",
            fakturisano: e.fakturisano,
            uplaceno: e.uplaceno,
            dug_van_valute: e.dug_van_valute,
          };
        });
        this.setState({ fakture });
      });

      getIzvjestaj(
        `${BASE_URL}/izvjestaji/potrazivanja_dugovanja.php?1`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        const potrazivanja = res.map((e: any, index: number) => {
          return {
            tip: index === 0 ? "Potraživanja" : "Dugovanja",
            ukupno: e.ukupno,
            u_valuti: e.u_valuti,
            van_valute: e.van_valute,
          };
        });
        this.setState({ potrazivanja });
      });

      getIzvjestaj(
        `${BASE_URL}/izvjestaji/duznici.php?1`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({ duznici: res });
      });
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/povjerioci.php?1`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({ povjerioci: res });
      });
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/pdv.php?1`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({ pdv: res });
      });
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/odnosLosegDuga.php?1&=`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({ odnosLosegDuga: res });
      });
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/profitabilnost.php?t=${p1}`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({ profitabilnost: res });
      });
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/vrijednostizlaznihLB.php?t=q`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({
          vrijednostIzlaznihFaktura: res.map((e: any) => {
            return { name: e.linija_biznisa, value: e.vrijednost };
          }),
        });
      });
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/top10kupaca.php?1`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({ topKupci: res });
      });
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/top10dobavljaca.php?1`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({ topDobavljaci: res });
      });
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/targetiprihodi.php?y=${currentYear}&all=1`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        getIzvjestaj(
          `${BASE_URL}/izvjestaji/targetiprihodi.php?y=${
            currentYear - 1
          }&all=1`,
          this.axiosCancelSource,
          this.props.api
        ).then((resp) => {
          this.setState((prevState: IIzvjestajiState) => ({
            ...prevState,
            targeti: {
              trenutnaGodina: res,
              prethodnaGodina: resp,
            },
          }));
        });
      });
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/brizlaznihfaktura.php?1`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({ brojFaktura: res });
      });
    } else if (name === "activeFirst") {
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/profitabilnost.php?t=${p1}`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({ profitabilnost: res });
      });
    } else if (name === "activeSecond") {
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/vrijednostizlaznihLB.php?t=${p1}`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState({
          vrijednostIzlaznihFaktura: res.map((e: any) => {
            return { name: e.linija_biznisa, value: e.vrijednost };
          }),
        });
      });
    } else if (name === "activeThird") {
      let target =
        this.state.activeThird === "Prihodi"
          ? "targetiprihodi"
          : "targetirashodi";
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/${target}.php?y=${currentYear}&all=1`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        this.setState((prevState: IIzvjestajiState) => ({
          ...prevState,
          targeti: {
            trenutnaGodina: res,
            prethodnaGodina: [],
          },
        }));
      });
    } else if (name === "activeFourth") {
      const index: any = this.state.optionsMonths.filter((e) => {
        return e.naziv === this.state.activeFourth;
      });
      const query = index[0].id + 1;
      getIzvjestaj(
        `${BASE_URL}/izvjestaji/izlazne_ulazne.php?m=${query}`,
        this.axiosCancelSource,
        this.props.api
      ).then((res) => {
        const fakture = res.map((e: any) => {
          return {
            tip: e.tip.charAt(0).toUpperCase() + e.tip.slice(1) + " fakture",
            fakturisano: e.fakturisano,
            uplaceno: e.uplaceno,
            dug_van_valute: e.dug_van_valute,
          };
        });
        this.setState({ fakture });
      });
    }
  };

  componentDidUpdate({}, prevState: IIzvjestajiState) {
    if (
      prevState.odnosLosegDuga !== this.state.odnosLosegDuga ||
      prevState.profitabilnost !== this.state.profitabilnost ||
      prevState.vrijednostIzlaznihFaktura !==
        this.state.vrijednostIzlaznihFaktura ||
      prevState.topKupci !== this.state.topKupci ||
      prevState.topDobavljaci !== this.state.topDobavljaci ||
      prevState.targeti !== this.state.targeti ||
      prevState.brojFaktura !== this.state.brojFaktura
    ) {
      // console.log(checkLoaded(this.state));
      if (checkLoaded(this.state)) {
        setTimeout(() => {
          this.setState({ loaded: true });
        }, 1000);
      }
    }
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  handleSelectChange = (val: any, name: StateKeys) => {
    if (val[0]) {
      if (name === "activeFirst") {
        this.setState({ profitabilnost: null });
      } else if (name === "activeSecond") {
        this.setState({ vrijednostIzlaznihFaktura: null });
      } else if (name === "activeThird") {
        this.setState({ targeti: null });
      } else if (name === "activeFourth") {
        this.setState({ fakture: null });
      }
      this.setState(
        (prevState: IIzvjestajiState) => ({
          ...prevState,
          [name]: val[0].naziv,
        }),
        () => this.getData(name)
      );
    }
  };

  render() {
    if (this.state.loaded) {
      return (
        <div className="izvjestaji pb-40">
          <div className="row">
            {this.state.reports.vrijednost_faktura ? (
              <div className="col-xl-8 col-lg-12">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <div className="selectPeriod target">
                    <label htmlFor="" className="react-select-label f-s-12">
                      {this.state.activeFourth}
                    </label>
                    <Select
                      options={this.state.optionsMonths}
                      labelField="naziv"
                      valueField="id"
                      placeholder=""
                      dropdownGap={0}
                      clearOnSelect={true}
                      searchable={false}
                      onChange={(val: any) =>
                        this.handleSelectChange(val, "activeFourth")
                      }
                      onClearAll={() =>
                        this.handleSelectChange([], "activeFourth")
                      }
                      noDataRenderer={() => (
                        <span className="text-center ptb-5">
                          Nema rezultata
                        </span>
                      )}
                    />
                  </div>
                  <ColumnChart
                    id="fakture"
                    data={null}
                    title="Fakture"
                    brojFaktura={null}
                    targeti={null}
                    potrazivanja={null}
                    duznici={null}
                    fakture={this.state.fakture}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.odnos_loseg_duga ? (
              <div className="col-xl-4 col-lg-6">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <GaugeChart
                    id="odlduga"
                    data={this.state.odnosLosegDuga}
                    title={"Odnos lošeg duga".toLocaleUpperCase()}
                    min={0}
                    max={100}
                    type={0}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.potrazivanja_dugovanja ? (
              <div className="col-xl-8 col-lg-12 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <ColumnChart
                    id="potrazivanja"
                    data={null}
                    title="Potraživanja/Dugovanja"
                    brojFaktura={null}
                    targeti={null}
                    fakture={null}
                    duznici={null}
                    potrazivanja={this.state.potrazivanja}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.profitabilnost ? (
              <div className="col-xl-4 col-lg-6 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <div className="selectPeriod">
                    <label htmlFor="" className="react-select-label f-s-12">
                      {this.state.activeFirst}
                    </label>
                    <Select
                      options={this.state.options}
                      labelField="naziv"
                      valueField="id"
                      placeholder=""
                      searchable={false}
                      dropdownGap={0}
                      clearOnSelect={true}
                      onChange={(val: any) =>
                        this.handleSelectChange(val, "activeFirst")
                      }
                      onClearAll={() =>
                        this.handleSelectChange([], "activeFirst")
                      }
                      noDataRenderer={() => (
                        <span className="text-center ptb-5">
                          Nema rezultata
                        </span>
                      )}
                    />
                  </div>
                  <GaugeChart
                    id="profitabilnost"
                    data2={this.state.profitabilnost}
                    title={"Profitabilnost".toLocaleUpperCase()}
                    min={-100}
                    max={100}
                    type={1}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.top5duznika ? (
              <div className="col-xl-8 col-lg-12 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <ColumnChart
                    id="duznici"
                    data={null}
                    title="Top 5 dužnika"
                    brojFaktura={null}
                    targeti={null}
                    fakture={null}
                    potrazivanja={null}
                    duznici={this.state.duznici}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.vrijednost_izlaznih ? (
              <div className="col-xl-4 col-lg-6 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <DoughnutChart
                    data={this.state.vrijednostIzlaznihFaktura}
                    id="ifakt"
                    title={"Vrijednost izlaznih faktura".toLocaleUpperCase()}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.top5povjerilaca ? (
              <div className="col-xl-8 col-lg-12 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <ColumnChart
                    id="povjerioci"
                    data={null}
                    title="Top 5 povjerioca"
                    brojFaktura={null}
                    targeti={null}
                    fakture={null}
                    potrazivanja={null}
                    duznici={this.state.povjerioci}
                    type={4}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.pdv ? (
              <div className="col-xl-4 col-lg-6 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  {this.state.pdv && (
                    <RingChartWrapper result={this.state.pdv} small />
                  )}
                </div>
              </div>
            ) : null}

            {this.state.reports.top10kupaca ? (
              <div className="col-sm-12 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <ColumnChart
                    id="kupci"
                    data={this.state.topKupci}
                    title="Top 10 kupaca"
                    brojFaktura={null}
                    targeti={null}
                    fakture={null}
                    duznici={null}
                    potrazivanja={null}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.top10dobavljaca ? (
              <div className="col-sm-12 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <ColumnChart
                    id="dobavljaci"
                    data={this.state.topDobavljaci}
                    title="Top 10 dobavljača"
                    brojFaktura={null}
                    targeti={null}
                    duznici={null}
                    potrazivanja={null}
                    fakture={null}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.targeti ? (
              <div className="col-sm-12 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <div className="selectPeriod target">
                    <label htmlFor="" className="react-select-label f-s-12">
                      {this.state.activeThird}
                    </label>
                    <Select
                      options={this.state.optionsTargeti}
                      labelField="naziv"
                      valueField="id"
                      placeholder=""
                      clearable={true}
                      dropdownGap={0}
                      clearOnSelect={true}
                      onChange={(val: any) =>
                        this.handleSelectChange(val, "activeThird")
                      }
                      onClearAll={() =>
                        this.handleSelectChange([], "activeThird")
                      }
                      noDataRenderer={() => (
                        <span className="text-center ptb-5">
                          Nema rezultata
                        </span>
                      )}
                    />
                  </div>
                  <ColumnChart
                    id="targeti"
                    data={null}
                    title={`Targeti ${this.state.activeThird}`}
                    brojFaktura={null}
                    targeti={this.state.targeti}
                    type={this.state.activeThird === "Prihodi" ? 0 : 1}
                    potrazivanja={null}
                    duznici={null}
                    fakture={null}
                  />
                </div>
              </div>
            ) : null}

            {this.state.reports.br_izlaznih_faktura ? (
              <div className="col-sm-12 mt-30">
                <div
                  className="chartContainer prl-5"
                  style={{ height: "450px" }}
                >
                  <ColumnChart
                    id="brojFaktura"
                    data={null}
                    title="Broj izlaznih faktura"
                    brojFaktura={this.state.brojFaktura}
                    targeti={null}
                    potrazivanja={null}
                    duznici={null}
                    fakture={null}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default Izvjestaji;
