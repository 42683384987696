import { IUnosDokumenta } from "../UnosDokumenta";

export const validateForm = (data: IUnosDokumenta, type: string) => {
  let invalidFields: Array<any> = [];
  if (type === "unos") {
    if (data.tip === "") {
      invalidFields.push("Polje tip dokumenta je obavezno!");
    }
    if (data.thirdCheckBox === false && data.klijent === "") {
      invalidFields.push("Polje kupac/dobavljač je obavezno!");
    }
    if (data.datum === undefined) {
      invalidFields.push("Polje datum je obavezno!");
    }
    if (data.djelovodni_broj.trim() === "") {
      invalidFields.push("Polje djelovodni broj je obavezno!");
    } else if (data.djelovodni_broj.trim().length > 50) {
      invalidFields.push("Dužina djelovodnog broja ne može biti veća od 50 karaktera!");
    }
    if (data.documentName.trim() === "") {
      invalidFields.push("Polje dokument je obavezno!");
    } else if (data.documentName.trim().length > 200) {
      invalidFields.push("Dužina naziva dokumenta ne može biti veća od 200 karaktera!");
    }
  }
  return invalidFields;
};
