import { InitialState } from "../InitialState/InitialState";

export const UslugeNav = [
  { icon: "SearchIcon", title: "Pretraži usluge" },
  { icon: "AddDocumentIcon", title: "Dodaj usluge" },
  { icon: "AddDocumentIcon", title: "Dodaj liniju biznisa" }
];

export const PretraziUsluge = {
  buttons: [{ icon: "DocumentIcon", text: "Izmijeni", type: "document" }],
  state: {
    datumOd: undefined,
    datumDo: undefined,
    loadMore: false,
    pageNumber: 0,
    firstCheckBox: true,
    secondCheckBox: true,
    sortBy: {
      name: "datum",
      direction: true
    },
    filter: {},
    ...InitialState,
    dropDown: {
      name: "statusDropdown",
      open: false,
      options: [
        "Status",
        "Djelimično pl. van valute",
        "Neplaćeno u valuti",
        "Neplaćeno van valute",
        "Plaćeno"
      ],
      reference: "dropDown",
      title: "Status"
    }
  },
  table: {
    thead: {
      firstRow: ["Usluga", "Linija biznisa", "Valuta", "PDV"],
      secondRow: [
        { name: "TextInput", property: "naziv" },
        { name: "TextInput", property: "linija_biznisa_naziv" },
        { name: "TextInput", property: "valuta" },
        { name: "TextInput", property: "pdv" }
      ]
    }
  }
};
