import React from "react";
import { renderIcon } from "../../Icons/Icons";

type CustomCheckboxType = {
  className: string;
  text: string;
  checked?: boolean;
  onClick(name: string): void;
  name: string;
};

const CustomCheckbox = ({
  className,
  text,
  checked,
  onClick,
  name
}: CustomCheckboxType) => {
  return (
    <div
      className={`${className} customCheckbox d-flex align-items-center`}
      onClick={() => onClick(name)}
    >
      <span
        className={`  check line-height-1 d-flex justify-content-center align-items-center ${
          checked ? "active" : ""
        }`}
      >
        {renderIcon("CheckIcon", "", 23)}
      </span>
      <span className="text ml-5 line-height-1 f-s-15 text-transform-uppercase">
        {text}
      </span>
    </div>
  );
};

export default CustomCheckbox;
