import React from "react";
import { renderIcon } from "../../../components/Icons/Icons";
import CustomCheckbox from "../../../components/custom/CustomCheckbox/CustomCheckbox";
import { LinijeBiznisa } from "../../../settings/TableTypes/FaktureTypes";
import Select from "react-dropdown-select";

export const renderLegend = (
  legend: Array<{ color: string; title: string }>
) => {
  return (
    <ul className="legend d-flex  mb-20">
      {legend.map((e: any, index: number) => {
        return (
          <li
            key={index}
            className="mr-20 d-flex align-items-center justify-content-center"
          >
            <span className={`circle ${e.color} mr-5 line-height-1`} />
            <span className="f-s-12 line-height-1">{e.title}</span>
          </li>
        );
      })}
    </ul>
  );
};

export const renderNav = (
  nav: Array<{ icon: string; title: string }>,
  changeNav: (e: React.MouseEvent<HTMLLIElement>, pageNumber: number) => void,
  unos?: string,
  type?: number
) => {
  if (!unos) {
    return (
      <ul className="nav d-flex">
        {nav.map((e: any, index: number) => {
          return (
            <li
              key={index}
              className={`prl-10 d-flex align-items-center transition-1 ${
                index === 0 ? "active" : ""
              }`}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                  changeNav(e, index)
                 }
              }
            >
              <span className="line-height-1 mr-10">
                {renderIcon(e.icon, "", 20)}
              </span>
              <span className="f-s-20 f-w-6 line-height-1">{e.title}</span>
            </li>
          );
        })}
      </ul>
    );
  } else if (!type) {
    return (
      <ul className="nav d-flex">
        {nav.map((e: any, index: number) => {
          return (
            <li
              key={index}
              className={`prl-10 d-flex align-items-center transition-1 ${
                index === 1 ? "active" : ""
              }`}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                  changeNav(e, index)
                }
              }
            >
              <span className="line-height-1 mr-10">
                {renderIcon(e.icon, "", 20)}
              </span>
              <span className="f-s-20 f-w-6 line-height-1">{e.title}</span>
            </li>
          );
        })}
      </ul>
    );
  } else {
    return (
      <ul className="nav d-flex">
        {nav.map((e: any, index: number) => {
          return (
            <li
              key={index}
              className={`prl-10 d-flex align-items-center transition-1 ${
                index === 2 ? "active" : ""
              }`}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                  changeNav(e, index)
                }
              }
            >
              <span className="line-height-1 mr-10">
                {renderIcon(e.icon, "", 20)}
              </span>
              <span className="f-s-20 f-w-6 line-height-1">{e.title}</span>
            </li>
          );
        })}
      </ul>
    );
  }
};

type RenderChekbox = (
  checkedFirst: boolean | undefined,
  checkedSecond: boolean | undefined,
  onClick: (name: any) => void,
  type: number,
  padding?: boolean,
  dokument?: boolean
) => any;

export const renderCheckbox: RenderChekbox = (
  checkedFirst,
  checkedSecond,
  onClick,
  type,
  padding,
  dokument
) => {
  if (type === 3) {
    return (
      <div className={`checkbox d-flex mb-40 ${!padding ? " pl-10" : ""}`}>
        <CustomCheckbox
          className="mr-20"
          text={!dokument ? "Kupci" : "Ulazni"}
          checked={checkedFirst}
          onClick={onClick}
          name="firstCheckBox"
        />
        <CustomCheckbox
          className=""
          text={!dokument ? "Dobavljači" : "Izlazni"}
          checked={checkedSecond}
          onClick={onClick}
          name="secondCheckBox"
        />
      </div>
    );
  } else if (type === 4) {
    return (
      <div className={`checkbox d-flex mb-40 pl-10`}>
        <CustomCheckbox
          className="mr-20"
          text="Ulazni"
          checked={checkedFirst}
          onClick={onClick}
          name="firstCheckBox"
        />
        <CustomCheckbox
          className=""
          text="Izlazni"
          checked={checkedSecond}
          onClick={onClick}
          name="secondCheckBox"
        />
      </div>
    );
  } else if (type === 5) {
    return (
      <div className={`checkbox d-flex mb-40 pl-10`}>
        <CustomCheckbox
          className="mr-20"
          text="Ulazni ugovor"
          checked={checkedFirst}
          onClick={onClick}
          name="firstCheckBox"
        />
        <CustomCheckbox
          className=""
          text="Izlazni ugovor"
          checked={checkedSecond}
          onClick={onClick}
          name="secondCheckBox"
        />
      </div>
    );
  }
};

export const renderSelect = (
  name: string,
  label: string,
  data: Array<any>,
  labelField: string,
  handleSelectChange: (
    val: any,
    name: any,
    index?: number,
    edit?: boolean
  ) => void,
  searchable?: boolean,
  placeholder?: string
) => {
  return (
    <div className="p-r">
      <label htmlFor="" className="react-select-label f-s-12">
        {label}
      </label>
      <Select
        options={data}
        labelField={labelField}
        valueField="id"
        placeholder={placeholder !== undefined ? placeholder : ""}
        dropdownGap={0}
        clearOnSelect={true}
        onChange={(val: any) => handleSelectChange(val, name, 0)}
        noDataRenderer={() => (
          <span className="text-center ptb-5">Nema rezultata</span>
        )}
        searchable={searchable}
      />
    </div>
  );
};

type KeysOfLinijeBiznisa = keyof LinijeBiznisa;

export const renderLinijaBiznisa = (data: Array<LinijeBiznisa>, key: any) => {
  const linija = data.find(e => e.id === key);

  if (linija) {
    return linija.naziv;
  }
};
